import { ApiResponse } from 'apisauce'

import {
  DocumentTemplate,
  DocumentTemplateListItem,
  DocumentTemplateSuggestionList,
} from '../../../../model/DocumentTemplate'
import { parseToValueJSON, stringifyFromValueJSON } from '../../../../misc/editor.utilities'

import { deserializeQuestionnaires } from '../../questionnaires/api/Questionnaire.mapper'
import {
  ApiDocumentTemplateResource,
  ApiDocumentTemplateListItemResource,
  ApiDocumentTemplateSerializedResource,
  ApiDocumentTemplateSuggestionListResource,
} from './DocumentTemplates.model'
import { deserializeQuestions } from '../../questions/api/Question.mapper'
import { PaginatedList } from '../../../../model/Pagination'
import { formatPrescriptionForDocumentUpdate } from '../../../../misc/documents.utilities'

export function deserializeDocumentTemplateListItem(
  item: ApiDocumentTemplateListItemResource,
): DocumentTemplateListItem {
  return {
    ...item,
    category: item.documentCategory,
  }
}

export function deserializeDocumentTemplatePaginatedResponse(
  response: ApiResponse<PaginatedList<ApiDocumentTemplateListItemResource>>,
): ApiResponse<PaginatedList<DocumentTemplateListItem>> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializeDocumentTemplateListItem) },
  }
}

export function deserializeDocumentTemplateResponse(
  response: ApiResponse<ApiDocumentTemplateResource>,
): ApiResponse<DocumentTemplate> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data:
      data.type === 'farte'
        ? {
            ...data,
            template: parseToValueJSON(data.template),
            category: data.documentCategory,
            variables: deserializeQuestions(data.variables || []),
            questionnaires: deserializeQuestionnaires(data.questionnaires || []),
          }
        : {
            ...data,
            category: data.documentCategory,
          },
  }
}

export function deserializeDocumentTemplateSuggestionListResponse(
  response: ApiResponse<ApiDocumentTemplateSuggestionListResource>,
): ApiResponse<DocumentTemplateSuggestionList> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data || !data.documentTemplates) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: {
      ...data,
      documentTemplates: data.documentTemplates.map(deserializeDocumentTemplateListItem),
    },
  }
}

export function serializeDocumentTemplate(
  templated: Partial<DocumentTemplate>,
): Partial<ApiDocumentTemplateSerializedResource> {
  if (templated.type === 'farte') {
    const { prescriptions, questionnaires, variables, ...originals } = templated
    return {
      ...originals,
      documentCategoryId: templated.category?.id,
      questionnaireIds: questionnaires && questionnaires.map(({ id }) => id),
      template: stringifyFromValueJSON(templated.template),
      variableIds: variables && variables.map(({ id }) => id),
      prescriptions: prescriptions
        ? prescriptions.map(formatPrescriptionForDocumentUpdate)
        : undefined,
    }
  }
  if (templated.type === 'pdf') {
    return {
      ...templated,
      documentCategoryId: templated.category?.id,
    }
  }
  return {}
}
