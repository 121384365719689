import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { SelectInput } from '../../../../shared'
import { Speciality } from '../../../../../model/Professions'
import { ProfessionSpecialitiesSelectProps } from './ProfessionSpecialities.model'
import { useField } from 'formik'
import { SelectOption } from '../../../../../model/SelectOption'

export const ProfessionSpecialitiesSelect: FunctionComponent<ProfessionSpecialitiesSelectProps> = ({
  listOfSpecialities,
  theme = 'light',
  disabled,
  getListOfSpecialities,
}) => {
  const [formValue, { error }, helpers] = useField<Speciality | null>('speciality')

  useEffect(() => {
    getListOfSpecialities()
  }, [getListOfSpecialities])

  const selectOptions: SelectOption<Speciality>[] = useMemo(
    () =>
      listOfSpecialities.map((speciality) => ({
        key: speciality.id,
        value: speciality,
        label: speciality.description,
      })),
    [listOfSpecialities],
  )

  const selectValue = useMemo(
    () => selectOptions.find((option) => option.value.id === formValue?.value?.id),
    [formValue.value, selectOptions],
  )

  return (
    <SelectInput<Speciality>
      title="Specialité"
      options={selectOptions}
      disabled={disabled}
      colorPreset={theme}
      value={selectValue}
      onSelect={({ value }) => {
        helpers.setValue(value)
      }}
      error={error}
    />
  )
}
