import React, { FunctionComponent, Fragment, useContext } from 'react'

import { QuestionDetailProps } from './QuestionDetail.model'
import { Icon } from '../../../shared'
import { Colors } from '../../../../colors'
import { QuestionnaireAddQuestion } from '../../QuestionnaireAddQuestion'
import { QuestionList } from '../../QuestionList'

import styles from './QuestionDetail.module.scss'
import { useEffectWithRef } from '../../../../hooks/utils'
import { QuestionContext } from '../../context'

export const QuestionDetail: FunctionComponent<QuestionDetailProps> = (props) => {
  const { questionTitle, answers, disabled, onAddSubQuestion, onDelete, onEdit } = props
  const { scrollToAnswerId } = useContext(QuestionContext)

  const [
    subQuestionsContainerRef,
    setSubQuestionsContainerRef,
  ] = useEffectWithRef<HTMLDivElement>(() => {
    if (subQuestionsContainerRef) {
      subQuestionsContainerRef.scrollIntoView()
    }
  }, [answers])

  return (
    <div className={styles.container} data-test-id="container-subquestions">
      {answers.map((answer, index) => (
        <Fragment key={index}>
          <div className={styles.questionTitleContainer} data-test-id={`title-answer-${answer.id}`}>
            <Icon size="normal" icon="bendedArrow" color={Colors.contentDark1}></Icon>
            <div className={styles.labels}>
              <div className={styles.questionTitle}>{questionTitle}</div>
              <div className={styles.answerTitle}>{answer.title}</div>
            </div>
          </div>
          <div
            ref={scrollToAnswerId === answer.id ? setSubQuestionsContainerRef : undefined}
            data-test-id={`subquestions-answer-${answer.id}`}
          >
            <QuestionList
              questions={answer.childQuestions || []}
              setAnswerValue={props.setAnswerValue}
              onEdit={onEdit}
              onDelete={onDelete}
              mode={props.mode}
              setSubQuestionsAnswerValues={props.setAnswerValue}
              disabled={disabled}
              isReadonly={props.isReadonly}
            />
            {onAddSubQuestion && (
              <QuestionnaireAddQuestion
                onAddQuestion={(type) =>
                  onAddSubQuestion(
                    type,
                    answer.id,
                    answer.childQuestions ? answer.childQuestions.length + 1 : 1,
                  )
                }
                answerTitle={answer.title}
              />
            )}
          </div>
        </Fragment>
      ))}
    </div>
  )
}
