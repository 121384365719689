import React, { FunctionComponent, useState } from 'react'
import { AutocompleteInput, Input, SuggestionItem } from '../../shared'
import { ContactsProfessionSearchProps } from './ContactsProfessionSearch.model'

export const ContactsProfessionSearch: FunctionComponent<ContactsProfessionSearchProps> = ({
  search,
  colorPreset,
  loading,
  listOfProfessions,
  onSearch,
  onFocus,
}) => {
  const [focused, setFocused] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number>()

  return (
    <AutocompleteInput
      selectedIndex={selectedIndex}
      predictionsLength={listOfProfessions.length}
      suggestionsShown={focused}
      setSelectedIndex={setSelectedIndex}
      handlePressEnter={() => {
        if (selectedIndex !== undefined && listOfProfessions[selectedIndex]) {
          setFocused(false)
          onSearch(listOfProfessions[selectedIndex].description)
        }
      }}
      renderInput={(SuggestionContainer) => (
        <>
          <Input
            type="search"
            name="contactProfession"
            label="Spécialité médicale"
            onFocus={() => {
              setFocused(true)
              onFocus()
            }}
            onBlur={() => setFocused(false)}
            colorPreset={colorPreset}
            value={search}
            onChange={(e) => onSearch(e.currentTarget.value)}
          />
          {focused && (
            <SuggestionContainer>
              {loading ? (
                <SuggestionItem>
                  <div className="p-4">Chargement...</div>
                </SuggestionItem>
              ) : (
                listOfProfessions.map((item, index) => (
                  <SuggestionItem
                    key={item.id}
                    hover={index === selectedIndex}
                    onClick={() => onSearch(item.description)}
                  >
                    <div className="p-4">{item.description}</div>
                  </SuggestionItem>
                ))
              )}
            </SuggestionContainer>
          )}
        </>
      )}
    />
  )
}
