import { HOTJAR_ID, HOTJAR_VERSION } from '../environment/analytics'
import { createAsyncScript, preventAnalyticLoading } from './utils'

/* tslint:disable */

export function initHotjar() {
  if (preventAnalyticLoading(document)) {
    return
  }
  ;(function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_VERSION }
    createAsyncScript(o, t + h._hjSettings.hjid + j + h._hjSettings.hjsv)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
