import React, { FunctionComponent, useState, useEffect } from 'react'
import { useDebounce } from 'react-use'

import { isDefined } from '../../../../misc/functions.utilities'
import { QuestionWrapper } from '../../atoms/QuestionWrapper/index'
import { QuestionTitle } from '../../atoms/QuestionTitle'
import { DateProps } from './DateQuestion.model'
import { DatePicker } from '../../../shared'

export const DateQuestion: FunctionComponent<DateProps> = ({
  mode,
  onEdit,
  onDelete,
  question,
  answerValue,
  setAnswerValue,
  disabled,
  isReadonly,
}) => {
  const [value, setValue] = useState<Date | null>()

  useEffect(() => {
    // undefined => en attente du retour de l'API
    if (value === undefined && answerValue !== undefined && answerValue !== null) {
      setValue(new Date(answerValue))
    }
  }, [answerValue, value])

  useDebounce(
    () => {
      if (value !== null) {
        if (value?.toISOString() !== answerValue) setAnswerValue(value)
      } else {
        setAnswerValue(undefined)
      }
    },
    400,
    [value],
  )

  return (
    <QuestionWrapper
      mode={mode}
      question={question}
      filled={isDefined(answerValue)}
      title={<QuestionTitle question={question} />}
      onDelete={isReadonly ? undefined : onDelete}
      onEdit={isReadonly ? undefined : onEdit}
    >
      <DatePicker
        label="Date"
        colorPreset="light"
        value={answerValue ? new Date(answerValue) : null}
        onChange={(date) => {
          setValue(date)
        }}
        placeholder="jj/mm/aaaa"
        showCalendarButton={false}
        clearable
        disabled={disabled}
        inputType="input"
        testId="date_picker-question"
      />
    </QuestionWrapper>
  )
}
