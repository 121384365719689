import React, { FunctionComponent, MouseEvent, useMemo } from 'react'
import classNames from 'classnames/bind'
import { MssEmail, ContactOrganization } from '../../../model/Contact'
import { ContactCardProps } from './ContactCard.model'
import styles from './ContactCard.module.scss'
import { IconButton, SelectInput, Heading, Tag, DropdownItem, Loader, Input } from '../../shared'
import { SelectOption } from '../../../model/SelectOption'
import { TagBackgroundColors } from '../../../colors'
import { LabeledCheckbox } from '../../legacy/LabeledCheckbox'
import { contactStartGreetingMap } from '@follow/cdk'

const noAddress = 'Non renseignée'

export const ContactCardPlaceholder: FunctionComponent = () => (
  <div className={`${styles.container} ${styles.loading}`}>
    <Loader isLoading={true} height={70} width={70} />
  </div>
)

const mapOrganizationToValue = (organization: ContactOrganization) =>
  organization.name || organization.address?.street
    ? {
        label: `${organization.name ? organization.name : organization.address?.street ?? ''}, ${
          organization.address?.city ?? ''
        }`,
        value: organization.id,
      }
    : { label: noAddress, value: organization.id }

const mapMssEmailToValue = (mssEmail: MssEmail) => ({
  label: mssEmail.emailAddress,
  value: mssEmail.id,
})

const cx = classNames.bind(styles)
export const ContactCard: FunctionComponent<ContactCardProps> = ({
  contact,
  loading,
  disabled = false,
  recipientDisabled = false,
  context,
  showAddresses,
  onClickDisplayDetails,
  onClickDisplayConfiguration,
  onChangeAssignedDoctor,
  onChangeAddressingDoctor,
  onChangeMainRecipient,
  onChangeCopyRecipient,
  onChangeOrganization,
  onChangeMssEmail,
  onClose,
}) => {
  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (onClose) {
      onClose(event)
    }
  }
  const handleDisplayDetails = (event: MouseEvent) => {
    event.preventDefault()
    if (onClickDisplayDetails) {
      onClickDisplayDetails(contact.id, !contact.isEditable, context)
    }
  }

  const handleDisplayConfiguration = (event: MouseEvent) => {
    event.preventDefault()
    if (onClickDisplayConfiguration) {
      onClickDisplayConfiguration(contact.id)
    }
  }

  const handleOrganizationChange = (newOrganization: SelectOption<string>) => {
    if (newOrganization) {
      onChangeOrganization && onChangeOrganization(newOrganization.value)
    }
  }

  const handleMssEmailChange = (newMssEmail: SelectOption<string>) => {
    if (newMssEmail) {
      onChangeMssEmail && onChangeMssEmail(newMssEmail.value)
    }
  }

  const organizations = useMemo(
    () => (showAddresses ? contact.organizations.map(mapOrganizationToValue) : null),
    [contact, showAddresses],
  )

  const mssEmails = useMemo(
    () => (showAddresses ? contact.mssEmails.map(mapMssEmailToValue) : null),
    [contact, showAddresses],
  )

  const inactive = !(contact.assignedDoctor || contact.copyRecipient || contact.mainRecipient)

  const displayContactTypeBlock = useMemo(
    () =>
      onChangeAssignedDoctor ||
      onChangeAddressingDoctor ||
      onChangeMainRecipient ||
      onChangeCopyRecipient,
    [
      onChangeAssignedDoctor,
      onChangeAddressingDoctor,
      onChangeMainRecipient,
      onChangeCopyRecipient,
    ],
  )

  return (
    <div className={cx(styles.container, { inactive })}>
      {loading && (
        <div className={styles.loadingOverlay}>
          <Loader isLoading={true} height={70} width={70} />
        </div>
      )}
      <div className={styles.infos}>
        <div className={styles.infoContent}>
          <Heading size={5}>
            <span className={styles.name}>{`${contact.firstName} ${contact.familyName}`}</span>
          </Heading>
          <div className={styles.specialty}>
            {contact.profession?.description}
            {contact.private && (
              <div className={styles.privateTag}>
                <Tag color={TagBackgroundColors.dsTagVisibility}>Privé</Tag>
              </div>
            )}
          </div>
        </div>
        <div className={styles.infoAction}>
          <IconButton
            onClick={handleDisplayConfiguration}
            icon={'gear'}
            size="nano"
            theme="light"
          />
          {onClickDisplayDetails && (
            <IconButton
              onClick={handleDisplayDetails}
              icon={contact.isEditable ? 'pencil' : 'eye'}
              size="nano"
              theme="light"
            />
          )}
          {onClose && <IconButton icon="cross" onClick={handleClose} size="nano" theme="light" />}
        </div>
      </div>
      {displayContactTypeBlock && (
        <div className={styles.contactType}>
          {onChangeAssignedDoctor && (
            <div className={styles.check}>
              <LabeledCheckbox
                label="Médecin traitant"
                checked={contact.assignedDoctor}
                onChange={onChangeAssignedDoctor}
                disabled={disabled}
              />
            </div>
          )}
          {onChangeAddressingDoctor && (
            <div className={styles.check}>
              <LabeledCheckbox
                label="Adressant"
                checked={contact.addressingDoctor}
                onChange={onChangeAddressingDoctor}
                disabled={disabled}
              />
            </div>
          )}
          {onChangeMainRecipient && (
            <div className={styles.check}>
              <LabeledCheckbox
                label="Destinataire principal"
                checked={contact.mainRecipient}
                onChange={onChangeMainRecipient}
                disabled={recipientDisabled}
              />
            </div>
          )}
          {onChangeCopyRecipient && (
            <div className={styles.check}>
              <LabeledCheckbox
                label="En copie"
                checked={contact.copyRecipient}
                onChange={onChangeCopyRecipient}
                disabled={recipientDisabled}
              />
            </div>
          )}
        </div>
      )}
      {organizations && (
        <div className={styles.contactOrganization}>
          <SelectInput
            options={organizations}
            value={mapOrganizationToValue(contact.organization)}
            onSelect={handleOrganizationChange}
            title="Adresse"
            disabled={disabled ?? mapOrganizationToValue(contact.organization).label === noAddress}
            renderOption={(option, _, isHovered) => (
              <DropdownItem selected={isHovered}>
                <div className="max-w-full whitespace-normal">{option.label}</div>
              </DropdownItem>
            )}
          />
        </div>
      )}
      {mssEmails && (
        <div className={styles.contactOrganization}>
          <SelectInput
            options={mssEmails}
            value={(contact.mssEmail && mapMssEmailToValue(contact.mssEmail)) || undefined}
            onSelect={handleMssEmailChange}
            placeholder={mssEmails.length === 0 ? 'Aucune adresse MSS' : ''}
            title="Adresse MSS"
            disabled={mssEmails.length === 0 || disabled}
            renderOption={(option, _, isHovered) => (
              <DropdownItem selected={isHovered}>
                <div className="max-w-full whitespace-normal">{option.label}</div>
              </DropdownItem>
            )}
          />
        </div>
      )}
      <div className={styles.contactOrganization}>
        <div className={styles.contactGreetingFormLayout}>
          <Input
            label="Politesse de début"
            value={
              contact.contactConfiguration?.startGreeting
                ? contactStartGreetingMap[contact.contactConfiguration?.startGreeting]
                : ''
            }
            name="greetingConfigure"
            colorPreset="light"
            readonly={true}
            onChange={() => {}}
          />
          <Input
            label="Politesse de fin"
            value={
              contact.contactConfiguration?.endGreeting
                ? contactStartGreetingMap[contact.contactConfiguration?.endGreeting]
                : ''
            }
            name="greetingConfigure"
            colorPreset="light"
            readonly={true}
            onChange={() => {}}
          />
        </div>
      </div>
    </div>
  )
}
