import { put, call, takeEvery, select, all } from 'redux-saga/effects'
import * as Actions from './survey.actions'
import * as Api from '../../api/Survey'

import { addResponseError, addValid } from '../message/index'
import * as SurveyUtils from './survey.utils'
import { SurveyBranchConfig, Survey, SurveyActionTypes, SurveyApi } from './survey.model'
import { ApiResponse } from 'apisauce'
import { getSelectedSurvey } from './survey.selectors'
import { RootState } from '../reducers/index'
import { PaginatedList } from '../../model/Pagination'
import { questionnairesActions } from '../cache/questionnaires/index'
import { restuxQuestionnairesApiConfig } from '../cache/questionnaires/api/index'
import { hideBottomPanel } from '../ui/bottomPanel/index'

function* getSurveysWorker({ page }: ReturnType<typeof Actions.getSurveys>) {
  yield put(Actions.setSurveyListLoading(true))
  const response: ApiResponse<PaginatedList<Survey>> = yield call(Api.getSurveys, page)
  if (response.ok && response.data) {
    const { items, pageCount } = response.data
    yield put(Actions.addSurveys(items, pageCount))
  } else {
    yield put(addResponseError(response))
  }
}
export function* getSurveysWatcher() {
  yield takeEvery(SurveyActionTypes.GET_SURVEYS, getSurveysWorker)
}

function* getSurveyWorker({ surveyId }: ReturnType<typeof Actions.getSurvey>) {
  const response = yield call(Api.getSurvey, surveyId)
  if (response.ok) {
    yield put(Actions.setSurvey(response.data))
  } else {
    yield put(addResponseError(response))
  }
}
export function* getSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.GET_SURVEY, getSurveyWorker)
}

function* addSurveyWorker({ name }: ReturnType<typeof Actions.addSurvey>) {
  const response = yield call(Api.addSurvey, name)
  if (response.ok) {
    yield put(Actions.setSurvey(response.data))
  } else {
    yield put(addResponseError(response))
  }
}
export function* addSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.ADD_SURVEY, addSurveyWorker)
}

function* updateSurveyWorker({ surveyId, survey }: ReturnType<typeof Actions.updateSurvey>) {
  const response = yield call(Api.updateSurvey, surveyId, survey)
  if (response.ok) {
    yield put(Actions.setUpdatedSurvey(response.data))
  } else {
    yield put(addResponseError(response))
  }
}
export function* updateSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.UPDATE_SURVEY, updateSurveyWorker)
}

function* deleteSurveyWorker({ surveyId }: ReturnType<typeof Actions.deleteSurvey>) {
  const response = yield call(Api.deleteSurvey, surveyId)
  if (response.ok) {
    yield put(Actions.removeSurvey(surveyId))
  } else {
    yield put(addResponseError(response))
  }
}
export function* deleteSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.DELETE_SURVEY, deleteSurveyWorker)
}

function* addQuestionnairesToSelectedSurvewWorker({
  questionnairesId,
}: ReturnType<typeof Actions.addQuestionnairesToSurvey>) {
  const selectedSurvey: Survey | null = yield select(getSelectedSurvey)
  const tree: SurveyBranchConfig[] = yield select((state: RootState) => state.survey.tree)
  const branch: SurveyBranchConfig | null = yield select((state: RootState) => state.survey.branch)

  if (selectedSurvey === null || branch === null) {
    console.warn('No selected survey')
    return
  }

  yield put(Actions.addScoresToSurveyBranch(selectedSurvey.id, questionnairesId, tree, branch.uuid))
}
export function* addQuestionnairesToSelectedSurvewWatcher() {
  yield takeEvery(
    SurveyActionTypes.ADD_QUESTIONNAIRES_TO_SELECTED_SURVEY,
    addQuestionnairesToSelectedSurvewWorker,
  )
}

function* addScoresToSurveyWorker({
  surveyId,
  tree,
  branchUuid,
  scoresIds,
}: ReturnType<typeof Actions.addScoresToSurveyBranch>) {
  const newTree = SurveyUtils.addScoresToTree(
    SurveyUtils.buildSurveyScores(scoresIds),
    tree,
    branchUuid,
  )
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* addScoresToSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.ADD_SCORES_TO_SURVEY_BRANCH, addScoresToSurveyWorker)
}

function* deleteSurveyScoreWorker({
  surveyId,
  tree,
  branchUuid,
  score,
}: ReturnType<typeof Actions.deleteSurveyScore>) {
  const newTree = SurveyUtils.removeBranchItemFromTree(score, tree, branchUuid)
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* deleteSurveyScoreWatcher() {
  yield takeEvery(SurveyActionTypes.DELETE_SURVEY_SCORE, deleteSurveyScoreWorker)
}

function* addQuestionToSurveyWorker({
  surveyId,
  tree,
  branchUuid,
  label,
  answers,
}: ReturnType<typeof Actions.addQuestionToSurveyBranch>) {
  // We first build the survey question from label and answers
  // Then we add it to the tree and create the new branches from question answers
  const newTree = SurveyUtils.createBranchesInTree(
    SurveyUtils.addQuestionToTree(
      SurveyUtils.buildSurveyQuestion(label, answers),
      tree,
      branchUuid,
    ),
    branchUuid,
    answers.map((x) => x.branchUuid),
  )
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* addQuestionToSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.ADD_QUESTION_TO_SURVEY_BRANCH, addQuestionToSurveyWorker)
}

function* deleteSurveyQuestionWorker({
  surveyId,
  tree,
  branchUuid,
  question,
}: ReturnType<typeof Actions.deleteSurveyQuestion>) {
  // We first delete the question in survey, then delete all the related branches in tree
  const newTree = SurveyUtils.removeBranchesFromTree(
    SurveyUtils.removeBranchItemFromTree(question, tree, branchUuid),
    question.answers.map((x) => x.branchUuid),
  )
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}

export function* deleteSurveyQuestionWatcher() {
  yield takeEvery(SurveyActionTypes.DELETE_SURVEY_QUESTION, deleteSurveyQuestionWorker)
}

function* editQuestionInSurveyWorker({
  surveyId,
  tree,
  branchUuid,
  question,
  label,
  answers,
}: ReturnType<typeof Actions.editQuestionInSurveyBranch>) {
  // We update the tree with the edited question
  // Then if there is new branches in the question we add it to the tree
  const newTree = SurveyUtils.createBranchesInTree(
    SurveyUtils.editQuestionInTree(question, { ...question, label, answers }, tree, branchUuid),
    branchUuid,
    answers.map((x) => x.branchUuid),
  )
  // We filter the tree in case an answer has been deleted and the branch is no longer used
  const filteredTree = SurveyUtils.filterUnusedBranches(
    newTree,
    answers.map((x) => x.branchUuid),
  )
  const response = yield call(Api.updateSurvey, surveyId, { survey: filteredTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* editQuestionInSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.EDIT_QUESTION_IN_SURVEY_BRANCH, editQuestionInSurveyWorker)
}

function* toggleSurveyScoreFieldWorker({
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  fieldId,
  checked,
}: ReturnType<typeof Actions.toggleSurveyScoreField>) {
  const newTree = SurveyUtils.toggleFieldInTree(tree, branchUuid, scoreUuid, fieldId, checked)
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* toggleSurveyScoreFieldWatcher() {
  yield takeEvery(SurveyActionTypes.TOGGLE_SURVEY_SCORE_FIELD, toggleSurveyScoreFieldWorker)
}

function* setScoreExcludedFieldsWorker({
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  fieldsIds,
}: ReturnType<typeof Actions.setScoreExcludedFields>) {
  const newTree = SurveyUtils.updateScoreExcludedFields(tree, branchUuid, scoreUuid, fieldsIds)
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* setScoreExcludedFieldsWatcher() {
  yield takeEvery(SurveyActionTypes.SET_SCORE_EXCLUDED_FIELDS, setScoreExcludedFieldsWorker)
}

function* updateScoreFieldLabelWorker({
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  fieldId,
  label,
}: ReturnType<typeof Actions.updateScoreFieldLabel>) {
  const newTree = SurveyUtils.updateFieldLabelInTree(tree, branchUuid, scoreUuid, fieldId, label)
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* updateScoreFieldLabelWatcher() {
  yield takeEvery(SurveyActionTypes.UPDATE_SCORE_FIELD_LABEL, updateScoreFieldLabelWorker)
}

function* updateSurveyConclusionWorker({
  surveyId,
  value,
}: ReturnType<typeof Actions.updateSurveyConclusion>) {
  const response = yield call(Api.updateSurvey, surveyId, { finish: value })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyConclusion(surveyId, value))
  } else {
    yield put(addResponseError(response))
  }
}
export function* updateSurveyConclusionWatcher() {
  yield takeEvery(SurveyActionTypes.UPDATE_SURVEY_CONCLUSION, updateSurveyConclusionWorker)
}

function* updateScoreIntroductionWorker({
  surveyId,
  tree,
  branchUuid,
  scoreUuid,
  introduction,
}: ReturnType<typeof Actions.updateScoreIntroduction>) {
  const newTree = SurveyUtils.updateScoreIntroductionInTree(
    tree,
    branchUuid,
    scoreUuid,
    introduction,
  )
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}
export function* updateScoreIntroductionWatcher() {
  yield takeEvery(SurveyActionTypes.UPDATE_SURVEY_SCORE_INTRODUCTION, updateScoreIntroductionWorker)
}

function* duplicateSurveyWorker({ survey }: ReturnType<typeof Actions.duplicateSurvey>) {
  const surveyApi: SurveyApi = {
    pathologyId: survey.pathologyId,
    name: `Copie de ${survey.name}`,
    survey: survey.survey,
    finish: survey.finish,
    active: survey.active,
    tagIds: survey.tagIds,
  }
  const response = yield call(Api.duplicateSurvey, surveyApi)
  if (response.ok) {
    yield put(Actions.setSurvey(response.data))
  } else {
    yield put(addResponseError(response))
  }
}

export function* duplicateSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.DUPLICATE_SURVEY, duplicateSurveyWorker)
}

function* getBranchScoresWorker({
  surveyId,
  branchScoresIds,
}: ReturnType<typeof Actions.getBranchScores>) {
  if (restuxQuestionnairesApiConfig.getItem) {
    const responses = yield all(
      branchScoresIds.map((id) => {
        return call(Api.getSurveyQuestionnaire, surveyId, id)
      }),
    )
    const failedResponse = responses.find((response) => !response.ok)
    if (failedResponse) {
      yield put(addResponseError(failedResponse))
    } else {
      const result = yield all(
        responses.map((response) =>
          put(questionnairesActions.actions.storeSetItemDetails(response.data)),
        ),
      )
      if (result) {
        yield put(Actions.setSurveyItemLoading(false))
      }
    }
  }
  yield put(hideBottomPanel())
}

export function* getBranchScoresWatcher() {
  yield takeEvery(SurveyActionTypes.GET_BRANCH_SCORES, getBranchScoresWorker)
}

function* updateSurveyBranchItemsWorker({
  surveyId,
  tree,
  branchUuid,
  items,
}: ReturnType<typeof Actions.updateSurveyBranchItems>) {
  const newTree = SurveyUtils.updateSurveyBranchItemsInTree(tree, branchUuid, items)
  const response = yield call(Api.updateSurvey, surveyId, { survey: newTree })
  if (response.ok) {
    yield put(Actions.setUpdatedSurveyBranch(response.data, response.data.survey, branchUuid))
  } else {
    yield put(addResponseError(response))
  }
}

export function* updateSurveyBranchItemsWatcher() {
  yield takeEvery(SurveyActionTypes.UPDATE_SURVEY_BRANCH_ITEMS, updateSurveyBranchItemsWorker)
}

function* selectSurveyItemWorker({
  surveyId,
  withFetch,
}: ReturnType<typeof Actions.selectSurveyItem>) {
  if (withFetch) {
    yield put(Actions.setSurveyItemLoading(true))
    const response = yield call(Api.getSurvey, surveyId)
    if (response.ok && response.data) {
      yield put(Actions.setSelectedSurvey(surveyId, response.data as Survey))
    } else {
      yield put(addResponseError(response))
    }
    yield put(Actions.setSurveyItemLoading(false))
  } else {
    yield put(Actions.setSelectedSurvey(surveyId, null))
  }
  yield put(Actions.setRoot(surveyId))
}

export function* setSelectedSurveyWatcher() {
  yield takeEvery(SurveyActionTypes.SELECT_SURVEY_ITEM, selectSurveyItemWorker)
}

function* duplicateDocumentToAnotherUserWorker({
  surveyId,
  userId,
}: ReturnType<typeof Actions.duplicateSurveyToAnotherUser>) {
  const response = yield call(Api.duplicateSurveyToAnotherUer, {
    surveyId,
    userId,
  })
  if (response.ok) {
    yield put(
      addValid(
        'Duplication réalisée',
        `Le questionnaire a bien été dupliqué sur l'utilisateur ${userId} !`,
      ),
    )
  } else {
    console.error('an error occured', response.problem)
    yield put(addResponseError(response))
  }
}

export function* duplicateSurveyToAnotherUserWatcher() {
  yield takeEvery(
    SurveyActionTypes.DUPLICATE_SURVEY_TO_ANOTHER_USER,
    duplicateDocumentToAnotherUserWorker,
  )
}
