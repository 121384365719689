import React, { FunctionComponent, MouseEvent, useState, useEffect, useMemo } from 'react'

import { BaseAnswer } from '../../../../model/Questionnaire'
import { QuestionWrapper } from '../../atoms/QuestionWrapper/index'
import { QuestionTitle } from '../../atoms/QuestionTitle/QuestionTitle.component'
import { SelectableAnswer } from '../../atoms/SelectableAnswer/index'
import {
  QuestionComponentProps,
  isAutocompletable,
  hasAtLeastOneImage,
  getAutocompleteValues,
  SetAnswerValueCallback,
  DeleteQuestionCallback,
  EditQuestionCallback,
  ShowSubQuestionsCallback,
  AddSubQuestionCallback,
} from '../../Questionnaire.model'
import { isDefined } from '../../../../misc/functions.utilities'
import { SubQuestions } from '../../SubQuestions/index'
import { SelectInput } from '../../../shared'
import { SelectOption } from '../../../../model/SelectOption'

interface QcuProps extends QuestionComponentProps {
  onDelete?: DeleteQuestionCallback
  onEdit?: EditQuestionCallback
  onAddSubQuestion?: AddSubQuestionCallback
  answerValueId?: number | null
  selectAnswer?: (answerId?: number) => void
  setSubQuestionsAnswerValues?: SetAnswerValueCallback
  subQuestionsShown: boolean
  onDeleteSubQuestion?: DeleteQuestionCallback
  onShowSubQuestions?: ShowSubQuestionsCallback
}

export const Qcu: FunctionComponent<QcuProps> = ({
  question,
  answerValueId,
  mode,
  onDelete,
  onEdit,
  onAddSubQuestion,
  disabled,
  isReadonly,
  selectAnswer,
  subQuestionsShown,
  onShowSubQuestions,
  onDeleteSubQuestion,
  setSubQuestionsAnswerValues,
}) => {
  const [localAnswerValueId, setLocalAnswerValueId] = useState<number | null | undefined>(
    answerValueId,
  )
  const [optimist, setOptimist] = useState(false)

  const options = useMemo(
    () =>
      question.answers.map((answer) => ({
        value: answer.id,
        label: answer.title,
      })),
    [question.answers],
  )

  useEffect(() => {
    const isInitialHydration = localAnswerValueId === undefined
    const isReconciliation = localAnswerValueId !== answerValueId

    if (isInitialHydration || isReconciliation) {
      setLocalAnswerValueId(answerValueId)
      setOptimist(false)
    }

    if (optimist && localAnswerValueId === answerValueId) {
      setOptimist(false)
    }

    // Repli des sous-questions en fonction de la réponse sélectionnée
    if (subQuestionsShown && onShowSubQuestions) {
      if (answerValueId === undefined) {
        onShowSubQuestions(false)
      } else {
        const selectedAnswer = question.answers.find((ans) => ans.id === answerValueId)
        if (
          // Si la réponse sélectionnée a des sous-questions, on les affiche.
          // Sinon on cache les sous-questions
          selectedAnswer &&
          (!selectedAnswer.childQuestions || selectedAnswer.childQuestions.length < 1)
        ) {
          onShowSubQuestions(false)
        }
      }
    }
    // TODO #MAIN-751
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [answerValueId, onShowSubQuestions])

  const handleOnClick = ({ id }: BaseAnswer, activated: boolean) => (
    event: MouseEvent<HTMLDivElement>,
  ) => {
    event.preventDefault()

    const newAnswerId = id !== localAnswerValueId && !activated ? id : undefined

    // Rendu optimiste
    setLocalAnswerValueId(newAnswerId)
    setOptimist(true)
    // Modification
    if (selectAnswer) {
      selectAnswer(newAnswerId)
    }
  }

  const onAutocompleteChange = (selected: SelectOption<number>) => {
    if (selectAnswer) {
      selectAnswer(selected ? selected.value : undefined)
    }
  }

  const switchToSelect = isAutocompletable(question.answers)
  const withImage = useMemo(() => hasAtLeastOneImage(question.answers), [question.answers])

  const subQuestionsNode = (
    <SubQuestions
      mode={mode}
      disabled={disabled}
      isReadonly={isReadonly}
      question={question}
      answerValuesIds={isDefined(answerValueId) ? [answerValueId] : answerValueId}
      subQuestionsShown={subQuestionsShown}
      onAddSubQuestion={onAddSubQuestion}
      onShowSubQuestions={onShowSubQuestions}
      setAnswerValue={setSubQuestionsAnswerValues}
      onEdit={onEdit}
      onDelete={onDeleteSubQuestion}
    />
  )

  return (
    <QuestionWrapper
      mode={mode}
      question={question}
      filled={isDefined(localAnswerValueId)}
      title={<QuestionTitle question={question} />}
      subQuestions={subQuestionsNode}
      onEdit={isReadonly ? undefined : onEdit}
      onDelete={isReadonly ? undefined : onDelete}
    >
      {!switchToSelect &&
        question.answers.map((answer) => {
          const activated =
            (optimist && answerValueId === answer.id) || localAnswerValueId === answer.id

          return (
            <SelectableAnswer
              withImage={withImage}
              key={answer.id}
              answer={answer}
              activated={activated}
              onClick={handleOnClick(answer, activated)}
              optimist={optimist}
              disabled={disabled || optimist}
              warning={question.sharedInMedicalEvent}
            />
          )
        })}
      {switchToSelect && (
        <SelectInput
          readonly={disabled}
          options={options}
          title="Liste des réponses"
          value={getAutocompleteValues(answerValueId ? [answerValueId] : [], question.answers)[0]}
          onSelect={onAutocompleteChange}
        />
      )}
    </QuestionWrapper>
  )
}
