import getApi from '../../../../api/apisauceConfig'
import { Treatment } from '../../../../model/Treatment'
import { PaginatedList } from '../../../../model/Pagination'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import { SerializedTreatment } from './PatientTreatments.model'
import { deserializePatientTreatmentsPaginatedResponse } from './PatientTreatments.mapper'

export const treatmentsBaseURL = 'patients/:patientId/treatments/:treatmentId?'

export const restuxPatientTreatmentsApiConfig: RestuxApiConfig<Treatment, Treatment> = {
  url: treatmentsBaseURL,
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<SerializedTreatment>>(route)
      .then(deserializePatientTreatmentsPaginatedResponse),
}
