import { isDefined } from '../../../../misc/functions.utilities'
import { BottomPanel } from '../BottomPanel'
import { Button } from '../../buttons'
import { FullLoader } from '../../loading'
import { Formik, FormikProps } from 'formik'
import styles from './ContactConfigureBottomPanel.module.scss'
import { FormikSelectInput } from '../../inputs'
import {
  CONTACT_GREETING_START_OPTIONS,
  CONTACT_GREETING_END_OPTIONS,
  ContactConfigureBottomPanelProps,
  ContactConfigureSchema,
} from './ContactConfigureBottomPanel.model'
import { ContactConfiguration } from '../../../../model/Contact'
import { useCallback, useEffect, useState } from 'react'
import { useGetContact, useUpdateContactConfiguration } from '../../../../hooks/queries/contact'

export const ContactConfigureBottomPanel = ({
  itemId,
  isUnmounting,
  onRequestClose,
  onItemEdited,
}: ContactConfigureBottomPanelProps) => {
  const {
    query: { data: contact, isLoading },
  } = useGetContact({
    id: itemId ?? '',
  })

  const { mutate: updateContactConfiguration } = useUpdateContactConfiguration()

  const [contactConfiguration, setContactConfiguration] = useState<ContactConfiguration>(
    contact?.contactConfiguration ?? {
      startGreeting: null,
      endGreeting: null,
    },
  )

  const isButtonDisabled = !contactConfiguration.startGreeting || !contactConfiguration.endGreeting

  useEffect(() => {
    if (!contact?.contactConfiguration) {
      return
    }

    setContactConfiguration(contact?.contactConfiguration)
  }, [contact])

  const handleValidate = useCallback(() => {
    if (onItemEdited) {
      onItemEdited(contactConfiguration)
    }

    updateContactConfiguration({
      id: itemId as string,
      payload: contactConfiguration,
    })
  }, [contactConfiguration, itemId, onItemEdited, updateContactConfiguration])

  const topBarActions = (
    <Button
      onClick={handleValidate}
      theme="primary"
      label="Valider"
      disabled={isButtonDisabled}
      testId="button-bottom_panel-submit"
      type="submit"
    />
  )
  return (
    <BottomPanel
      title="Éditer l'élément"
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
      actions={topBarActions}
    >
      {isDefined(itemId) && !isLoading ? (
        <Formik
          initialValues={contactConfiguration}
          validationSchema={ContactConfigureSchema}
          onSubmit={() => {}}
          enableReinitialize
          validateOnMount
        >
          {(props: FormikProps<ContactConfiguration>) => (
            <div
              onKeyPress={(e) => {
                if (e.key === 'Enter' && props.isValid) {
                  props.handleSubmit()
                }
              }}
            >
              <div className={styles.formContainer}>
                <FormikSelectInput
                  label="Politesse Début"
                  fieldName="startGreeting"
                  colorPreset="dark"
                  placeholder="Veuillez sélectionner une formule de politesse"
                  options={CONTACT_GREETING_START_OPTIONS}
                  value={CONTACT_GREETING_START_OPTIONS.find(
                    (option) => option.value === props.values.startGreeting,
                  )}
                  onChange={(selectedOption) => {
                    if (!selectedOption) {
                      return
                    }

                    setContactConfiguration({
                      ...contactConfiguration,
                      startGreeting: selectedOption,
                    })
                    props.setFieldValue('startGreeting', selectedOption)
                  }}
                  required
                />
                <FormikSelectInput
                  label="Politesse Fin"
                  fieldName="endGreeting"
                  placeholder="Veuillez sélectionner une formule de politesse"
                  options={CONTACT_GREETING_END_OPTIONS}
                  colorPreset="dark"
                  value={CONTACT_GREETING_END_OPTIONS.find(
                    (option) => option.value === props.values.endGreeting,
                  )}
                  onChange={(selectedOption) => {
                    if (!selectedOption) {
                      return
                    }

                    setContactConfiguration({
                      ...contactConfiguration,
                      endGreeting: selectedOption,
                    })
                    props.setFieldValue('endGreeting', selectedOption)
                  }}
                  required
                />
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <FullLoader />
      )}
    </BottomPanel>
  )
}
