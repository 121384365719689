import { VitalCardActionTypes } from './vitalCard.model'
import { Patient } from '../../../model/Patient'

const readVitalCardInfos = (readToUpdate = false) => ({
  type: VitalCardActionTypes.READ_VITAL_CARD_INFOS,
  readToUpdate,
})

const openPatientWithVitalCard = (patientId: number) => ({
  type: VitalCardActionTypes.OPEN_PATIENT_PAGE,
  patientId,
})

const setVitalCardPatient = (vitalCardPatient: Partial<Patient> | null, update = false) => ({
  type: VitalCardActionTypes.SET_VITAL_CARD_PATIENT,
  vitalCardPatient,
  update,
})

const setBeneficiariesListVitalCard = (beneficiaries: Array<Partial<Patient>>) => ({
  type: VitalCardActionTypes.SET_BENEFICIARIES_LIST_VITAL_CARD,
  beneficiaries,
})

const applyVitalCardDifferences = (confirm: boolean) => ({
  type: VitalCardActionTypes.APPLY_VITAL_CARD_DIFFERENCES,
  confirm,
})

const setVitalCardPatientDifferences = (differences: Partial<Patient> | null) => ({
  type: VitalCardActionTypes.SET_VITAL_CARD_DIFFERENCES,
  differences,
})

const setCpsPincodeModalOpen = (open: boolean) => ({
  type: VitalCardActionTypes.CPS_PINCODE_MODAL_OPEN,
  open,
})

const setCpsPincode = (pincode: string) => ({
  type: VitalCardActionTypes.SET_CPS_PINCODE,
  pincode,
})

export const vitalCardActions = {
  readVitalCardInfos,
  openPatientWithVitalCard,
  setVitalCardPatient,
  setBeneficiariesListVitalCard,
  applyVitalCardDifferences,
  setVitalCardPatientDifferences,
  setCpsPincodeModalOpen,
  setCpsPincode,
}
