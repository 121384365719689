import { FC, useCallback, useMemo } from 'react'
import { HealthInformationBlockProps } from './HealthInformationBlock.model'
import { getCssVariable } from '../../../../design-system/utils'
import { BIRTH_PLACE_CODE_REGEX, Sex } from '../../../../model/Patient'
import {
  FormBlock,
  FormikSecuriteSocialNumberInput,
  FormikSelectInput,
  TooltipWrapper,
  Icon,
  FormikTextInput,
  FormikSwitch,
} from '../../../shared'
import { DisabledINSITooltipWrapper } from '../DisabledINSITootipWrapper'
import { useFormikContext } from 'formik'
import { PatientFormFields } from '../PatientForm.model'
import styles from '../PatientForm.module.scss'
import { useDisableInsiFields } from '../useDisableInsiFields.hook'
import { isINSIValidated } from '../../../../misc/patient.utilities'

const ASSIGNING_AUTHORITIES_LABEL = (
  <div className="flex flex-col text-left">
    <span>INS : Identifiant national de santé</span>
    <span>INS-C : Identifiant national de santé calculé</span>
    <span>NIA : Numéro identifiant d'attente</span>
    <span>NIR : Numéro d'inscription au RNIPP</span>
    <span>OID : Identifiant d'objet (Object Identifier)</span>
  </div>
)

export const HealthInformationBlock: FC<HealthInformationBlockProps> = ({
  assigningAuthorities,
  hasAccessToInsOid,
  isInsiFeatureEnabled,
}) => {
  const { values, initialValues, setValues } = useFormikContext<PatientFormFields>()

  const disableInsiFields = useDisableInsiFields()

  const assigningAuthoritiesOptions = useMemo(
    () =>
      assigningAuthorities.map(({ oid, label }) => ({
        value: oid,
        label: `${label} (${oid})`,
      })),
    [assigningAuthorities],
  )

  const findSelectedAssigningAuthorities = useCallback(
    (value?: string) =>
      assigningAuthoritiesOptions.find((authorityOption) => authorityOption.value === value) ??
      undefined,
    [assigningAuthoritiesOptions],
  )

  const handleInseeChange = useCallback(
    (value: string) => {
      const extractedBirthPlaceCode = value.replaceAll(' ', '').substring(5, 10)
      const isExistingBirthPlaceCodeValid = values.birthPlaceCode?.match(BIRTH_PLACE_CODE_REGEX)

      setValues({
        ...values,
        inseeNumber: value,
        birthPlaceCode: isExistingBirthPlaceCodeValid
          ? values.birthPlaceCode
          : extractedBirthPlaceCode,
      })
    },
    [setValues, values],
  )

  return (
    <FormBlock icon="checkup" label="Santé">
      {hasAccessToInsOid && (
        <>
          <DisabledINSITooltipWrapper display={isINSIValidated(values.identityStatus)}>
            <FormikSecuriteSocialNumberInput
              fieldName="inseeNumber"
              label="Matricule INS"
              placeholder="x xx xx xx xxx xxx xx"
              colorPreset="light"
              disabled={disableInsiFields}
              onChange={handleInseeChange}
            />
          </DisabledINSITooltipWrapper>
          {isInsiFeatureEnabled && (
            <div className="w-full flex items-center space-x-4">
              <DisabledINSITooltipWrapper display={isINSIValidated(values.identityStatus)}>
                <div className="w-full">
                  <FormikSelectInput
                    label="OID"
                    fieldName="assigningAuthority.oid"
                    placeholder="Veuillez sélectionner un OID"
                    options={assigningAuthoritiesOptions}
                    disabled={disableInsiFields}
                    value={findSelectedAssigningAuthorities(values.assigningAuthority?.oid)}
                    required
                  />
                </div>
              </DisabledINSITooltipWrapper>
              <TooltipWrapper pointerDirection="left" content={ASSIGNING_AUTHORITIES_LABEL}>
                <Icon
                  icon="infoCircle"
                  color={getCssVariable('primary', 'default')}
                  size="normal"
                />
              </TooltipWrapper>
            </div>
          )}
        </>
      )}
      <FormikTextInput
        fieldName="healthInsuranceNumber"
        label="Assurance complémentaire"
        colorPreset="light"
      />
      <div className="space-y-4 font-medium text-base text-shades-3">
        <FormikSwitch
          name={values.sex === Sex.FEMALE ? 'Cette patiente est décédée' : 'Ce patient est décédé'}
          fieldName="isDeceased"
          borderBottom
        />
      </div>
      {initialValues.doctor && (
        <>
          <FormikTextInput fieldName="doctor" label="Médecin traitant *" colorPreset="light" />
          <div className={styles.deprecated}>
            (*) Ce champ est obsolète. Veuillez le rechercher via les correspondants pour l'associer
            au patient.
          </div>
        </>
      )}
    </FormBlock>
  )
}
