import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { getQuestion } from '../../../data/questions'
import { questionsKeys } from './questions.keys'
import { QuestionLight } from '../../../model/Questionnaire'

interface GetQuestionParams {
  id: number
  enabled?: boolean
}

export const useGetQuestion = ({ id, enabled = true }: GetQuestionParams) => {
  const queryKey = useMemo(() => questionsKeys.detail(id), [id])

  const queryReturn = useQuery<QuestionLight>(
    queryKey,
    async () => {
      const { data, ok } = await getQuestion(id)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la question`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      enabled: enabled,
    },
  )

  return { query: queryReturn }
}
