import { FunctionComponent, useMemo, useState } from 'react'
import { withTrackingEvent, FwTrackingEvent } from '../../../misc/Tracking'
import { LightPatient } from '../../../model/Patient'
import { PatientsListProps } from './PatientsList.model'
import {
  PatientListItem,
  PatientMenuActions,
  PatientsListActions,
  PatientsListExtendedActions,
} from '../../../components/patient'
import {
  DeleteValidationModal,
  OwnerModal,
  VitalCardButton,
  LabeledButton,
  ListNavBar,
  List,
  VitalCardBeneficiariesModal,
} from '../../../components/shared'
import {
  getDisplayedFirstname,
  getDisplayedLastname,
  isPatientFormReachable,
} from '../../../misc/patient.utilities'
import { formatISO } from 'date-fns'
import { isDoctor } from '../../../misc/user.utilities'
import { NavBar } from '../../../components/layout'
import styles from './PatientsList.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SharingConfigKeys } from '../../../model/Team'
import { useGetTags } from '../../../hooks/queries/tag'
import {
  useGetInfinitePatient,
  useDeletePatient,
  useChangePatientOwner,
} from '../../../hooks/queries/patients'
import { Filters } from '../../../model/Filters'
import { isEqual, reduce } from 'lodash'
import { isDefined } from '../../../misc/functions.utilities'
import { useOpenPatientHistoric, useCreateEventInPlanning } from '../../../hooks/utils/doctolib'
import { useAtomValue } from 'jotai'
import { zipperStatusAtom } from '../../../state/doctolib'
import { VitalCardDifferencesModal } from '../../../components/shared/modal/VitalCardDifferencesModal'

const MIN_SEARCH_FILTER_SIZE = 3

const PATIENT_FILTERS_INITIAL_STATE = {
  search: '',
  drugSearch: '',
  prescriptionReason: '',
  activePrinciple: '',
  tagIds: [],
  userIds: [],
  myFollowedPatient: false,
  drugStartDate: null,
  drugEndDate: null,
  atcCodeExternalId: null,
  sex: [],
}

/**
 * Fonctionnement de la recherche
 *   - 3 caracteres minimum
 *   - Si la liste a deja été filtrée  et que la value est en dessous du nombre minimal de caractères alors on clear le filtre
 * Le composant porte 2 state pour la recherche "search" et "debouncedSearch"
 * "search" est le reflet synchrone de la valeur du champ texte
 * "debouncedSearch" est la valeur débouncée du champs de recherche. Son changement provoque les requêtes de recherche
 */
export const PatientsList: FunctionComponent<PatientsListProps> = ({
  userTeams,
  currentUser,
  loggedUser,
  beneficiaries,
  openPatientWithVitalCard,
  setVitalCardPatient,
  setBeneficiariesListVitalCard,
  vitalCardService,
}) => {
  const doctolibStatus = useAtomValue(zipperStatusAtom)
  const { triggerOpenPatientHistoric } = useOpenPatientHistoric()
  const { triggerCreateEventInPlanning } = useCreateEventInPlanning()
  const { tagList: tags } = useGetTags()
  const [searchParams, setSearchParams] = useSearchParams()
  const { mutate: deletePatient } = useDeletePatient()
  const { mutate: changePatientOwner } = useChangePatientOwner()

  const getSearchParamsFilters = (urlSearchParams: URLSearchParams): Filters => {
    const searchParamsFilters = {}
    for (let [key, value] of urlSearchParams.entries()) {
      if (isDefined(searchParamsFilters[key])) {
        const prevValue: Array<any> = Array.isArray(searchParamsFilters[key])
          ? [...searchParamsFilters[key]]
          : [searchParamsFilters[key]]
        searchParamsFilters[key] = [...prevValue, value]
      } else {
        searchParamsFilters[key] = value
      }
    }

    return searchParamsFilters
  }

  const filters = useMemo(() => {
    const searchParamsFilters = getSearchParamsFilters(searchParams)
    return {
      ...PATIENT_FILTERS_INITIAL_STATE,
      ...(searchParamsFilters.search && { search: searchParamsFilters.search }),
      ...(searchParamsFilters.drugSearch && { drugSearch: searchParamsFilters.drugSearch }),
      ...(searchParamsFilters.prescriptionReason && {
        prescriptionReason: searchParamsFilters.prescriptionReason,
      }),
      ...(searchParamsFilters.tagIds?.length && {
        tagIds: Array.isArray(searchParamsFilters.tagIds)
          ? searchParamsFilters.tagIds
          : [searchParamsFilters.tagIds],
      }),
      ...(searchParamsFilters.sex?.length && {
        sex: Array.isArray(searchParamsFilters.sex)
          ? searchParamsFilters.sex
          : [searchParamsFilters.sex],
      }),
      ...(searchParamsFilters.userIds?.length && {
        userIds: Array.isArray(searchParamsFilters.userIds)
          ? searchParamsFilters.userIds
          : [searchParamsFilters.userIds],
      }),
      ...(searchParamsFilters.myFollowedPatient && {
        myFollowedPatient: searchParamsFilters.myFollowedPatient,
      }),
      ...(searchParamsFilters.drugStartDate && {
        drugStartDate: new Date(searchParamsFilters.drugStartDate),
      }),
      ...(searchParamsFilters.drugEndDate && {
        drugEndDate: new Date(searchParamsFilters.drugEndDate),
      }),
      ...(searchParamsFilters.atcCodeExternalId && {
        atcCodeExternalId: searchParamsFilters.atcCodeExternalId,
      }),
      ...(searchParamsFilters.activePrinciple && {
        activePrinciple: searchParamsFilters.activePrinciple,
      }),
    }
  }, [searchParams])

  const activeFilters = useMemo(() => {
    return {
      ...(filters.sex.length && { sex: filters.sex }),
      ...(filters.tagIds.length && { tagIds: filters.tagIds }),
      ...(filters.userIds.length && { userIds: filters.userIds }),
      ...(filters.userIds.length && { userIds: filters.userIds }),
      ...(filters.myFollowedPatient && {
        myFollowedPatient: filters.myFollowedPatient,
      }),
      ...(filters.drugStartDate && {
        drugStartDate: formatISO(filters.drugStartDate),
      }),
      ...(filters.drugEndDate && { drugEndDate: formatISO(filters.drugEndDate) }),
      ...(filters.atcCodeExternalId && {
        atcCodeExternalId: filters.atcCodeExternalId,
      }),
      ...(filters.activePrinciple && {
        activePrinciple: filters.activePrinciple,
      }),
      ...(filters.search &&
        filters.search.length >= MIN_SEARCH_FILTER_SIZE && {
          search: filters.search,
        }),
      ...(filters.drugSearch &&
        filters.drugSearch.length >= MIN_SEARCH_FILTER_SIZE && {
          drugSearch: filters.drugSearch,
        }),
      ...(filters.prescriptionReason &&
        filters.prescriptionReason.length >= MIN_SEARCH_FILTER_SIZE && {
          prescriptionReason: filters.prescriptionReason,
        }),
    }
  }, [filters])

  const {
    patientList,
    paginationState,
    query: { isLoading, fetchNextPage },
  } = useGetInfinitePatient({ filters: activeFilters })

  const [selectedId, setSelectedId] = useState<number>()
  const [changingOwnerPatient, setChangingOwnerPatient] = useState<LightPatient | undefined>()
  const hasTeams = userTeams.length > 0
  const extendedActionsOpenDefaultValue =
    !!searchParams.get('drugSearch') ||
    !!searchParams.get('activePrinciple') ||
    !!searchParams.get('drugStartDate') ||
    !!searchParams.get('drugEndDate') ||
    !!searchParams.get('prescriptionReason') ||
    !!searchParams.get('atcCodeExternalId')

  const [extendedActionsOpen, setExtendedActionsOpen] = useState(
    extendedActionsOpenDefaultValue === false ? undefined : true,
  )

  const navigate = useNavigate()

  const handleFiltersChange = (filters: Filters): void => {
    setSearchParams((prev) => {
      return reduce(
        {
          ...getSearchParamsFilters(prev),
          ...filters,
        },
        function (result, value, key) {
          return isEqual(value, PATIENT_FILTERS_INITIAL_STATE[key])
            ? result
            : { ...result, [key]: value }
        },
        [],
      )
    })
  }

  const handleAddItem = () => {
    navigate('/patients/new')
  }

  const navigateToPatient = withTrackingEvent(FwTrackingEvent.DISPLAY_PATIENT, {
    origin: 'patientsList',
  })((patient: LightPatient) => {
    navigate(`/patients/${patient.id}`)
  })

  const handleEditItem = (patient: LightPatient) => {
    navigate(`/patients/${patient.id}/details`)
  }

  const handleDeleteItem = (patient: LightPatient) => {
    handleShowDeletePatient(patient.id)
  }

  const handleShowDeletePatient = (patientId: number) => {
    setSelectedId(patientId)
  }

  const handleValidateDeletePatient = () => {
    if (selectedId) {
      deletePatient({
        patientId: selectedId,
      })
      setSelectedId(undefined)
    }
  }

  const isPatientFormLinkVisible = isPatientFormReachable(currentUser, loggedUser)

  return (
    <>
      <NavBar>
        <ListNavBar itemCount={paginationState?.itemCount ?? null} title="Liste des patients" />
      </NavBar>
      <div className={styles.list}>
        <List
          pageCount={paginationState?.pageCount ?? null}
          currentPage={paginationState?.currentPage ?? 0}
          addItemLabel="Nouveau patient"
          testId="patient"
          loading={isLoading}
          items={patientList}
          renderItem={(patient) => <PatientListItem patient={patient} currentUser={currentUser} />}
          renderActions={() => (
            <PatientsListActions
              tags={tags}
              userTeams={userTeams}
              filters={filters}
              onSearchFiltersChange={handleFiltersChange}
            />
          )}
          renderCustomActionButtons={() => (
            <VitalCardButton
              renderButton={({ readVitalCardInfos }) => (
                <LabeledButton
                  label="Lecture Vitale"
                  icon="vitalCardReader"
                  onClick={() => readVitalCardInfos(false)}
                />
              )}
            />
          )}
          renderExtendedActions={() =>
            isDoctor(currentUser) &&
            currentUser.preferences.enabledFeatures.drug && (
              <PatientsListExtendedActions
                filters={filters}
                onSearchFiltersChange={handleFiltersChange}
              />
            )
          }
          extendedActionsOpen={extendedActionsOpen}
          toggleExtendedActions={
            isDoctor(currentUser) && currentUser.preferences.enabledFeatures.drug
              ? setExtendedActionsOpen
              : undefined
          }
          renderCustomItemActions={(patient) => (
            <PatientMenuActions
              doctolibStatus={doctolibStatus}
              onChangeOwner={
                patient.isEditable && hasTeams ? () => setChangingOwnerPatient(patient) : undefined
              }
              onEdit={
                patient.isEditable && isPatientFormLinkVisible
                  ? () => handleEditItem(patient)
                  : undefined
              }
              onDelete={patient.isDeletable ? () => handleDeleteItem(patient) : undefined}
              onCreateEvent={() => triggerCreateEventInPlanning({ patientId: patient.id })}
              onOpenPatientHistoric={() => triggerOpenPatientHistoric({ patientId: patient.id })}
            />
          )}
          onAddItem={handleAddItem}
          onPrimaryAction={navigateToPatient}
          onUpdateList={() => fetchNextPage()}
        />
      </div>
      <DeleteValidationModal
        display={!!selectedId}
        title={`Voulez vous vraiment supprimer ce patient ?`}
        testId="modal-delete-patient"
        onClose={() => setSelectedId(undefined)}
        onSubmit={handleValidateDeletePatient}
      />
      <OwnerModal
        display={!!changingOwnerPatient}
        label={
          changingOwnerPatient
            ? `Changement de référent du dossier "${getDisplayedFirstname(
                changingOwnerPatient,
              )} ${getDisplayedLastname(changingOwnerPatient)}"`
            : 'Changement de référent'
        }
        sharingConfigKey={SharingConfigKeys.patient}
        teams={userTeams}
        currentUser={currentUser}
        currentOwner={changingOwnerPatient?.owner ?? null}
        onClose={() => setChangingOwnerPatient(undefined)}
        onValid={(userId, includeSubResources) =>
          changingOwnerPatient &&
          changePatientOwner({
            patientId: changingOwnerPatient.id,
            newOwnerId: userId,
            includeSubResources: includeSubResources,
          })
        }
        showSubResourcesSwitch
      />
      <VitalCardBeneficiariesModal
        beneficiaries={beneficiaries}
        setVitalCardPatient={setVitalCardPatient}
        setBeneficiariesListVitalCard={setBeneficiariesListVitalCard}
        display={beneficiaries.length > 1}
        openPatientWithVitalCard={openPatientWithVitalCard}
      />
      <VitalCardDifferencesModal />
    </>
  )
}
