import React, { FunctionComponent, ReactNode, useCallback } from 'react'
import { QuestionContext } from '../../../components/questionnaire'

import { Question } from '../../../model/Questionnaire'

import ImportantQuestionAction from '../ImportantQuestionAction'
import { useGetAllImportantInformations } from '../../../hooks/queries/importantInformations'

export const WithQuestionContext: FunctionComponent<{
  singleColumnDisplay: boolean
  children?: ReactNode
  scrollToAnswerId?: number
  readOnly?: boolean
}> = ({ singleColumnDisplay, scrollToAnswerId, children, readOnly = false }) => {
  const { importantInformationList } = useGetAllImportantInformations()

  const renderImportantAction = useCallback(
    (question: Question) => {
      const isImportantQuestion = importantInformationList.some(({ id }) => id === question.id)

      return <ImportantQuestionAction question={question} isImportant={isImportantQuestion} />
    },
    [importantInformationList],
  )

  return (
    <QuestionContext.Provider
      value={{
        renderImportantAction: !readOnly ? renderImportantAction : undefined,
        singleColumnDisplay,
        scrollToAnswerId,
      }}
    >
      {children}
    </QuestionContext.Provider>
  )
}
