import getApi from '../../api/apisauceConfig'
import {
  deserializeQuestionnaireResponse,
  serializeQuestionnaire,
} from './mapper/questionnaires.mapper'
import { QuestionnaireMutationPayload, QuestionnaireResource } from './mapper/questionnaires.model'

export const updateQuestionnaire = (id: number, payload: QuestionnaireMutationPayload) =>
  getApi()
    .put<QuestionnaireResource>(`/questionnaires/${id}`, serializeQuestionnaire(payload))
    .then(deserializeQuestionnaireResponse)
