import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'

import { TaskPageHeader, TaskPageContent } from '../../components/task'
import { Task, TaskFilters, TaskMutationPayload, TaskStatus } from '../../model/Task'
import { isDefined } from '../../misc/functions.utilities'
import { DeleteValidationModal } from '../../components/shared'
import { formatISO } from 'date-fns'
import { TaskPageFiltersState } from '../../components/task/TaskPageContent/TaskPageContent.model'
import { NavBar } from '../../components/layout'
import {
  useCreateTask,
  useDeleteAllDoneTasks,
  useDeleteTask,
  useGetTaskCount,
  useGetTasks,
  useReadTask,
  useUpdateTask,
} from '../../hooks/queries/task'

export const TaskPage: FunctionComponent = () => {
  const [newTask, setNewTask] = useState<Partial<Task>>()
  const [toBeDeletedTaskId, setToBeDeletedTaskId] = useState<number>()
  const [deleteAllDoneTasksModal, setDeleteAllDoneTasksModal] = useState<boolean>(false)
  const [filters, setFilters] = useState<TaskFilters>({})

  const { mutate: createTask } = useCreateTask()
  const { mutate: updateTask } = useUpdateTask()
  const { mutate: deleteTask } = useDeleteTask()
  const { mutate: deleteAllDoneTasks } = useDeleteAllDoneTasks()
  const { mutate: readTasks } = useReadTask()
  const { data: totalNotificationTaskUnread } = useGetTaskCount(
    { read: false },
    { infinityStale: true },
  )
  const { taskList, isLoading: isListLoading } = useGetTasks({
    filters: filters,
  })

  const tasksTodo = useMemo(
    () => taskList.filter((taskTodo) => taskTodo.status === TaskStatus.TODO),
    [taskList],
  )

  const tasksDone = useMemo(
    () => taskList.filter((taskDone) => taskDone.status === TaskStatus.DONE),
    [taskList],
  )

  const handleNewTask = useCallback(() => {
    setNewTask({ title: '', status: TaskStatus.TODO, createdAt: new Date(), editMode: true })
  }, [])

  const emptyNewTask = useCallback(() => {
    setNewTask(undefined)
  }, [])

  const handleCreateOrUpdateTask = useCallback(
    (task: TaskMutationPayload) => {
      if (task.id) {
        updateTask({ taskId: task.id, variables: { ...task, autoCreated: false } })
      } else if (task.title) {
        createTask(task)
        emptyNewTask()
      } else {
        emptyNewTask()
      }
    },
    [updateTask, createTask, emptyNewTask],
  )

  const handleConfirmDeleteTask = useCallback(() => {
    if (isDefined(toBeDeletedTaskId)) {
      deleteTask({ taskId: toBeDeletedTaskId })
    } else {
      emptyNewTask()
    }
    setToBeDeletedTaskId(undefined)
  }, [deleteTask, emptyNewTask, toBeDeletedTaskId])

  const handleConfirmDeleteAllDoneTasks = useCallback(() => {
    deleteAllDoneTasks()
    setDeleteAllDoneTasksModal(false)
  }, [deleteAllDoneTasks])

  const handleGetTasks = useCallback(
    ({
      types,
      patient,
      startDate,
      endDate,
      hideAutoCreated,
      ...filters
    }: TaskPageFiltersState & { hideAutoCreated: boolean }) => {
      setFilters({
        ...filters,
        startDate: startDate ? formatISO(startDate) : undefined,
        endDate: endDate ? formatISO(endDate) : undefined,
        type: types.map(({ value }) => value).join() || undefined,
        patientId: patient?.value.id ?? undefined,
        autoCreated: hideAutoCreated ? false : undefined,
      })
    },
    [setFilters],
  )

  useEffect(() => {
    if (totalNotificationTaskUnread) {
      readTasks()
    }
  }, [readTasks, totalNotificationTaskUnread])

  const todoWithNewTask: Partial<Task>[] = useMemo(
    () => (newTask ? [newTask, ...tasksTodo] : tasksTodo),
    [newTask, tasksTodo],
  )

  return (
    <>
      <NavBar>
        <TaskPageHeader
          itemsCount={taskList.length}
          newTask={!newTask ? handleNewTask : undefined}
          testId="task-page-header"
        />
      </NavBar>
      <TaskPageContent
        tasksDone={tasksDone}
        onDeleteTask={setToBeDeletedTaskId}
        tasksTodo={todoWithNewTask}
        onCreateOrUpdate={handleCreateOrUpdateTask}
        newTask={!newTask ? handleNewTask : undefined}
        loading={isListLoading}
        removeNewTask={emptyNewTask}
        onDeleteAllDoneTasks={() => setDeleteAllDoneTasksModal(true)}
        getTasks={handleGetTasks}
        testId="task-page-content"
      />
      <DeleteValidationModal
        display={!!toBeDeletedTaskId}
        title="Voulez-vous vraiment supprimer cette tâche ?"
        onClose={() => setToBeDeletedTaskId(undefined)}
        onSubmit={handleConfirmDeleteTask}
        testId="task-page-delete-one-modal"
      />
      <DeleteValidationModal
        display={deleteAllDoneTasksModal}
        title="Voulez-vous vraiment effacer vos tâches terminées ?"
        onClose={() => setDeleteAllDoneTasksModal(false)}
        onSubmit={handleConfirmDeleteAllDoneTasks}
        testId="task-page-delete-all-done-modal"
      />
    </>
  )
}
