import React, { FunctionComponent, useMemo } from 'react'
import { parseISO } from 'date-fns'

import { formatFr, getAge, getAgeWithUnit } from '../../../../misc/date.utilities'
import { Icon, Tag, TagCollection, TooltipWrapper } from '../../../shared'

import { PatientDrugFormated, PatientListItemProps } from './PatientListItem.model'

import styles from './PatientListItem.module.scss'
import { SEX_LABELS, Sex, MIN_MAJOR_AGE } from '../../../../model/Patient'
import { getCssVariable } from '../../../../design-system/utils'
import { getFullName } from '../../../../misc/user.utilities'
import RoleRestriction from '../../../../containers/RoleRestriction'
import { ADMIN_ROLES } from '../../../../model/Roles'
import { getPriorityNames } from '../../../../misc/patient.utilities'
import classNames from 'classnames'
import { TagBackgroundColors } from '../../../../colors'

export const PatientListItem: FunctionComponent<PatientListItemProps> = ({
  patient,
  currentUser,
}) => {
  const {
    primaryFirstName,
    primaryLastName,
    secondaryFirstName,
    secondaryLastName,
  } = getPriorityNames(patient)

  const patientDrugsFormated: Array<PatientDrugFormated> = useMemo(() => {
    if (patient.drugs.length < 3) {
      return patient.drugs.map(({ name }) => ({ title: name, label: name }))
    } else {
      return [
        {
          title: `${patient.drugs.length} MÉDICAMENTS`,
          label: (
            <>
              {patient.drugs.map(({ name, signature }) => (
                <span key={signature}>{name}</span>
              ))}
            </>
          ),
        },
      ]
    }
  }, [patient.drugs])

  return (
    <div className={styles.item}>
      <div className={styles.visibility}>
        <div title={SEX_LABELS[patient.sex]}>
          {patient.sex !== Sex.UNKNOWN && (
            <Icon
              icon={patient.sex === Sex.MALE ? 'male' : 'female'}
              color={getCssVariable('shades', 'shade2')}
              size="normal"
            />
          )}
        </div>
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.titleRow}>
          <span
            className={classNames(styles.title, { [styles.titleDeceased]: patient.isDeceased })}
          >
            {primaryFirstName} <strong>{primaryLastName}</strong>
            {secondaryFirstName &&
              secondaryLastName &&
              ` (né.e ${secondaryFirstName} ${secondaryLastName})`}
          </span>
          <div
            className={classNames(styles.age, { [styles.titleThrough]: patient.isDeceased })}
            title={formatFr(parseISO(patient.birthDate), 'dd MMMM yyyy')}
          >
            <span className={styles.ageLabel}>{getAgeWithUnit(patient.birthDate)} </span>
            <span className={styles.birthdateLabel}>
              ({formatFr(parseISO(patient.birthDate), 'd MMMM yyyy')})
            </span>
          </div>
        </div>
        <div className={styles.deceasedAndMinorLabelContainer}>
          {getAge(patient.birthDate) < MIN_MAJOR_AGE && (
            <Tag color={TagBackgroundColors.dsTag03}>Mineur·e</Tag>
          )}
          {patient.isDeceased && <Tag color={TagBackgroundColors.dsTag01}>Décédé·e</Tag>}
        </div>
        {patient.owner.id !== currentUser?.id && (
          <div className={styles.ownerName}>{getFullName(patient.owner, true)}</div>
        )}
      </div>
      {patient.drugs.length > 0 && (
        <div className="space-x-2 flex mr-4 w-2/5">
          {patientDrugsFormated.map((drug, index) => {
            return (
              <TooltipWrapper
                key={index}
                content={<div className="flex flex-col">{drug.label}</div>}
                pointerDirection="top"
              >
                <Tag ellipsis={false}>
                  <div className="flex items-center w-full h-auto">
                    <div className="mr-1">
                      <Icon icon="pills" size="nano" />
                    </div>
                    <span className="text-ellipsis overflow-hidden whitespace-nowrap h-full">
                      {drug.title}
                    </span>
                  </div>
                </Tag>
              </TooltipWrapper>
            )
          })}
        </div>
      )}
      <div className={classNames(styles.tags, 'space-x-2')}>
        <TagCollection ids={patient.tagIds} />
      </div>
      <RoleRestriction roles={ADMIN_ROLES}>
        <div className={styles.identifier} title="Identifiant technique du patient">
          {patient.id}
        </div>
      </RoleRestriction>
    </div>
  )
}
