import { VitalCardState, VitalCardActionTypes } from './vitalCard.model'
import { vitalCardActions } from './vitalCard.actions'
import { createReducer } from 'redux-starter-kit'

const VITAL_CARD_INITIAL_STATE: VitalCardState = {
  vitalCardPatient: null,
  beneficiaries: [],
  vitalCardPatientDifferences: null,
  cpsPincode: null,
  cpsPincodeModalOpen: false,
}

const ACTION_HANDLERS = {
  [VitalCardActionTypes.SET_VITAL_CARD_PATIENT]: (
    state: VitalCardState = VITAL_CARD_INITIAL_STATE,
    { vitalCardPatient }: ReturnType<typeof vitalCardActions.setVitalCardPatient>,
  ): VitalCardState => ({
    ...state,
    vitalCardPatient,
  }),
  [VitalCardActionTypes.SET_BENEFICIARIES_LIST_VITAL_CARD]: (
    state: VitalCardState = VITAL_CARD_INITIAL_STATE,
    { beneficiaries }: ReturnType<typeof vitalCardActions.setBeneficiariesListVitalCard>,
  ): VitalCardState => ({
    ...state,
    beneficiaries,
  }),
  [VitalCardActionTypes.SET_VITAL_CARD_DIFFERENCES]: (
    state: VitalCardState = VITAL_CARD_INITIAL_STATE,
    { differences }: ReturnType<typeof vitalCardActions.setVitalCardPatientDifferences>,
  ): VitalCardState => ({
    ...state,
    vitalCardPatientDifferences: differences,
  }),
  [VitalCardActionTypes.SET_CPS_PINCODE]: (
    state = VITAL_CARD_INITIAL_STATE,
    { pincode }: ReturnType<typeof vitalCardActions.setCpsPincode>,
  ): VitalCardState => ({
    ...state,
    cpsPincode: pincode,
  }),
  [VitalCardActionTypes.CPS_PINCODE_MODAL_OPEN]: (
    state = VITAL_CARD_INITIAL_STATE,
    { open }: ReturnType<typeof vitalCardActions.setCpsPincodeModalOpen>,
  ): VitalCardState => ({
    ...state,
    cpsPincodeModalOpen: open,
  }),
}

export const vitalCardReducer = createReducer<VitalCardState, any>(
  VITAL_CARD_INITIAL_STATE,
  ACTION_HANDLERS,
)
