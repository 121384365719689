import getApi from '../../../../api'
import { Speciality } from '../../../../model/Professions'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

const baseUrl = '/specialities'

export const restuxSpecialitysApiConfig: RestuxApiConfig<Speciality, Speciality> = {
  url: baseUrl,
  getItem: (route) => getApi().get<Speciality>(route),
}

export const getSpecialityList = () => getApi().get<Speciality[]>(baseUrl)
