import { connect } from 'react-redux'

import { RootState } from '../../../../store'
import { PatientDetailsPage } from './PatientDetailsPage.component'
import { PatientDetailsStoreProps, PatientDetailsDispatchProps } from './PatientDetailsPage.model'
import {
  currentBeneficiariesListVitalCard,
  currentVitalCardPatient,
  vitalCardServiceSelector,
} from '../../../../store/domain/vitalCard/vitalCard.selector'
import { vitalCardActions } from '../../../../store/domain/vitalCard'
import { bottomPanelResourceHistoryActions } from '../../../../store/ui/bottomPanelResourceHistory'

const mapStateToProps = (state: RootState): PatientDetailsStoreProps => ({
  vitalCardPatient: currentVitalCardPatient(state),
  vitalCardStatus: vitalCardServiceSelector(state),
  beneficiaries: currentBeneficiariesListVitalCard(state),
})
const mapDispatchToProps: PatientDetailsDispatchProps = {
  setVitalCardPatient: vitalCardActions.setVitalCardPatient,
  openBottomPanelResourceHistory: bottomPanelResourceHistoryActions.open,
  setBeneficiariesListVitalCard: vitalCardActions.setBeneficiariesListVitalCard,
  openPatientWithVitalCard: vitalCardActions.openPatientWithVitalCard,
}
const withConnect = connect(mapStateToProps, mapDispatchToProps)(PatientDetailsPage)
export { withConnect as PatientDetailsPage }
