import { Filters } from '../../../model/Filters'

const all = ['patients']
const lists = [...all, 'list']
const list = (filters?: Filters) => [...lists, filters]
const detail = (id: number) => [...all, id]
const create = [...all, 'create']
const update = [...all, 'update']
const deleteItem = [...all, 'deleteItem']

export const patientsKeys = {
  all,
  lists,
  list,
  detail,
  create,
  update,
  deleteItem,
}
