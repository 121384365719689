import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QuestionnaireMutationPayload } from '../../../data/questionnaires/mapper/questionnaires.model'
import { Questionnaire } from '../../../model/Questionnaire'
import { updateQuestionnaire } from '../../../data/questionnaires'
import { UserFriendlyError } from '../../../model/Error'
import { questionnairesKeys } from './questionnaires.keys'

interface QuestionnaireMutateVariable {
  id: number
  payload: QuestionnaireMutationPayload
}

export const useUpdateQuestionnaire = () => {
  const queryClient = useQueryClient()

  return useMutation<Questionnaire, Error, QuestionnaireMutateVariable>(
    async ({ id, payload }) => {
      const { data, ok } = await updateQuestionnaire(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour du questionnaire a échoué')
      }
      return data
    },
    {
      onSuccess: (questionnaire) => {
        queryClient.invalidateQueries(questionnairesKeys.lists)
        queryClient.setQueryData(questionnairesKeys.detail(questionnaire.id), questionnaire)
      },
    },
  )
}
