import getApi from '../../api'
import {
  deserializeQuestionnaireResponse,
  serializeQuestionnaire,
} from './mapper/questionnaires.mapper'
import { QuestionnaireMutationPayload, QuestionnaireResource } from './mapper/questionnaires.model'

export const createQuestionnaire = (questionnaire: QuestionnaireMutationPayload) =>
  getApi()
    .post<QuestionnaireResource>('/questionnaires', serializeQuestionnaire(questionnaire))
    .then(deserializeQuestionnaireResponse)
