import { call, put, race, select, take, takeEvery } from 'redux-saga/effects'

import { bottomPanelContactsActions } from './bottomPanelContacts.actions'
import {
  BottomPanelContactsContext,
  UiBottomPanelContactsActionTypes,
} from './bottomPanelContacts.model'
import { hideBottomPanel, setBottomPanelOptions } from '../bottomPanel/bottomPanel.actions'
import { BottomPanelActionTypes, BottomPanelComponentType } from '../bottomPanel/bottomPanel.model'
import { inUseContactSelector } from './bottomPanelContacts.selectors'
import { contactsActions } from '../../cache/contacts'
import { inUseMedicalEventDocumentSelector } from '../medicalEvents/medicalEventDocumentInstances'
import { documentInstancesActions } from '../../cache/documentInstances'
import { mapContactToRecipientContact } from '../../../misc/contact.utilities'
import { Contact } from '../../../model/Contact'
import { addError } from '../../message'
import { ApiErrorResponse } from 'apisauce'

function* createContactFromDifferentContext(context: BottomPanelContactsContext, contact: Contact) {
  switch (context) {
    case BottomPanelContactsContext.PATIENT_CONTACT:
      // Ajout du patient créé dans la liste des patients
      yield put(bottomPanelContactsActions.contactSuccessfullyUpdated(contact))
      break
    case BottomPanelContactsContext.DOCUMENT_CONTACT:
      // Ajout du contact au document en cours d'édiion
      const inUseDocumentInstance: ReturnType<typeof inUseMedicalEventDocumentSelector> =
        yield select(inUseMedicalEventDocumentSelector)
      // Gestion de l'unicité dans les contacts
      if (
        inUseDocumentInstance &&
        inUseDocumentInstance.type === 'farte' &&
        !inUseDocumentInstance.contacts.find(({ id }) => contact.id === id)
      ) {
        const contacts = [...inUseDocumentInstance.contacts, mapContactToRecipientContact(contact)]
        yield put(
          documentInstancesActions.actions.apiUpdateItem(inUseDocumentInstance.id, {
            contacts,
            type: inUseDocumentInstance.type,
          }),
        )
      }
      break
  }
  yield put(hideBottomPanel())
}

function* openCreateContactWorker({
  context,
  onCreate,
}: ReturnType<typeof bottomPanelContactsActions.openCreateContactForm>) {
  yield put(
    setBottomPanelOptions({
      componentType: BottomPanelComponentType.EditContact,
      open: true,
      title: `Création d'un correspondant`,
    }),
  )
  const {
    succeed,
    failed,
  }: {
    succeed: ReturnType<typeof contactsActions.actions.storeSetItemDetails>
    failed: ReturnType<typeof contactsActions.actions.dispatchError>
  } = yield race({
    succeed: take(contactsActions.types.STORE_SET_ITEM_DETAILS),
    failed: take(contactsActions.types.DISPATCH_ERROR),
    cancel: take(BottomPanelActionTypes.BOTTOM_PANEL_HIDE),
  })

  // Close bottom panel
  yield put(hideBottomPanel())

  if (succeed) {
    yield createContactFromDifferentContext(context, succeed.item)
    if (onCreate) {
      yield call(onCreate, succeed.item)
    }
  } else if (failed) {
    const error = failed.response as ApiErrorResponse<{ detail: string }>
    yield put(
      addError(
        'Création du contact impossible',
        `${error.data?.detail ?? 'Une erreur inattendue est survenue'}`,
      ),
    )
    return
  }
}

function* openCreateContactFormWatcher() {
  yield takeEvery(
    UiBottomPanelContactsActionTypes.OPEN_CREATE_CONTACT_FORM,
    openCreateContactWorker,
  )
}

function* openEditContactWorker({
  contactId,
  readOnly,
  context,
  onEdit,
}: ReturnType<typeof bottomPanelContactsActions.openEditContactForm>) {
  yield put(bottomPanelContactsActions.useId(contactId))
  const contact: ReturnType<typeof inUseContactSelector> = yield select(inUseContactSelector)
  const title = contact
    ? `${contact.title} ${contact.firstName} ${contact.familyName} (${
        contact.private ? 'Privé' : 'Public'
      })`
    : `Édition d'un correspondant`
  if (context) {
    yield put(bottomPanelContactsActions.setContext(context))
  }
  yield put(bottomPanelContactsActions.setReadOnly(readOnly))
  yield put(
    setBottomPanelOptions({
      componentType: BottomPanelComponentType.EditContact,
      open: true,
      title,
    }),
  )

  if (onEdit) {
    const {
      succeed,
    }: {
      succeed: ReturnType<typeof bottomPanelContactsActions.contactSuccessfullyUpdated>
    } = yield race({
      succeed: take(UiBottomPanelContactsActionTypes.CONTACT_SUCCESFULLY_UPDATED),
      cancel: take(BottomPanelActionTypes.BOTTOM_PANEL_HIDE),
    })

    if (succeed) {
      yield call(onEdit, succeed.contact, readOnly ? contactId : undefined)
    }
  }
}

function* openEditContactFormWatcher() {
  yield takeEvery(UiBottomPanelContactsActionTypes.OPEN_EDIT_CONTACT_FORM, openEditContactWorker)
}

function* switchToPrivateCopyModeWorker() {
  const contact: ReturnType<typeof inUseContactSelector> = yield select(inUseContactSelector)
  yield put(
    setBottomPanelOptions({
      componentType: BottomPanelComponentType.EditContact,
      open: true,
      title: `${contact?.title} ${contact?.firstName} ${contact?.familyName} (Privé)`,
    }),
  )
}

function* switchToPrivateCopyWatcher() {
  yield takeEvery(
    UiBottomPanelContactsActionTypes.SWITCH_PRIVATE_COPY_MODE,
    switchToPrivateCopyModeWorker,
  )
}

function* createPrivateContactWorker({
  contact,
  context,
  privateCopyofPublicContact,
}: ReturnType<typeof bottomPanelContactsActions.createPrivateCopy>) {
  yield put(contactsActions.actions.apiCreateItem(contact))

  if (privateCopyofPublicContact) {
    const {
      succeed,
      failed,
    }: {
      succeed: ReturnType<typeof contactsActions.actions.storeSetItemDetails>
      failed: ReturnType<typeof contactsActions.actions.dispatchError>
    } = yield race({
      succeed: take(contactsActions.types.STORE_SET_ITEM_DETAILS),
      failed: take(contactsActions.types.DISPATCH_ERROR),
      cancel: take(BottomPanelActionTypes.BOTTOM_PANEL_HIDE),
    })

    if (succeed) {
      const contact = succeed.item
      yield createContactFromDifferentContext(context, contact)
    } else if (failed) {
      const error = failed.response as ApiErrorResponse<{ detail: string }>
      yield put(
        addError(
          'Création du contact impossible',
          `${error.data?.detail ?? 'Une erreur inattendue est survenue'}`,
        ),
      )
    }
  }
}

function* createPrivateContactWatcher() {
  yield takeEvery(UiBottomPanelContactsActionTypes.CREATE_PRIVATE_COPY, createPrivateContactWorker)
}

export const bottomPanelContactsSagas = {
  openCreateContactFormWatcher,
  openEditContactFormWatcher,
  switchToPrivateCopyWatcher,
  createPrivateContactWatcher,
}
