import React, { FunctionComponent, MouseEvent, useState, useEffect, useMemo } from 'react'
import { Answer } from '../../../../model/Questionnaire'
import { QuestionWrapper } from '../../atoms/QuestionWrapper'
import { QuestionTitle } from '../../atoms/QuestionTitle/QuestionTitle.component'
import { SelectableAnswer } from '../../atoms/SelectableAnswer'
import {
  QuestionComponentProps,
  isAutocompletable,
  getAutocompleteValues,
  DeleteQuestionCallback,
  SetAnswerValueCallback,
  EditQuestionCallback,
  ShowSubQuestionsCallback,
  hasAtLeastOneImage,
  AddSubQuestionCallback,
} from '../../Questionnaire.model'
import { SubQuestions } from '../../SubQuestions'
import { isNumberGuard } from '../../../../misc/types.utilities'
import { MultiSelectInput } from '../../../shared'
import { MultiSelectOption } from '../../../../model/SelectOption'
import { isDefined } from '../../../../misc/functions.utilities'

interface QcmProps extends QuestionComponentProps {
  onDeleteSubQuestion?: DeleteQuestionCallback
  onDelete?: DeleteQuestionCallback
  onEdit?: EditQuestionCallback
  onAddSubQuestion?: AddSubQuestionCallback
  answerValueIds?: number[] | null
  selectAnswers?: (answerIds: number[]) => void
  setSubQuestionsAnswerValues?: SetAnswerValueCallback
  subQuestionsShown: boolean
  onShowSubQuestions?: ShowSubQuestionsCallback
}

interface OptimistUpdate {
  id: number
  state: 'add' | 'remove'
}

export const Qcm: FunctionComponent<QcmProps> = ({
  mode,
  question,
  onDeleteSubQuestion,
  onDelete,
  onEdit,
  onAddSubQuestion,
  answerValueIds,
  disabled,
  isReadonly,
  selectAnswers,
  subQuestionsShown,
  onShowSubQuestions,
  setSubQuestionsAnswerValues,
}) => {
  const switchToSelect = isAutocompletable(question.answers)
  const withImage = useMemo(() => hasAtLeastOneImage(question.answers), [question.answers])
  const [optimistUpdates, setOptimistUpdates] = useState<OptimistUpdate[]>([])

  const options = useMemo(
    () =>
      question.answers.map((answer) => ({
        value: answer.id,
        label: answer.title,
      })),
    [question.answers],
  )

  const value = useMemo(
    () => getAutocompleteValues(isDefined(answerValueIds) ? answerValueIds : [], question.answers),
    [answerValueIds, question.answers],
  )

  useEffect(() => {
    if (answerValueIds) {
      setOptimistUpdates((previousOptimistUpdates) =>
        previousOptimistUpdates // Clean des élements ajoutés
          .filter(({ id, state }) => !(state === 'add' && answerValueIds.includes(id)))
          // Clean des élements supprimés
          .filter(({ id, state }) => !(state === 'remove' && !answerValueIds.includes(id))),
      )

      // Repli des sous-questions en fonction des réponses sélectionnées
      if (subQuestionsShown && onShowSubQuestions) {
        if (answerValueIds.length < 1) {
          onShowSubQuestions(false)
        } else {
          // Si une seule des réponses sélectionnées a des sous-questions, on les affiche.
          // Sinon on cache les sous-questions
          let showsubquest = false
          answerValueIds.forEach((ansID) =>
            question.answers.forEach((ans) => {
              if (ans.id === ansID && ans.childQuestions && ans.childQuestions.length > 0) {
                showsubquest = true
              }
            }),
          )
          onShowSubQuestions(showsubquest)
        }
      }
    }
  }, [answerValueIds, onShowSubQuestions, question.answers, subQuestionsShown])

  const handleOnClick = ({ id }: Answer, activated: boolean) => (
    event: MouseEvent<HTMLDivElement>,
  ) => {
    event.preventDefault()

    if (answerValueIds === undefined) {
      return
    }

    if (selectAnswers) {
      if (activated) {
        selectAnswers(
          answerValueIds ? answerValueIds.filter((answerValueId) => answerValueId !== id) : [],
        )
      } else {
        selectAnswers([...(answerValueIds ? [...answerValueIds] : []), id])
      }
    }

    setOptimistUpdates((updates) => [...updates, { id, state: activated ? 'remove' : 'add' }])
  }

  const onSelect = (values: MultiSelectOption<number>[]) => {
    const newSelection = !!values ? values.map(({ value }) => value).filter(isNumberGuard) : []
    selectAnswers && selectAnswers(newSelection)
  }

  const subQuestionsNode = (
    <SubQuestions
      mode={mode}
      disabled={disabled}
      isReadonly={isReadonly}
      answerValuesIds={answerValueIds}
      question={question}
      onEdit={onEdit}
      onAddSubQuestion={onAddSubQuestion}
      onDelete={onDeleteSubQuestion}
      subQuestionsShown={subQuestionsShown}
      onShowSubQuestions={onShowSubQuestions}
      setAnswerValue={setSubQuestionsAnswerValues}
    />
  )

  return (
    <QuestionWrapper
      mode={mode}
      question={question}
      filled={isDefined(answerValueIds) && answerValueIds.length > 0}
      title={<QuestionTitle question={question} />}
      onDelete={isReadonly ? undefined : onDelete}
      onEdit={isReadonly ? undefined : onEdit}
      subQuestions={subQuestionsNode}
    >
      {!switchToSelect &&
        question.answers.map((answer, index) => {
          const activated = isDefined(answerValueIds) && answerValueIds.includes(answer.id)
          const optimist = optimistUpdates.find(({ id }) => id === answer.id) !== undefined
          return (
            <SelectableAnswer
              key={answer.id}
              withImage={withImage}
              answer={answer}
              activated={activated}
              disabled={disabled}
              onClick={handleOnClick(answer, activated)}
              optimist={optimist}
              warning={question.sharedInMedicalEvent}
            />
          )
        })}
      {switchToSelect && (
        <MultiSelectInput
          disabled={disabled}
          mode="multiline"
          title="Liste des réponses"
          value={value}
          options={options}
          onSelect={onSelect}
          testId="multi_select-answer"
        />
      )}
    </QuestionWrapper>
  )
}
