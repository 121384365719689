export const SECURITE_SOCIAL_NUMBER_REGEX_WITHOUT_SPACE =
  /^([1-4|7|8]{1})([0-9]{2})([0-9]{2})([a-zA-Z0-9]{2})([0-9]{3})([0-9]{3})([0-9]{2})$/

export function inputInseeNumberFormatter(input: string): string {
  const matchResults = input
    .replace(/\s/g, '')
    .slice(0, 15)
    .match(SECURITE_SOCIAL_NUMBER_REGEX_WITHOUT_SPACE)

  if (matchResults) {
    const [, ...groups] = matchResults
    return groups.join(' ')
  }

  return input
}

export function inseeNumberValidator(input: string) {
  if (typeof input !== 'string') {
    return false
  }
  if (input === '') {
    return true
  }
  let withoutSpaceInput = input.replace(/\s/g, '')
  if (withoutSpaceInput.match(SECURITE_SOCIAL_NUMBER_REGEX_WITHOUT_SPACE) === null) {
    return false
  }
  // Gestion des numéros corses (2a => 19, 2b => 18)
  withoutSpaceInput = withoutSpaceInput.replace(/2a/i, '19').replace(/2b/i, '18')

  // Validation de la clé : 97 - ( (Valeur numérique du NIR) modulo 97 )
  const value = withoutSpaceInput.slice(0, 13)
  const check = parseInt(withoutSpaceInput.slice(13), 10)
  const calculatedCheck = 97 - (parseInt(value, 10) % 97)
  return check === calculatedCheck
}
