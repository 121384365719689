import { takeEvery, call, put, select, take } from 'redux-saga/effects'
import { VitalCardActionTypes } from './vitalCard.model'
import { Patient } from '../../../model/Patient'
import { ApiResponse } from 'apisauce'
import { customHistory } from '../../../history'
import { vitalCardActions } from './vitalCard.actions'
import { computePatientDifferenceToVitalCard } from '../../../misc/vitalCard.utilities'
import { enabledFeaturesPreferencesSelector } from '../me'
import { Features } from '../../../model/Preference'
import { readVitalCardInfosFromStellair } from './stellair'
import { readVitalCardInfosFromSephira } from './sephira'
import { getPatientByVitalCardInfos } from './api'
import { updatePatient } from '../../../data/patients'
import { queryClient } from '../../../App'
import { patientsKeys } from '../../../hooks/queries/patients/patients.keys'

function* readVitalCardInfosWorker({
  readToUpdate,
}: ReturnType<typeof vitalCardActions.readVitalCardInfos>) {
  const features: Features | null = yield select(enabledFeaturesPreferencesSelector)

  let beneficiaries: Array<Partial<Patient>> | null = null
  if (features?.vitalCardService === 'stellair') {
    beneficiaries = yield readVitalCardInfosFromStellair()
  }
  if (features?.vitalCardService === 'sephira') {
    beneficiaries = yield readVitalCardInfosFromSephira()
  }

  if (!beneficiaries || beneficiaries.length === 0) return

  if (beneficiaries.length > 1) {
    yield put(vitalCardActions.setBeneficiariesListVitalCard(beneficiaries))
  } else {
    yield put(vitalCardActions.setVitalCardPatient(beneficiaries[0], readToUpdate))
  }
}

function* readVitalCardInfosWatcher() {
  yield takeEvery(VitalCardActionTypes.READ_VITAL_CARD_INFOS, readVitalCardInfosWorker)
}

function* setVitalCardPatientWorker({
  vitalCardPatient,
  update,
}: ReturnType<typeof vitalCardActions.setVitalCardPatient>) {
  if (!vitalCardPatient) return

  const { ok, data: patient }: ApiResponse<Patient> = yield call(
    getPatientByVitalCardInfos,
    vitalCardPatient,
  )

  if (ok && patient) {
    // Patient connu de Follow, calcul des differences entre le dossier et les infos de la CV
    const differences = computePatientDifferenceToVitalCard(patient, vitalCardPatient)
    if (differences) {
      // Différences trouvées, on ouvre la modale de proposition de mise à jour et attend une réponse
      yield put(vitalCardActions.setVitalCardPatientDifferences(differences))
      const { confirm }: ReturnType<typeof vitalCardActions.applyVitalCardDifferences> = yield take(
        VitalCardActionTypes.APPLY_VITAL_CARD_DIFFERENCES,
      )
      if (confirm) {
        const { data: updatedPatient, ok }: ApiResponse<Patient> = yield call(
          updatePatient,
          patient.id,
          {
            ...differences,
          },
        )

        if (ok && updatedPatient) {
          // Invalidation du cache de liste et détail patient RQ
          queryClient.invalidateQueries(patientsKeys.lists)
          queryClient.invalidateQueries(patientsKeys.detail(updatedPatient.id))
        }
      }
    }
    if (!update) {
      yield put(vitalCardActions.openPatientWithVitalCard(patient.id))
    }
    // Clear des informations relative à la carte vitale
    yield put(vitalCardActions.setVitalCardPatient(null))
    yield put(vitalCardActions.setVitalCardPatientDifferences(null))
  } else if (!update) {
    // Pas de patient connu de Follow, redirection vers la création
    customHistory.navigate('/patients/new?vital=true')
  }
}

function* setVitalCardPatientWatcher() {
  yield takeEvery(VitalCardActionTypes.SET_VITAL_CARD_PATIENT, setVitalCardPatientWorker)
}

function openPatientWithVitalCardWorker({
  patientId,
}: ReturnType<typeof vitalCardActions.openPatientWithVitalCard>) {
  // Redirection vers le patient existant
  customHistory.navigate(`/patients/${patientId}`)
}

function* openPatientWithVitalCardWatcher() {
  yield takeEvery(VitalCardActionTypes.OPEN_PATIENT_PAGE, openPatientWithVitalCardWorker)
}

export const vitalCardSagas = {
  readVitalCardInfosWatcher,
  openPatientWithVitalCardWatcher,
  setVitalCardPatientWatcher,
}
