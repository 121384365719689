import { DocumentTemplateListItem } from './DocumentTemplate'
import { LightQuestionnaire, Question } from './Questionnaire'
import { TagType } from './Tag'

// --- State

export interface ConfigurableExportFilters {
  documentTemplates: DocumentTemplateListItem[]
  questionnaires: LightQuestionnaire[]
  questions: Question[]
  patientLabels: TagType[]
}

export interface ConfigurableExportColumns {
  documentTemplates: DocumentTemplateListItem[]
  questionnaires: LightQuestionnaire[]
  assignatedDoctor: boolean
  addressingDoctor: boolean
  patient: ExportPatientColumns[]
}

// -- Payload

export interface ExportPayloadQuestion {
  id: number
  value?: string | number | Date | number[] | null
}

export interface ExportPayloadQuestionnaire {
  id: number
  data: ExportQuestionnaireDataColumns[]
  questionIds?: number[]
}

export interface ExportPayloadDocument {
  id: number
  data: ExportDocumentDataColumns[]
  variableIds?: number[]
  quesionnaires?: ExportPayloadQuestionnaire[]
}

export interface ConfigurableExportPayload {
  filters: {
    patient?: {
      labels?: string[]
    }
    questionnaireIds?: number[]
    documentIds?: number[]
    questions?: ExportPayloadQuestion[]
    specificUserIds?: number[]
  }
  columns: {
    patient?: ExportPatientColumns[]
    assignedDoctor?: boolean
    addressingDoctor?: boolean
    documents?: ExportPayloadDocument[]
    questionnaires?: ExportPayloadQuestionnaire[]
  }
}

// -- Constantes

export enum ExportDocumentDataColumns {
  Title = 'title',
  Date = 'date',
}

export enum ExportQuestionnaireDataColumns {
  Title = 'title',
  Date = 'date',
  Score = 'score',
}

export enum ExportPatientColumns {
  Serial = 'serial',
  BirthFirstName = 'birthFirstName',
  BirthLastName = 'birthLastName',
  UsedFirstName = 'usedFirstName',
  UsedLastName = 'usedLastName',
  Sex = 'sex',
  BirthDate = 'birthDate',
  Email = 'email',
  Telephone1 = 'telephone1',
  Telephone2 = 'telephone2',
  InseeNumber = 'inseeNumber',
  HealthInsuranceNumber = 'healthInsuranceNumber',
  Profession = 'profession',
  Labels = 'labels',
  Owner = 'owner',
  Location = 'location',
}

export const EXPORT_PATIENT_COLUMNS_PARTIAL = [
  ExportPatientColumns.Serial,
  ExportPatientColumns.Sex,
  ExportPatientColumns.BirthDate,
  ExportPatientColumns.Labels,
  ExportPatientColumns.Owner,
]

export const EXPORT_PATIENT_COLUMNS_FULL = [
  ExportPatientColumns.Serial,
  ExportPatientColumns.BirthFirstName,
  ExportPatientColumns.BirthLastName,
  ExportPatientColumns.UsedFirstName,
  ExportPatientColumns.UsedLastName,
  ExportPatientColumns.Sex,
  ExportPatientColumns.BirthDate,
  ExportPatientColumns.Email,
  ExportPatientColumns.Telephone1,
  ExportPatientColumns.Telephone2,
  ExportPatientColumns.InseeNumber,
  ExportPatientColumns.HealthInsuranceNumber,
  ExportPatientColumns.Profession,
  ExportPatientColumns.Labels,
  ExportPatientColumns.Owner,
  ExportPatientColumns.Location,
]
