import { put, takeEvery } from 'redux-saga/effects'
import { BottomPanelComponentType, setBottomPanelOptions } from '../bottomPanel'
import { uiBottomPanelTreatmentsHistoryActionTypes } from './bottomPanelTreatmentsHistory.model'

function* openWorker() {
  yield put(
    setBottomPanelOptions({
      title: 'Historique médicamenteux',
      open: true,
      componentType: BottomPanelComponentType.TreatmentsHistory,
    }),
  )
}

function* openWatcher() {
  yield takeEvery(uiBottomPanelTreatmentsHistoryActionTypes.OPEN, openWorker)
}

export const bottomPanelTreatmentsHistorySagas = {
  openWatcher,
}
