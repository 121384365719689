import { ApiResponse } from 'apisauce'
import { QuestionnaireResource, QuestionnaireSerializedResource } from './questionnaires.model'
import { Questionnaire } from '../../../model/Questionnaire'
import { deserializeQuestionnaire } from '@follow/cdk'

export { deserializeQuestionnaire }

export function deserializeQuestionnaires(
  questionnaires: ReadonlyArray<QuestionnaireResource>,
): Questionnaire[] {
  return questionnaires.map((questionnaire) => deserializeQuestionnaire(questionnaire))
}

export function deserializeQuestionnaireResponse(
  response: ApiResponse<QuestionnaireResource>,
): ApiResponse<Questionnaire> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeQuestionnaire(data),
  }
}

export function serializeQuestionnaire(
  questionnaire: Partial<Questionnaire>,
): Partial<QuestionnaireSerializedResource> {
  return {
    ...questionnaire,
    questionIds: questionnaire.questions && questionnaire.questions.map(({ id }) => id),
  }
}
