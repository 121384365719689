import { RestuxResources } from '../../resources'
import { RestuxPagination, RestuxUiSagaConf } from '../../restux/ui/restuxUi.model'
import { uiActionsFactory } from '../../restux/ui/restuxUiActions.factory'
import { patientTreatmentsPaginationSelector } from './patientTreatments.selectors'

export const patientTreatmentsConfig: RestuxUiSagaConf = {
  resourceName: RestuxResources.patientTreatments,
  identifier: 'patientTreatments',
  paginationSelector: patientTreatmentsPaginationSelector,
}

export interface PatientTreatmentsState {
  pagination: RestuxPagination
}

export const uiActions = uiActionsFactory(patientTreatmentsConfig)

export const UiPatientTreatmentsActionTypes = {
  ...uiActions.types,
}
