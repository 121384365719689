import { deserializeAnswer } from '@follow/cdk'
import { ApiResponse } from 'apisauce'
import { Answer } from '../../../model/Questionnaire'
import { serializeQuestion } from '../../questions/mapper/questions.mapper'
import { QuestionResource } from '../../questions/mapper/questions.model'
import { AnswerResource, AnswerSerializedResource } from './answers.model'

export { deserializeAnswer }

export function deserializeAnswerResponse(
  response: ApiResponse<AnswerResource>,
): ApiResponse<Answer> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeAnswer(data),
  }
}

export function serializeAnswer(answer: Partial<Answer>): Partial<AnswerSerializedResource> {
  const { imageFile, ...values } = answer
  return {
    ...values,
    textOutput: answer.textOutput === null ? null : JSON.stringify(answer.textOutput), // valeur "null" sert à supprimer le texte géneré de l'API
    imageFileId: imageFile ? imageFile.id : imageFile,
    childQuestions: answer.childQuestions
      ? (answer.childQuestions.map(serializeQuestion) as unknown as QuestionResource[])
      : undefined,
  }
}
