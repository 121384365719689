import getApi from '../../../../api/apisauceConfig'
import { Patient } from '../../../../model/Patient'

export const getPatientByVitalCardInfos = ({
  sex,
  birthDate,
  birthLastName,
  birthFirstName,
  inseeNumber,
}: Partial<Patient>) =>
  getApi().get<Patient>(`/patients/find`, {
    sex,
    birthDate,
    birthLastName,
    birthFirstName,
    nir: inseeNumber,
  })
