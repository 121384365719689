import { Profession } from '../../../model/Professions'
import { professionsActionsType } from './professions.model'

export const professionsActions = {
  getListOfProfession: () => ({
    type: professionsActionsType.GET_LIST_OF_PROFESSION,
  }),

  storeListOfProfessions: (professionsList: Profession[]) => ({
    type: professionsActionsType.STORE_LIST_OF_PROFESSIONS,
    professionsList,
  }),
}
