import { PaginatedList } from '../../../../model/Pagination'
import { DocumentInstance, DocumentInstanceListItem } from '../../../../model/DocumentInstance'
import {
  deserializeDocumentInstancePaginatedResponse,
  deserializeDocumentInstanceResponse,
  serializeDocumentInstance,
} from './DocumentInstances.mapper'
import getApi from '../../../../api/apisauceConfig'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import {
  ApiDocumentInstanceResource,
  ApiDocumentInstanceBatchResponse,
  ApiDocumentInstanceCreationResource,
  ApiDocumentInstanceListItemResource,
} from './DocumentInstances.model'
import { ApiResponse } from 'apisauce'
import { IdentityIdentifier } from '../../../restux/restux.model'

export const restuxDocumentInstanceApiConfig: RestuxApiConfig<
  DocumentInstanceListItem,
  DocumentInstance
> = {
  url: '/documents',
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<ApiDocumentInstanceListItemResource>>(route)
      .then(deserializeDocumentInstancePaginatedResponse),
  getItem: (route) =>
    getApi().get<ApiDocumentInstanceResource>(route).then(deserializeDocumentInstanceResponse),
  deleteItem: (route) => getApi().delete(route),
  updateItem: (route, item) =>
    getApi()
      .put<ApiDocumentInstanceResource>(route, serializeDocumentInstance(item))
      .then(deserializeDocumentInstanceResponse),
}

export const getQuestionnaireComputedValue = (
  documentId: IdentityIdentifier,
  questionnaireId: number,
) =>
  getApi()
    .get<{ value: number }>(
      `/documents/${documentId}/questionnaire/${questionnaireId}/computed_value`,
    )
    .then((response): ApiResponse<number> => {
      const { data, ...responseRest } = response
      if (!response.ok || !data) {
        return { ...responseRest }
      }

      return {
        ...responseRest,
        data: data.value,
      }
    })

export const batchDocumentInstances = (
  route: string,
  documents: Array<ApiDocumentInstanceCreationResource>,
) => getApi().post<ApiDocumentInstanceBatchResponse>(route, { documents })
