import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { useMeasure } from 'react-use'
import { Question, QuestionType } from '../../../model/Questionnaire'
import { MoreButton } from '../../shared'
import { QuestionContext } from '../context'
import {
  AddSubQuestionCallback,
  DeleteQuestionCallback,
  EditQuestionCallback,
  QuestionnaireMode,
  SetAnswerValueCallback,
  ShowSubQuestionsCallback,
} from '../Questionnaire.model'
import { QuestionDetail } from './QuestionDetail'
import styles from './SubQuestions.module.scss'
import { isDefined } from '../../../misc/functions.utilities'

function displaySubQuestionLabel(showSubQuestions: boolean, subQuestionsTotal: number) {
  const questionNumberLabel =
    subQuestionsTotal > 1 ? `les ${subQuestionsTotal} sous questions` : 'la sous question'
  if (showSubQuestions) {
    return subQuestionsTotal > 0 ? `Masquer ${questionNumberLabel}` : 'Masquer les sous questions'
  } else {
    return subQuestionsTotal > 0 ? `Voir ${questionNumberLabel}` : 'Ajouter une sous question'
  }
}

interface SubQuestionsProps {
  mode: QuestionnaireMode
  question: Question
  onAddSubQuestion?: AddSubQuestionCallback
  onDelete?: DeleteQuestionCallback
  onEdit?: EditQuestionCallback
  setAnswerValue?: SetAnswerValueCallback
  answerValuesIds?: number[] | null
  subQuestionsShown?: boolean
  onShowSubQuestions?: ShowSubQuestionsCallback
  disabled?: boolean
  isReadonly?: boolean
}

export const SubQuestions: FunctionComponent<SubQuestionsProps> = ({
  mode,
  question,
  subQuestionsShown = false,
  onEdit,
  onDelete,
  onAddSubQuestion,
  onShowSubQuestions,
  answerValuesIds,
  disabled,
  isReadonly,
  setAnswerValue,
}) => {
  const [overflowHidden, setOverflowHidden] = useState(true)
  const { scrollToAnswerId } = useContext(QuestionContext)

  /**
   * Affichage des sous questions
   *  - ce n'est pas une sous question
   *  - s'il est demandé de scroller dans les sous questions "scrollToAnswerId"
   *  - s'il en existe déjà
   */
  const displaySubQuestions = useMemo(
    () =>
      (!question.parentAnswerId &&
        scrollToAnswerId &&
        question.answers.map(({ id }) => id).includes(scrollToAnswerId)) ||
      question.answers.some((answer) =>
        answer.childQuestions ? answer.childQuestions.length > 0 : false,
      ),
    [question.parentAnswerId, scrollToAnswerId, question.answers],
  )

  const handleClickShowingMore = () => {
    const show = !subQuestionsShown
    setOverflowHidden(true) // il faut avoir le overflow hidden durant la transition
    if (onShowSubQuestions) {
      onShowSubQuestions(show)
    }
  }

  const answers = question.answers.filter((answer) => {
    if (answerValuesIds !== undefined) {
      return (
        answerValuesIds &&
        answerValuesIds.includes(answer.id) &&
        answer.childQuestions &&
        answer.childQuestions.length > 0
      )
    }
    return true
  })

  const hasChildQuestions = useMemo(
    () =>
      answers.some((answer) => (answer.childQuestions ? answer.childQuestions.length > 0 : false)),
    [answers],
  )
  const subQuestionsTotal = useMemo(() => {
    return answers.reduce(
      (acc, answer) => (acc += answer.childQuestions ? answer.childQuestions.length : 0),
      0,
    )
  }, [answers])
  useEffect(() => {
    if ((hasChildQuestions || displaySubQuestions) && onShowSubQuestions) {
      onShowSubQuestions(true)
    }
  }, [hasChildQuestions, onShowSubQuestions, displaySubQuestions])

  const [ref, { height }] = useMeasure()

  const style = useSpring({
    to: { height: subQuestionsShown ? height : 0 },
    onRest: () => {
      setOverflowHidden(!subQuestionsShown)
    },
  })

  // Condition pour afficher le boutton d'affichage des sous questions
  const isMoreButtonDisplayed = useMemo(() => {
    const hasSubQuestions = question.answers.length > 0 && subQuestionsTotal > 0
    const canBeAddedSubQuestions =
      mode === QuestionnaireMode.managerEdit &&
      ![QuestionType.Slider, QuestionType.Text, QuestionType.Date].includes(question.type) &&
      question.answers.length > 0
    const isNotSubQuestion = !isDefined(question.parentAnswerId)

    return hasSubQuestions || (isNotSubQuestion && canBeAddedSubQuestions)
  }, [mode, question.answers.length, question.parentAnswerId, question.type, subQuestionsTotal])

  return (
    <>
      {isMoreButtonDisplayed ? (
        <div className={styles.subQuestionsButtonContainer}>
          <MoreButton showingMore={subQuestionsShown} onChange={() => handleClickShowingMore()}>
            {displaySubQuestionLabel(subQuestionsShown, subQuestionsTotal)}
          </MoreButton>
        </div>
      ) : null}
      {(answers.length > 0 || mode === QuestionnaireMode.managerEdit) && (
        <animated.div style={{ ...style, overflow: overflowHidden ? 'hidden' : 'visible' }}>
          <div ref={ref}>
            <QuestionDetail
              questionTitle={question.title}
              mode={mode}
              answers={mode === QuestionnaireMode.fill ? answers : question.answers}
              setAnswerValue={setAnswerValue}
              disabled={!!disabled}
              onAddSubQuestion={isReadonly ? undefined : onAddSubQuestion}
              onDelete={onDelete}
              onEdit={onEdit}
              isReadonly={isReadonly}
            ></QuestionDetail>
          </div>
        </animated.div>
      )}
    </>
  )
}
