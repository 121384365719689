import React, { FunctionComponent, useMemo, useEffect } from 'react'
import { SelectInput } from '../../../../shared'
import { Profession } from '../../../../../model/Professions'
import { ProfessionSelectProps } from './ProfessionSelect.model'
import { useField } from 'formik'
import { SelectOption } from '../../../../../model/SelectOption'

export const ProfessionSelect: FunctionComponent<ProfessionSelectProps> = ({
  listOfProfession,
  theme = 'light',
  disabled = false,
  getListOfProfessions,
}) => {
  const [formValue, { error }, helpers] = useField<Profession | null>('profession')

  useEffect(() => {
    getListOfProfessions()
  }, [getListOfProfessions])

  const selectOptions: SelectOption<Profession>[] = useMemo(
    () =>
      listOfProfession.map((profession) => ({
        key: profession.oid,
        value: profession,
        label: profession.description,
      })),
    [listOfProfession],
  )

  const selectValue = useMemo(
    () => selectOptions.find((option) => option.value.id === formValue?.value?.id),
    [formValue.value, selectOptions],
  )

  return (
    <SelectInput<Profession>
      title="Profession"
      disabled={disabled}
      options={selectOptions}
      colorPreset={theme}
      value={selectValue}
      onSelect={({ value }) => {
        helpers.setValue(value)
      }}
      error={error}
    />
  )
}
