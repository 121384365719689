import React, { FunctionComponent } from 'react'

import { Questionnaire } from '../../../../model/Questionnaire'
import { ChipLabel } from '../../chip'

import { ScorePercentage } from './ScorePercentage'

export const QuestionnaireLabel: FunctionComponent<{
  documentId?: number
  questionnaire: Questionnaire
  label: string
}> = ({ documentId, questionnaire: { id, scoreConfig }, label }) => {
  return (
    <div className="flex">
      <ChipLabel label={label} />
      {documentId && scoreConfig && (
        <div className="ml-2">
          <ScorePercentage documentId={documentId} questionaireId={id} scoreConfig={scoreConfig} />
        </div>
      )}
    </div>
  )
}
