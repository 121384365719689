import { connect } from 'react-redux'
import { RootState } from '../../../store'

import { EditQuestionPanelStateProps } from './EditQuestionPanel.model'

import EditQuestionPanel from './EditQuestionPanel.component'
import { isAdminSelector, isExtDsmSelector } from '../../../store/ui/adminUsers/index'
import { editorFontFamilyPreferencesSelector } from '../../../store/domain/me'

const mapStateToProps = (state: RootState): EditQuestionPanelStateProps => ({
  isExtDsm: isExtDsmSelector(state),
  isAdmin: isAdminSelector(state),
  editorFontFamily: editorFontFamilyPreferencesSelector(state),
})

export default connect(mapStateToProps, {})(EditQuestionPanel)
