import { FC, useCallback } from 'react'
import { VitalCardDifferencesModalProps } from './VitalCardDifferencesModal.model'
import styles from './VitalCardDifferencesModal.module.scss'
import { ValidationModal } from '../ValidationModal/ValidationModal.component'
import { inputInseeNumberFormatter } from '../../../../misc/inseeNumber.utils'

export const VitalCardDifferencesModal: FC<VitalCardDifferencesModalProps> = ({
  vitalCardDifferences,
  openPatient,
  isUnmounting,
}) => {
  const handleSubmit = useCallback(() => {
    openPatient(true)
  }, [openPatient])

  const handleCancel = useCallback(() => {
    openPatient(false)
  }, [openPatient])

  const formatedInsee = vitalCardDifferences?.inseeNumber
    ? inputInseeNumberFormatter(vitalCardDifferences.inseeNumber)
    : null

  return (
    <ValidationModal
      isUnmounting={isUnmounting}
      title="Les données présentes sur cette carte vitale sont différentes de celles connues pour ce·tte patient·e"
      confirmTheme="primary"
      onSubmit={handleSubmit}
      onClose={handleCancel}
      cancelLabel="Ignorer"
      size="medium"
    >
      {vitalCardDifferences && (
        <div className={styles.content}>
          {vitalCardDifferences.birthPlaceCode && (
            <p>Code lieu de naissance - {vitalCardDifferences.birthPlaceCode}</p>
          )}
          {vitalCardDifferences.inseeNumber && <p>Matricule INS - {formatedInsee}</p>}
        </div>
      )}
      <div className={styles.conclusion}>Souhaitez-vous effectuer la mise à jour ?</div>
    </ValidationModal>
  )
}
