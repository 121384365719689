import { Patient } from '../../../model/Patient'

export interface VitalCardState {
  vitalCardPatient: Partial<Patient> | null
  beneficiaries: Array<Partial<Patient>>
  vitalCardPatientDifferences: Partial<Patient> | null
  cpsPincode: string | null
  cpsPincodeModalOpen: boolean
}

export enum VitalCardActionTypes {
  READ_VITAL_CARD_INFOS = '@VITAL_CARD/READ_VITAL_CARD_INFOS',
  OPEN_PATIENT_PAGE = '@VITAL_CARD/OPEN_PATIENT_PAGE',
  SET_VITAL_CARD_PATIENT = '@VITAL_CARD/SET_VITAL_CARD_PATIENT',
  SET_BENEFICIARIES_LIST_VITAL_CARD = '@VITAL_CARD/SET_BENEFICIARIES_LIST_VITAL_CARD',
  SELECT_BENEFICIARY = '@VITAL_CARD/SELECT_BENEFICIARY',
  SET_VITAL_CARD_DIFFERENCES = '@VITAL_CARD/SET_VITAL_CARD_DIFFERENCES',
  CPS_PINCODE_MODAL_OPEN = '@VITAL_CARD/CPS_PINCODE_MODAL_OPEN',
  SET_CPS_PINCODE = '@VITAL_CARD/SET_CPS_PINCODE',
  APPLY_VITAL_CARD_DIFFERENCES = '@VITAL_CARD/APPLY_VITAL_CARD_DIFFERENCES',
}
