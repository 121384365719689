import { Speciality } from '../../../model/Professions'

export interface SpecialitiesState {
  specialitiesList: Array<Speciality>
}

export enum SpecialityActionsType {
  GET_LIST_OF_SPECIALITIES = 'GET_LIST_OF_SPECIALITIES',
  STORE_LIST_OF_SPECIALITIES = 'STORE_LIST_OF_SPECIALITIES',
}
