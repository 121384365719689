import getApi from '../../../../api/index'
import { AnswerValue } from '../../../../model/AnswerValue'
import { PaginatedList } from '../../../../model/Pagination'
import { IdentityIdentifier } from '../../../restux/restux.model'

const route = `/answer_values`

/**
 * documents/{documentId}/answer_values pour toutes les réponses questionnaires/variables
 */
export const getDocumentAnswerValues = (documentId: IdentityIdentifier) => {
  const url = `/documents/${documentId}/answer_values`
  return getApi().get<PaginatedList<AnswerValue>>(url, {
    page: 1,
    limit: 1000,
  })
}

export const saveDocumentAnswerValue = (item: Partial<AnswerValue>) =>
  getApi().post<AnswerValue>(route, item)

export const deleteDocumentAnswerValue = (id: number) => getApi().delete<void>(`${route}/${id}`)
