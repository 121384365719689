import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { ImportantInformation } from '../../../model/ImportantInformations'
import { getImportantInformations } from '../../../data/importantInformations'
import { importantInformationsKeys } from './importantInformations.keys'

interface GetAllImportantInformationsParams {
  enabled?: boolean
}

export const useGetAllImportantInformations = ({
  enabled = true,
}: GetAllImportantInformationsParams = {}) => {
  const queryKey = importantInformationsKeys.listAll

  const fetchImportantInformations = async (page: number, signal?: AbortSignal) => {
    const { data, ok } = await getImportantInformations(page, signal)
    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        'Impossible de récupérer la liste des informations importantes',
        "Une erreur technique s'est produite",
      )
    }
    return data
  }

  const queryReturn = useQuery<ImportantInformation[]>(
    queryKey,
    async ({ signal }) => {
      const firstPaginatedList = await fetchImportantInformations(1, signal)
      const paginatedLists = await Promise.all(
        Array.from({ length: firstPaginatedList.pageCount - 1 }).map((_, index) => {
          const page = index + 2
          return fetchImportantInformations(page, signal)
        }),
      )

      return [...firstPaginatedList.items, ...paginatedLists.flatMap((p) => p.items)]
    },
    {
      enabled,
    },
  )

  return { query: queryReturn, importantInformationList: queryReturn.data || [] }
}
