import { FunctionComponent, useCallback, useRef, useState } from 'react'
import { IconButton, RoundedButton } from '../../../shared'
import { useOnScreen } from '../../../../hooks/utils'

import { QuestionnaireInstanceProps } from './QuestionnaireInstance.model'
import { QuestionList, QuestionnaireMode } from '../../../questionnaire'
import { QuestionnairePlaceholder } from './QuestionnairePlaceholder'

import styles from './QuestionnaireInstance.module.scss'
import { SelectedContentType } from '../../../../store/ui/medicalEvents/medicalEventContent'
import { BaseQuestion, Question } from '../../../../model/Questionnaire'
import { EditQuestionBottomPanel } from '../../../shared/bottomPanel/EditQuestionBottomPanel'
import { scrollToTopSmoothly } from '../../../../misc/window.utilities'
import { isDefined } from '../../../../misc/functions.utilities'

export const QuestionnaireInstance: FunctionComponent<QuestionnaireInstanceProps> = ({
  documentInstanceId,
  onEditQuestion,
  questions,
  isReadonly = false,
  nextQuestionnaireId,
  disabled,
  selectMedicalEventContent,
  saveAnswerValue,
}) => {
  const [editQuestionId, setEditQuestionId] = useState<number | null>(null)
  const topRef = useRef<HTMLDivElement>(null)

  const topOutOfScreen = !useOnScreen(topRef)

  const loadNextQuestionnaire = useCallback(
    (nextQuestionnaireId: number) => () => {
      scrollToTopSmoothly()
      selectMedicalEventContent({
        type: SelectedContentType.QUESTIONNAIRE,
        questionnaireId: nextQuestionnaireId,
      })
    },
    [selectMedicalEventContent],
  )

  const handleQuestionEdited = (question: Question) => {
    // Laisser cette action Redux pour rafraichir les ressources parentes
    onEditQuestion(question.id, { documentInstanceId })
  }

  return (
    <div className={styles.container}>
      <div ref={topRef} />
      {questions.length === 0 ? (
        <QuestionnairePlaceholder />
      ) : (
        <>
          <QuestionList
            mode={QuestionnaireMode.fill}
            questions={questions}
            onEdit={
              documentInstanceId
                ? (question: BaseQuestion) => {
                    setEditQuestionId(question.id)
                  }
                : undefined
            }
            setAnswerValue={(questionId, answerValue) => {
              saveAnswerValue(questionId, answerValue)
            }}
            setSubQuestionsAnswerValues={(questionId, answerValue) => {
              saveAnswerValue(questionId, answerValue)
            }}
            isReadonly={isReadonly}
            disabled={disabled}
          />
        </>
      )}
      <div className={styles.bottomActions}>
        <div className={styles.nextButton}>
          {nextQuestionnaireId ? (
            <RoundedButton
              theme="primary"
              onClick={loadNextQuestionnaire(nextQuestionnaireId)}
              icon="arrow"
              iconRotate={90}
              iconPosition="right"
              label="Questionnaire suivant"
              testId="button-questionnaire-next"
            />
          ) : (
            <RoundedButton
              theme="primary"
              onClick={() => selectMedicalEventContent({ type: SelectedContentType.EDITOR })}
              label="Aperçu du document"
              testId="button-document-preview"
            />
          )}
        </div>
        {topOutOfScreen && (
          <div className={styles.scrollTopButton}>
            <IconButton
              onClick={scrollToTopSmoothly}
              icon="arrow"
              theme="primary"
              title="Remonter"
              testId="button-scroll-top"
            />
          </div>
        )}
      </div>
      <EditQuestionBottomPanel
        display={isDefined(editQuestionId)}
        onRequestClose={() => setEditQuestionId(null)}
        itemId={editQuestionId}
        onItemEdited={handleQuestionEdited}
      />
    </div>
  )
}
