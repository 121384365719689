import classNames from 'classnames/bind'
import React, { FunctionComponent, ReactNode, useContext } from 'react'
import { Question } from '../../../../model/Questionnaire'
import {
  DeleteQuestionCallback,
  EditQuestionCallback,
  QuestionnaireMode,
} from '../../Questionnaire.model'
import { IconButton } from '../../../shared'
import { QuestionContext } from '../../context'

import styles from './QuestionWrapper.module.scss'

const cx = classNames.bind(styles)

interface QuestionWrapperProps {
  children?: ReactNode
  mode: QuestionnaireMode
  question: Question
  filled?: boolean
  title?: React.ReactNode
  onDelete?: DeleteQuestionCallback
  onEdit?: EditQuestionCallback
  subQuestions?: React.ReactNode
}

export const QuestionWrapper: FunctionComponent<QuestionWrapperProps> = ({
  mode,
  question,
  title,
  filled,
  children,
  onDelete,
  onEdit,
  subQuestions,
}) => {
  const { renderImportantAction } = useContext(QuestionContext)

  return (
    <div
      data-test-id={`question-${question.id}`}
      className={cx(styles.container, {
        filled,
        shared: question.sharedInMedicalEvent,
      })}
    >
      {mode === 'fill' && (
        <div className={styles.fillIndicatorContainer}>
          <div className={styles.fillIndicator} />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.questionHeader}>
          {title}
          <div className={styles.actions}>
            {renderImportantAction && renderImportantAction(question)}
            {onEdit && (
              <IconButton
                theme="transparent-dark"
                size="micro"
                icon="pencil"
                onClick={() => onEdit(question)}
                testId="button-edit-question"
              />
            )}
            {onDelete && (
              <IconButton
                theme="transparent-dark"
                size="micro"
                icon="trash"
                onClick={() => onDelete(question)}
                testId="button-delete-question"
              />
            )}
          </div>
        </div>
        <div className={styles.answers}>{children}</div>
        {subQuestions}
      </div>
    </div>
  )
}
