import { deserializeQuestion } from '@follow/cdk'
import { ApiResponse } from 'apisauce'
import { PaginatedList } from '../../../../model/Pagination'
import { Question, QuestionLight } from '../../../../model/Questionnaire'
import { QuestionCreationResource, QuestionResource } from './Questions.model'

export { deserializeQuestion }

export function deserializeQuestions(questions: QuestionResource[]): Question[] {
  return questions.map(deserializeQuestion)
}

export function serializeQuestion({
  answers,
  parentAnswerId,
  ...restProps
}: Partial<Question>): Partial<QuestionCreationResource> {
  return {
    ...restProps,
    parentAnswer: parentAnswerId,
    answers: answers && answers.map(({ id }) => id),
  }
}

export function deserializeQuestionResponse(
  response: ApiResponse<QuestionResource>,
): ApiResponse<QuestionLight> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: deserializeQuestion(data),
  }
}

export function deserializeQuestionsPageResponse(
  questionsPage: ApiResponse<PaginatedList<QuestionResource>>,
) {
  const { data, ...responseRest } = questionsPage
  // Error case => no deserialization
  if (!questionsPage.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: deserializeQuestions(data.items) },
  }
}
