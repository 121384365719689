import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { createAnswer } from '../../../data/answers'
import { AnswerMutationPayload, AnswerResource } from '../../../data/answers/mapper/answers.model'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { questionsKeys } from '../questions/questions.keys'

interface AnswerMutateVariable {
  payload: AnswerMutationPayload
  questionnaireId?: number
}

export const useCreateAnswer = () => {
  const queryClient = useQueryClient()

  return useMutation<AnswerResource, Error, AnswerMutateVariable>(
    async ({ payload }) => {
      const { data, ok } = await createAnswer(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de la réponse a échoué')
      }
      return data
    },
    {
      onSuccess: (answer, { questionnaireId }) => {
        queryClient.invalidateQueries(questionsKeys.detail(answer.questionId))
        if (questionnaireId) {
          queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
        }
      },
    },
  )
}
