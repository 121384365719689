import { createReducer, Reducer } from 'redux-starter-kit'
import { professionsActions } from './professions.actions'
import { professionsActionsType, ProfessionsState } from './professions.model'

const PROFESSIONS_DEFAULT_STATE: ProfessionsState = {
  professionsList: [],
}

const ACTION_HANDLERS = {
  [professionsActionsType.STORE_LIST_OF_PROFESSIONS]: (
    state: ProfessionsState = PROFESSIONS_DEFAULT_STATE,
    { professionsList }: ReturnType<typeof professionsActions.storeListOfProfessions>,
  ) => ({
    ...state,
    professionsList,
  }),
}

export const professionsReducer: Reducer = createReducer<ProfessionsState>(
  PROFESSIONS_DEFAULT_STATE,
  ACTION_HANDLERS,
)
