import React, { FunctionComponent } from 'react'
import { VitalCardButtonProps } from './VitalCardButton.model'

export const VitalCardButton: FunctionComponent<VitalCardButtonProps> = ({
  vitalCardService,
  readVitalCardInfos,
  renderButton,
}) => {
  return <>{vitalCardService && renderButton({ readVitalCardInfos })}</>
}
