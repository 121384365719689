import { Patient } from '../../../model/Patient'
import { enabledFeaturesPreferencesSelector } from '../me'
import { RootState } from '../../reducers'

export const currentVitalCardPatient = (state: RootState): Partial<Patient> | null => {
  return state.domain.vitalCard.vitalCardPatient
}

export const currentBeneficiariesListVitalCard = (state: RootState): Array<Partial<Patient>> => {
  return state.domain.vitalCard.beneficiaries
}

export const vitalCardServiceSelector = (state: RootState) => {
  const enabledFeatures = enabledFeaturesPreferencesSelector(state)

  return enabledFeatures?.vitalCardService ?? null
}

export const vitalCardPatientDifferencesSelector = (state: RootState) => {
  return state.domain.vitalCard.vitalCardPatientDifferences
}

export const vitalCardCpsPincodeSelector = (state: RootState) => {
  return state.domain.vitalCard.cpsPincode
}

export const vitalCardCpsModalOpenSelector = (state: RootState) => {
  return state.domain.vitalCard.cpsPincodeModalOpen
}
