interface BaseSurvey {
  survey: SurveyBranchConfig[]
  active: boolean
  finish: string
  name: string
  tagIds: string[]
}

export interface Survey extends BaseSurvey {
  id: number
  pathologyId: number
}

export interface SurveyApi extends BaseSurvey {
  pathologyId: number
}

export interface SurveyBranchConfig {
  uuid: string
  level: number
  questions: SurveyBranchItem[]
}

export type SurveyBranchItem = SurveyScore | SurveyQuestionConfig

export interface SurveyScore {
  type: SurveyBranchItemType.Score
  uuid: string
  id: number
  excludedFields: number[]
  fields: SurveyFieldCustomLabel[]
  beforeText: string
}

export interface SurveyQuestionConfig {
  type: SurveyBranchItemType.Question
  uuid: string
  label: string
  answers: SurveyQuestionAnswer[]
}

export interface SurveyFieldCustomLabel {
  id: number
  value: string
}

export interface SurveyQuestionAnswer {
  label: string
  branchUuid: string
}

export enum SurveyBranchItemType {
  Score = 'score',
  Question = 'question',
}

export interface SurveyLoading {
  item: boolean
  list: boolean
}

export interface ReduxSurvey {
  surveys: Survey[]
  details: { [id: number]: Survey | null }
  selectedSurvey: number | null
  pageCount: number
  tree: SurveyBranchConfig[]
  branch: SurveyBranchConfig | null
  previousBranch: SurveyBranchConfig | null
  loading: SurveyLoading
}

export enum SurveyActionTypes {
  GET_SURVEYS = 'GET_SURVEYS',
  GET_SURVEY = 'GET_SURVEY',
  SET_SURVEYS = 'SET_SURVEYS',
  ADD_SURVEYS = 'ADD_SURVEYS',
  ADD_SURVEY = 'ADD_SURVEY',
  CLEAR_SURVEYS_LIST = 'CLEAR_SURVEYS_LIST',
  DUPLICATE_SURVEY = 'DUPLICATE_SURVEY',
  SET_SURVEY = 'SET_SURVEY',
  UPDATE_SURVEY = 'UPDATE_SURVEY',
  SET_UPDATED_SURVEY = 'SET_UPDATED_SURVEY',
  DELETE_SURVEY = 'DELETE_SURVEY',
  REMOVE_SURVEY = 'REMOVE_SURVEY',
  SET_ROOT = 'SET_ROOT',
  SET_BRANCH = 'SET_BRANCH',
  RESET_TREE = 'RESET_TREE',
  ADD_SCORES_TO_SURVEY_BRANCH = 'ADD_SCORES_TO_SURVEY_BRANCH',
  SET_UPDATED_SURVEY_BRANCH = 'SET_UPDATED_SURVEY_BRANCH',
  DELETE_SURVEY_SCORE = 'DELETE_SURVEY_SCORE',
  ADD_QUESTION_TO_SURVEY_BRANCH = 'ADD_QUESTION_TO_SURVEY_BRANCH',
  DELETE_SURVEY_QUESTION = 'DELETE_SURVEY_QUESTION',
  EDIT_QUESTION_IN_SURVEY_BRANCH = 'EDIT_QUESTION_IN_SURVEY_BRANCH',
  TOGGLE_SURVEY_SCORE_FIELD = 'TOGGLE_SURVEY_SCORE_FIELD',
  SET_SCORE_EXCLUDED_FIELDS = 'SET_SCORE_EXCLUDED_FIELDS',
  UPDATE_SCORE_FIELD_LABEL = 'UPDATE_SCORE_FIELD_LABEL',
  UPDATE_SURVEY_CONCLUSION = 'UPDATE_SURVEY_CONCLUSION',
  SET_UPDATED_SURVEY_CONCLUSION = 'SET_UPDATED_SURVEY_CONCLUSION',
  UPDATE_SURVEY_SCORE_INTRODUCTION = 'UPDATE_SURVEY_SCORE_INTRODUCTION',
  UPDATE_SURVEY_BRANCH_ITEMS = 'UPDATE_SURVEY_BRANCH_ITEMS',
  GET_BRANCH_SCORES = 'GET_BRANCH_SCORES',
  SET_SURVEY_ITEM_LOADING = 'SET_SURVEY_ITEM_LOADING',
  SET_SURVEY_LIST_LOADING = 'SET_SURVEY_LIST_LOADING',
  SELECT_SURVEY_ITEM = 'SELECT_SURVEY_ITEM',
  SET_SELECTED_SURVEY = 'SET_SELECTED_SURVEY',
  DUPLICATE_SURVEY_TO_ANOTHER_USER = 'DUPLICATE_SURVEY_TO_ANOTHER_USER',
  ADD_QUESTIONNAIRES_TO_SELECTED_SURVEY = '@SURVEY/UI-ADD_QUESTIONNAIRES_TO_SELECTED_SURVEY',
}

/**
 * Typescript type guard. Determine if "SurveyBranchItem" is a "ISurveyScore"
 */
export function isSurveyScoreObject(branchItem: SurveyBranchItem): branchItem is SurveyScore {
  return branchItem.hasOwnProperty('id')
}
