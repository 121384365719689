import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'

import { deleteQuestion } from '../../../data/questions'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { questionsKeys } from './questions.keys'

interface QuestionMutateVariable {
  questionId: number
  questionnaireIds?: number[]
}

export const useDeleteQuestion = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, QuestionMutateVariable>(
    async ({ questionId }) => {
      const { ok } = await deleteQuestion(questionId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la question a échoué')
      }
      return null
    },
    {
      onSuccess: (_, { questionId, questionnaireIds }) => {
        queryClient.invalidateQueries(questionsKeys.lists)
        queryClient.invalidateQueries(questionsKeys.detail(questionId))
        questionnaireIds?.map((id) => queryClient.invalidateQueries(questionnairesKeys.detail(id)))
      },
    },
  )
}
