import { connect } from 'react-redux'
import { RootState } from '../../../../../store'
import { professionsActions } from '../../../../../store/domain/professions'
import { professionSelector } from '../../../../../store/domain/professions/professions.selector'
import { ProfessionSelectDispatchProps, ProfessionSelectStateProps } from './ProfessionSelect.model'
import { ProfessionSelect as component } from './ProfessionSelect.component'

const mapStateToProps = (state: RootState): ProfessionSelectStateProps => ({
  listOfProfession: professionSelector(state),
})

const mapDisptachToProps: ProfessionSelectDispatchProps = {
  getListOfProfessions: professionsActions.getListOfProfession,
}

export const ProfessionSelect = connect(mapStateToProps, mapDisptachToProps)(component)
