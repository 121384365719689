import getApi from '../../../../api'
import { Profession } from '../../../../model/Professions'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

const baseUrl = '/professions'

export const restuxProfessionsApiConfig: RestuxApiConfig<Profession, Profession> = {
  url: baseUrl,
  getItem: (route) => getApi().get<Profession>(route),
}

export const getProfessionList = () => getApi().get<Profession[]>(baseUrl)
