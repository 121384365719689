import styled from '@emotion/styled'
import { PageBreakComponentProps } from './PageBreak.model'
import css from '@emotion/css'

export const PageBreak = styled.div<PageBreakComponentProps>`
  break-after: page;
  display: block;
  ${({ mode }) =>
    mode !== 'print' &&
    css`
      position: relative;
      text-align: center;
      &::before {
        content: '';
        height: 1px;
        background: #555878;
        width: 100%;
        display: block;
        top: calc(50% - 1px);
        position: absolute;
      }
      &::after {
        content: attr(title);
        color: #555878;
        background: #ffffff;
        position: relative;
        padding: 0 0.5rem;
        display: inline-block;
      }
    `}
`
