import { connect } from 'react-redux'
import { VitalCardDifferencesModal } from './VitalCardDifferencesModal.component'
import {
  VitalCardDifferencesModalDispatchProps,
  VitalCardDifferencesModalStoreProps,
} from './VitalCardDifferencesModal.model'
import { RootState } from '../../../../store'
import { vitalCardPatientDifferencesSelector } from '../../../../store/domain/vitalCard/vitalCard.selector'
import { vitalCardActions } from '../../../../store/domain/vitalCard'
import { withUnmountTransition } from '../../../../misc/withUnmountTransition.hoc'

const mapStateToProps = (state: RootState): VitalCardDifferencesModalStoreProps => ({
  vitalCardDifferences: vitalCardPatientDifferencesSelector(state),
  display: !!vitalCardPatientDifferencesSelector(state),
})

const mapDispatchToProps: VitalCardDifferencesModalDispatchProps = {
  openPatient: vitalCardActions.applyVitalCardDifferences,
}

const withUnmountHoc = withUnmountTransition(VitalCardDifferencesModal)
const withConnect = connect(mapStateToProps, mapDispatchToProps)(withUnmountHoc)
export { withConnect as VitalCardDifferencesModal }
