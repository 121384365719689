import { ReduxSurvey, SurveyActionTypes } from './survey.model'
import {
  setSurveys,
  setSurvey,
  removeSurvey,
  setUpdatedSurvey,
  setUpdatedSurveyBranch,
  setRoot,
  setBranch,
  resetTree,
  setUpdatedSurveyConclusion,
  setSurveyItemLoading,
  setSurveyListLoading,
  setSelectedSurvey,
  addSurveys,
} from './survey.actions'
import createReducer from '../reducers/createReducer'

const INITIAL_STATE: ReduxSurvey = {
  details: {},
  selectedSurvey: null,
  surveys: [],
  pageCount: 1,
  tree: [],
  branch: null,
  previousBranch: null,
  loading: {
    item: true,
    list: true,
  },
}

const ACTION_HANDLERS = {
  [SurveyActionTypes.SET_SURVEYS]: (
    state: ReduxSurvey = INITIAL_STATE,
    { surveys, pageCount }: ReturnType<typeof setSurveys>,
  ): ReduxSurvey => ({
    ...state,
    surveys,
    pageCount,
    loading: {
      ...state.loading,
      list: false,
    },
  }),
  [SurveyActionTypes.ADD_SURVEYS]: (
    state: ReduxSurvey = INITIAL_STATE,
    { surveys, pageCount }: ReturnType<typeof addSurveys>,
  ): ReduxSurvey => {
    return {
      ...state,
      surveys: [...state.surveys, ...surveys],
      pageCount,
      loading: {
        ...state.loading,
        list: false,
      },
    }
  },
  [SurveyActionTypes.SET_SURVEY]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setSurvey>,
  ): ReduxSurvey => ({
    ...state,
    surveys: [action.survey, ...state.surveys],
  }),
  [SurveyActionTypes.CLEAR_SURVEYS_LIST]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setSurvey>,
  ): ReduxSurvey => ({
    ...state,
    surveys: [],
  }),
  [SurveyActionTypes.REMOVE_SURVEY]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof removeSurvey>,
  ): ReduxSurvey => ({
    ...state,
    surveys: state.surveys.filter((survey) => survey.id !== action.surveyId),
  }),
  [SurveyActionTypes.SET_UPDATED_SURVEY]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setUpdatedSurvey>,
  ): ReduxSurvey => {
    const toEditSurvey = getSelectedSurvey(state)

    // Update details if a survey is currently in edition
    const details = toEditSurvey
      ? {
          ...state.details,
          [toEditSurvey.id]: action.survey,
        }
      : state.details

    return {
      ...state,
      surveys: state.surveys.map((survey) =>
        survey.id !== action.survey.id ? survey : action.survey,
      ),
      details,
    }
  },
  [SurveyActionTypes.SET_UPDATED_SURVEY_BRANCH]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setUpdatedSurveyBranch>,
  ): ReduxSurvey => ({
    ...state,
    details: {
      ...state.details,
      [action.survey.id]: action.survey,
    },
    tree: action.tree,
    branch: action.tree.find((branch) => branch.uuid === action.branchUuid) || null,
  }),
  [SurveyActionTypes.SET_ROOT]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setRoot>,
  ): ReduxSurvey => {
    const toEditSurvey = getSelectedSurvey(state)
    if (!toEditSurvey) {
      return state
    }
    const rootBranch = toEditSurvey.survey.find((branch) => branch.level === 0)
    return {
      ...state,
      tree: toEditSurvey.survey,
      branch: rootBranch || null,
      previousBranch: null,
    }
  },
  [SurveyActionTypes.SET_BRANCH]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setBranch>,
  ): ReduxSurvey => ({
    ...state,
    branch: state.tree.find((branch) => branch.uuid === action.toBranchUuid) || null,
    previousBranch: state.tree.find((branch) => branch.uuid === action.fromBranchUuid) || null,
  }),
  [SurveyActionTypes.RESET_TREE]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof resetTree>,
  ): ReduxSurvey => ({
    ...state,
    tree: [],
    branch: null,
    previousBranch: null,
  }),
  [SurveyActionTypes.SET_UPDATED_SURVEY_CONCLUSION]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setUpdatedSurveyConclusion>,
  ): ReduxSurvey => {
    const toEditSurvey = getSelectedSurvey(state)
    if (!toEditSurvey) {
      return state
    }

    return {
      ...state,
      details: {
        ...state.details,
        [toEditSurvey.id]: { ...toEditSurvey, finish: action.value },
      },
    }
  },
  [SurveyActionTypes.SET_SURVEY_ITEM_LOADING]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setSurveyItemLoading>,
  ): ReduxSurvey => ({
    ...state,
    loading: {
      ...state.loading,
      item: action.loading,
    },
  }),
  [SurveyActionTypes.SET_SURVEY_LIST_LOADING]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setSurveyListLoading>,
  ): ReduxSurvey => ({
    ...state,
    loading: {
      ...state.loading,
      list: action.loading,
    },
  }),
  [SurveyActionTypes.SET_SELECTED_SURVEY]: (
    state: ReduxSurvey = INITIAL_STATE,
    action: ReturnType<typeof setSelectedSurvey>,
  ): ReduxSurvey => ({
    ...state,
    selectedSurvey: action.surveyId,
    details: { [action.surveyId]: action.survey },
  }),
}

// :POOP:
const getSelectedSurvey = (state: ReduxSurvey) => {
  return (state.selectedSurvey && state.details[state.selectedSurvey]) || null
}

export const surveyReducer = createReducer(INITIAL_STATE, ACTION_HANDLERS)
