import { put, takeEvery, select } from 'redux-saga/effects'
import { documentTemplatesActions } from '../../cache/documentTemplates'
import { questionsActions } from '../../cache/questions'
import { EditQuestionAction, UiBottomPanelQuestionsActionTypes } from './bottomPanelQuestions.model'
import { bottomPanelQuestionsActions } from './bottomPanelQuestions.actions'
import { identifierMatchScope } from '../../restux/restux.utilities'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { inUseDocumentTemplateSelector } from '../bottomPanelDocumentTemplates'
import { documentInstancesActions } from '../../cache/documentInstances'
import { getVariableData } from '../../renderer'

function* editQuestionSagaWorker({ questionContext, onQuestionEdited }: EditQuestionAction) {
  if (questionContext.documentInstanceId) {
    yield put(
      documentInstancesActions.actions.apiGetItemDetails(questionContext.documentInstanceId),
    )
    yield put(getVariableData())
  }
  if (questionContext.documentTemplateId) {
    yield put(
      documentTemplatesActions.actions.apiGetItemDetails(questionContext.documentTemplateId),
    )
  }
  if (onQuestionEdited) {
    onQuestionEdited()
  }
}

function* editQuestionSagaWatcher() {
  yield takeEvery(UiBottomPanelQuestionsActionTypes.EDIT_QUESTION, editQuestionSagaWorker)
}

function* onCreateQuestionWorker({
  identifier,
  item,
}: ReturnType<typeof questionsActions.actions.storeSetItemDetails>) {
  if (identifierMatchScope(identifier, RESTUX_IDENTIFIER.createQuestion)) {
    const inUseDocumentTemplate: ReturnType<typeof inUseDocumentTemplateSelector> = yield select(
      inUseDocumentTemplateSelector,
    )
    if (inUseDocumentTemplate) {
      yield put(
        bottomPanelQuestionsActions.editQuestionFromTemplate(item.id, {
          documentTemplateId: inUseDocumentTemplate.id,
        }),
      )
    }
  }
}

function* onCreateQuestionWatcher() {
  yield takeEvery(questionsActions.types.STORE_SET_ITEM_DETAILS, onCreateQuestionWorker)
}

export const bottomPanelQuestionSagas = {
  editQuestionSagaWatcher,
  onCreateQuestionWatcher,
}
