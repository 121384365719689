import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mergePatients } from '../../../data/patients'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from './patients.keys'
import { useActionDispatch } from '../../utils'
import { medicalEventDomainActions } from '../../../store/domain/medicalEvents'

interface mergeVariable {
  patientId: number
  patientToMergeId: number
}

export const useMergePatient = () => {
  const queryClient = useQueryClient()
  const mergePatientSuccess = useActionDispatch(medicalEventDomainActions.mergePatientSuccess)

  return useMutation<null, Error, mergeVariable>(
    async ({ patientId, patientToMergeId }) => {
      const { ok } = await mergePatients(patientId, patientToMergeId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La fusion des patients a échoué')
      }
      return null
    },
    {
      onSuccess: (_data, { patientId, patientToMergeId }) => {
        // -- Supprimer cette partie lors de la refacto RQ des medical events --
        mergePatientSuccess(patientId, patientToMergeId)
        // ----
        queryClient.invalidateQueries(patientsKeys.lists)
        queryClient.invalidateQueries(patientsKeys.detail(patientId))
      },
    },
  )
}
