import { FunctionComponent, useState } from 'react'
import { useDebounce } from 'react-use'
import { DocumentSection } from '../../../../document'
import { SelectInput } from '../../../../shared'

import { DocumentLayoutProps } from './DocumentLayoutForm.model'
import styles from './DocumentLayoutForm.module.scss'
import { useGetAllDocumentLayouts } from '../../../../../hooks/queries/documentLayouts'

const NO_LAYOUT_ID = -1

export const DocumentLayoutForm: FunctionComponent<DocumentLayoutProps> = ({
  documentInstance,
  isEditable = true,
  updateDocumentInstance,
}) => {
  const { documentLayoutList } = useGetAllDocumentLayouts()

  const [selectedLayoutId, setSelectedLayoutId] = useState(
    documentInstance.documentLayoutId || NO_LAYOUT_ID,
  )

  useDebounce(
    () => {
      updateDocumentInstance(documentInstance.id, {
        documentLayoutId: selectedLayoutId === NO_LAYOUT_ID ? null : selectedLayoutId,
        type: documentInstance.type,
      })
    },
    500,
    [selectedLayoutId],
  )

  const documentLayoutSelectValues = [
    { value: NO_LAYOUT_ID, label: 'Aucun' },
    ...documentLayoutList.map(({ id, title }) => ({ value: id, label: title })),
  ]

  const selectedLayout = documentLayoutSelectValues.find(
    (documentLayoutSelectValue) => documentLayoutSelectValue.value === selectedLayoutId,
  )
  return (
    <DocumentSection
      title="En-tête/Pied de page"
      subtitle={selectedLayout ? selectedLayout.label : 'Aucun'}
    >
      <div className={styles.dropdown}>
        <SelectInput
          options={documentLayoutSelectValues}
          value={selectedLayout}
          onSelect={(selectedValue) =>
            setSelectedLayoutId(selectedValue ? selectedValue.value : NO_LAYOUT_ID)
          }
          disabled={!isEditable}
        />
      </div>
    </DocumentSection>
  )
}
