import { connect } from 'react-redux'
import { CpsPincodeModal } from './CpsPincodeModal.component'
import { CpsPincodeModalDispatchProps, CpsPincodeModalStoreProps } from './CpsPincodeModal.model'
import { RootState } from '../../../../store'
import { vitalCardCpsModalOpenSelector } from '../../../../store/domain/vitalCard/vitalCard.selector'
import { vitalCardActions } from '../../../../store/domain/vitalCard'
import { withUnmountTransition } from '../../../../misc/withUnmountTransition.hoc'

const mapStateToProps = (state: RootState): CpsPincodeModalStoreProps => ({
  display: vitalCardCpsModalOpenSelector(state),
})

const mapDispatchToProps: CpsPincodeModalDispatchProps = {
  setCpsPincode: vitalCardActions.setCpsPincode,
  setModalOpen: vitalCardActions.setCpsPincodeModalOpen,
}

const withUnmountHoc = withUnmountTransition(CpsPincodeModal)
const withConnect = connect(mapStateToProps, mapDispatchToProps)(withUnmountHoc)
export { withConnect as CpsPincodeModal }
