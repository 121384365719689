import { connect } from 'react-redux'

import { RootState } from '../../../store'
import { PatientsList } from './PatientsList.component'

import { PatientsListStoreProps, PatientsListDispatchProps } from './PatientsList.model'
import {
  currentUserSelector,
  loggedUserSelector,
  userMeTeamsSelector,
} from '../../../store/domain/me'
import { vitalCardActions } from '../../../store/domain/vitalCard'
import {
  currentBeneficiariesListVitalCard,
  vitalCardServiceSelector,
} from '../../../store/domain/vitalCard/vitalCard.selector'

const mapStateToProps = (state: RootState): PatientsListStoreProps => ({
  userTeams: userMeTeamsSelector(state),
  currentUser: currentUserSelector(state),
  loggedUser: loggedUserSelector(state),
  vitalCardService: vitalCardServiceSelector(state),
  beneficiaries: currentBeneficiariesListVitalCard(state),
})

const mapDispatchToProps: PatientsListDispatchProps = {
  setVitalCardPatient: vitalCardActions.setVitalCardPatient,
  setBeneficiariesListVitalCard: vitalCardActions.setBeneficiariesListVitalCard,
  openPatientWithVitalCard: vitalCardActions.openPatientWithVitalCard,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PatientsList)
export { withConnect as PatientsList }
