import { FollowTypes } from '../model'
import { parseToValueJSON } from './editor.utils'

export function deserializeAnswer(
  answer: FollowTypes.Questionnaire.AnswerDTO,
): FollowTypes.Questionnaire.Answer {
  return {
    ...answer,
    textOutput: answer.textOutput ? parseToValueJSON(answer.textOutput) : undefined,
    childQuestions: answer.childQuestions
      ? answer.childQuestions.map(deserializeQuestion)
      : undefined,
  }
}

export function deserializeQuestion(
  question: FollowTypes.Questionnaire.QuestionDTO,
): FollowTypes.Questionnaire.Question {
  return {
    ...question,
    documentIds: question.documentIds || [],
    answers: question.answers.map(deserializeAnswer),
  }
}

export function deserializeQuestionnaire(
  resource: FollowTypes.Questionnaire.QuestionnaireDTO,
): FollowTypes.Questionnaire.Questionnaire {
  return {
    ...resource,
    questions: resource.questions.map(deserializeQuestion),
  }
}
