import { format, parse } from 'date-fns'
import { call, put } from 'redux-saga/effects'
import { Patient, Sex, Title } from '../../../../model/Patient'
import { addError, addResponseError } from '../../../message'
import { getStellairVitalCardInfos } from './stellair.api'
import { StellairVitalCardInfos } from './stellair.model'
import { stellairLoginPrompt } from '../../../../misc/Stellair/stellair.actions'

export function* readVitalCardInfosFromStellair(): Generator<Array<Partial<Patient>>> | null {
  const response = yield call(getStellairVitalCardInfos)
  const { ok, status, data } = response
  if (ok && data) {
    const vitalcardInfo: StellairVitalCardInfos = data
    try {
      const beneficiaries = vitalcardInfo.beneficiaire.map((beneficiary) => {
        return {
          inseeNumber: vitalcardInfo.nir.replace(/\s/g, ''),
          birthPlaceCode: vitalcardInfo.nir.replace(/\s/g, '').substring(5, 10),
          birthFirstName: beneficiary.prenom,
          birthLastName: beneficiary.nomFamille ? beneficiary.nomFamille : beneficiary.nomUsuel,
          usedLastName: beneficiary.nomFamille ? beneficiary.nomUsuel : undefined,
          birthDate: format(
            parse(beneficiary.dateNaissanceInterprete, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd',
          ),
          sex: vitalcardInfo.nir.charAt(0) === '1' ? Sex.MALE : Sex.FEMALE,
          title: vitalcardInfo.nir.charAt(0) === '1' ? Title.MR : Title.MS,
        }
      })

      return beneficiaries
    } catch {
      yield put(addError(`Erreur de lecture`, `Le format du patient est invalide`))
    }
  } else {
    if (status === 400) {
      try {
        const status = JSON.parse(data.detail).statusCode
        switch (status) {
          case 401:
            stellairLoginPrompt()
            break
          case 404:
            yield put(addError(`Erreur de lecture`, `Veuillez insérer la carte vitale`))
            break
          case 428:
            yield put(addError(`Erreur de lecture`, `Veuillez connecter le terminal de lecture`))
            break
          default:
            yield put(addError(`Erreur de lecture`, `Une erreur est survenue`))
            break
        }
      } catch {
        yield put(addResponseError(response))
      }
    } else {
      yield put(addResponseError(response))
    }
  }
  return null
}
