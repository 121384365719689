import { FollowTypes, QuestionType } from '@follow/cdk'

export type BaseAnswer = FollowTypes.Questionnaire.BaseAnswer
export type Answer = FollowTypes.Questionnaire.Answer
export type BaseQuestion = FollowTypes.Questionnaire.BaseQuestion
export type Question = FollowTypes.Questionnaire.Question
export type PercentageScoreConfig = FollowTypes.Questionnaire.PercentageScoreConfig
export type QuestionConfig = FollowTypes.Questionnaire.QuestionConfig
export type MinMaxScoreConfig = FollowTypes.Questionnaire.MinMaxScoreConfig
export type ScoreConfig = FollowTypes.Questionnaire.ScoreConfig
export type Questionnaire = FollowTypes.Questionnaire.Questionnaire

export { QuestionType }

export const QUESTION_TYPE_TO_LABEL: { [type in QuestionType]: string } = {
  SELECT: 'Liste',
  SLIDER: 'Slider',
  TEXT: 'Libre',
  YES_NO: 'QCU',
  RADIO: 'QCU',
  BTN_GROUP: 'QCU',
  BTN_GROUP_MULTIPLE: 'QCM',
  DATE: 'DATE',
}

export const WITH_ANSWERS_QUESTION_TYPES = [
  QuestionType.YesNo,
  QuestionType.Select,
  QuestionType.QCU,
  QuestionType.QCM,
  QuestionType.BtnGroup,
]
export const questionPlaceholder = {
  [QuestionType.Text]: 'Intitulé de la question (Texte libre)',
  [QuestionType.Slider]: 'Intitulé de la question (Slider)',
  [QuestionType.YesNo]: 'Intitulé de la question (Oui / Non)',
  [QuestionType.QCU]: 'Intitulé de la question (QCU)',
  [QuestionType.QCM]: 'Intitulé de la question (QCM)',
  [QuestionType.Date]: 'Intitulé de la question (Date)',
}

export const subQuestionPlaceholder = {
  [QuestionType.Text]: 'Intitulé de la sous-question (Texte libre)',
  [QuestionType.Slider]: 'Intitulé de la sous-question (Slider)',
  [QuestionType.YesNo]: 'Intitulé de la sous-question (Oui / Non)',
  [QuestionType.QCU]: 'Intitulé de la sous-question (QCU)',
  [QuestionType.QCM]: 'Intitulé de la sous-question (QCM)',
  [QuestionType.Date]: 'Intitulé de la sous-question (Date)',
}

export const QuestionLevel = {
  Question: 'Question',
  SubQuestion: 'Sous-question',
}
export type AnswerLight = SafeOmit<BaseAnswer, 'childQuestions'> & {
  childQuestionIds?: number[]
}

export interface QuestionLight extends BaseQuestion {
  answers: AnswerLight[]
}

export interface LightQuestionnaire extends Omit<Questionnaire, 'questions'> {}

export interface QuestionnaireWithScore extends Questionnaire {
  scoreConfig: null
  scoreValue: undefined
}
export interface QuestionnaireWithAnswerValues extends Omit<Questionnaire, 'questions'> {
  questions: ReadonlyArray<Question>
}
export interface QuestionnaireScore extends Questionnaire {
  scoreConfig: ScoreConfig
  scoreValue: number | null
}

export interface WithFilledIndicatorQuestionnaire extends Questionnaire {
  isFilled: boolean
}

export interface ScorePoint {
  scoreId: number
  documentId: number
  points: number | null
}

type QuestionContext = {
  questionnaireId?: number
  documentInstanceId?: number
  documentTemplateId?: number
  displayOrder?: number
  parentAnswerId?: number
}

export function createDefaultQuestion(
  type: QuestionType,
  questionContext: QuestionContext,
): Partial<Omit<Question, 'id'>> {
  const { questionnaireId, documentInstanceId, documentTemplateId, displayOrder, parentAnswerId } =
    questionContext
  const questionnaireIds = typeof questionnaireId === 'number' ? [questionnaireId] : undefined
  const documentIds = typeof documentInstanceId === 'number' ? [documentInstanceId] : undefined
  const documentTemplateIds =
    typeof documentTemplateId === 'number' ? [documentTemplateId] : undefined
  const baseQuestion = {
    title: '',
    type,
    questionnaireIds,
    documentIds,
    documentTemplateIds,
    displayOrder,
    parentAnswerId,
  }
  const createdQuestion =
    type === QuestionType.Slider
      ? {
          ...baseQuestion,
          config: {
            leftValue: 0,
            rightValue: 10,
            stepValue: 1,
            inverted: false,
          },
        }
      : baseQuestion

  return createdQuestion
}

function toPercentage(min: number, max: number, scoreValue: number) {
  return (scoreValue - min) * 100 * (1 / (max - min))
}

export function getColorByScoreConfigAndValue(
  scoreConfig: ScoreConfig,
  scoreValue: number,
): 'green' | 'orange' | 'red' | undefined {
  const scaledValue = scoreConfig.percentage
    ? scoreValue
    : toPercentage(scoreConfig.min, scoreConfig.max, scoreValue)
  let color
  if (scaledValue <= 25) {
    color = scoreConfig.inverted ? 'green' : 'red'
  } else if (scaledValue <= 75) {
    color = 'orange'
  } else {
    color = scoreConfig.inverted ? 'red' : 'green'
  }
  return color
}
