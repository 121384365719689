import React, { FunctionComponent, useRef, useState } from 'react'
import { Button, Input, SelectInput, Switch } from '../../../components/shared'
import { useAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { EditLegalDocumentPanelProps } from './EditLegalDocumentPanel.model'
import { editLegalDocumentPanelAtom } from '../../../state/admin/legalDocument'
import {
  usePostLegalDocument,
  usePutLegalDocument,
} from '../../../hooks/queries/admin/legalDocument'
import {
  LegalDocumentCreatePayload,
  LegalDocumentType,
  LEGAL_DOCUMENT_TYPE_OPTIONS,
} from '../../../model/LegalDocument'
import styles from './EditLegalDocumentPanel.module.scss'
import { Editor, EditorTool, SimpleEditor, ToolbarButton } from '@follow/elfe'
import { useActionDispatch } from '../../../hooks/utils'
import { hideBottomPanel } from '../../../store/ui/bottomPanel'

const editorTools: Array<EditorTool> = ['heading', 'fontSize', 'marks', 'align', 'list', 'undo']

export const EditLegalDocumentPanel: FunctionComponent<EditLegalDocumentPanelProps> = ({
  actions: Actions,
}) => {
  const [inUseLegalDocument, setInUseLegalDocument] = useAtom(editLegalDocumentPanelAtom)
  const editorRef = useRef<Editor>(null)

  const [title, setTitle] = useState<string>(inUseLegalDocument?.title ?? '')
  const [type, setType] = useState<LegalDocumentType>(
    LegalDocumentType[inUseLegalDocument?.type ?? 'gcu'],
  )
  const [needRead, setNeedRead] = useState<boolean>(inUseLegalDocument?.needRead ?? false)
  const [resetReaders, setResetReaders] = useState<boolean>(false)

  const closeBottomPanel = useActionDispatch(hideBottomPanel)

  const { mutate: updateLegalDocument } = usePutLegalDocument()
  const { mutate: createLegalDocument } = usePostLegalDocument()

  const handleSubmit = () => {
    if (!editorRef.current) return

    const serializedHtml = editorRef.current.getHTML()
    const contentToSend = `<div>${serializedHtml}</div>`

    const updates: LegalDocumentCreatePayload = {
      title,
      content: contentToSend,
      type,
      needRead,
    }

    if (inUseLegalDocument) {
      updateLegalDocument(
        {
          id: inUseLegalDocument.id,
          variables: { ...updates, resetReaders: resetReaders },
        },
        { onSuccess: handleClose },
      )
    } else {
      createLegalDocument(updates, { onSuccess: handleClose })
    }
  }

  const handleClose = () => {
    setInUseLegalDocument(RESET)
    closeBottomPanel()
  }

  const handleImage = (editor: Editor) => {
    const src = window.prompt("URL de l'image")
    if (src) {
      editor.chain().focus().setImage({ src }).run()
    }
  }

  return (
    <div className={styles.container}>
      <Actions>
        <Button theme="dark" label="Annuler" onClick={handleClose} />
        <Button theme="primary" label="Sauvegarder" onClick={handleSubmit} />
      </Actions>
      <div className={styles.wrapper}>
        <div className={styles.headerPanel}>
          <Input
            name="title"
            label="Titre"
            autocomplete="off"
            placeholder="Saisir un titre"
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
            colorPreset="light"
          />
          <SelectInput
            placeholder="Sélectionner un type"
            title="Type de document légal"
            icon={undefined}
            colorPreset="light"
            options={LEGAL_DOCUMENT_TYPE_OPTIONS}
            value={LEGAL_DOCUMENT_TYPE_OPTIONS.find(({ value }) => value === type)}
            onSelect={(selected) => {
              if (selected) {
                setType(selected?.value)
              }
            }}
          />
          <Switch
            name="Lecture obligatoire"
            checked={needRead}
            onChange={(value) => {
              if (!value) {
                setResetReaders(false)
              }
              setNeedRead(value)
            }}
          ></Switch>
          <Switch
            name="Forcer relecture"
            disabled={!needRead}
            checked={resetReaders}
            onChange={(value) => setResetReaders(value)}
          ></Switch>
        </div>
        <p className={styles.warningText}>
          On ne peut pas créer plusieurs documents avec le même type. Il ne peut y avoir par exemple
          qu'un document de type CGU.
        </p>
        <div className={styles.editorWrapper}>
          <SimpleEditor
            initialContent={inUseLegalDocument?.content ?? ''}
            editorRef={editorRef}
            key={inUseLegalDocument?.id}
            extraTools={({ editor }) => (
              <ToolbarButton
                title="Ajouter une image"
                icon="Image"
                onClick={() => handleImage(editor)}
              />
            )}
            tools={editorTools}
          />
        </div>
      </div>
    </div>
  )
}
