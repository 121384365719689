import { put, take, takeEvery, select } from 'redux-saga/effects'

import { uiSagasFactory } from '../../../restux/ui/restuxUiSagas.factory'
import { generateIdentifier, identifierMatchScope } from '../../../restux/restux.utilities'
import { RESTUX_IDENTIFIER } from '../../../restux.identifier'
import { RESTUX_UI_IDENTIFIER } from '../../../restux/ui'

import { medicalEventDocumentInstancesActions } from './medicalEventDocumentInstances.actions'
import {
  medicalEventDocumentInstancesConfig,
  UiMedicalEventDocumentInstanceActionTypes,
} from './medicalEventDocumentInstances.model'
import { questionsActions } from '../../../cache/questions'
import { documentInstancesActions } from '../../../cache/documentInstances'
import { createDefaultQuestion } from '../../../../model/Questionnaire'
import { inUseMedicalEventDocumentSelector } from './medicalEventDocumentInstances.selectors'
import {
  medicalEventContentUiActions,
  SelectedContent,
  SelectedContentType,
} from '../medicalEventContent'

const paginationSagas = uiSagasFactory(medicalEventDocumentInstancesConfig)

function* addExistingQuestionToInUseDocumentInstanceWorker({
  documentInstanceId,
  selectedQuestion,
  onQuestionAddedToDocumentInstance,
}: ReturnType<
  typeof medicalEventDocumentInstancesActions.addExistingQuestionToInUseDocumentInstance
>) {
  const inUseDocumentInstance: ReturnType<typeof inUseMedicalEventDocumentSelector> = yield select(
    inUseMedicalEventDocumentSelector,
  )
  if (
    inUseDocumentInstance &&
    inUseDocumentInstance.id === documentInstanceId &&
    inUseDocumentInstance.type === 'farte'
  ) {
    onQuestionAddedToDocumentInstance(selectedQuestion)
  } else {
    throw new Error(`Inconsitence entre le document cible et celui en cours d'edition`)
  }
}

function* addExistingQuestionToInUseDocumentInstanceWatcher() {
  yield takeEvery(
    UiMedicalEventDocumentInstanceActionTypes.ADD_EXISTING_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE,
    addExistingQuestionToInUseDocumentInstanceWorker,
  )
}

function* addQuestionnaireToInUseDocumentInstanceWorker({
  documentInstanceId,
  selectedQuestionnaire,
  onQuestionnaireAdded,
}: ReturnType<
  typeof medicalEventDocumentInstancesActions.addQuestionnaireToInUseDocumentInstance
>) {
  const inUseDocumentInstance: ReturnType<typeof inUseMedicalEventDocumentSelector> = yield select(
    inUseMedicalEventDocumentSelector,
  )
  if (
    inUseDocumentInstance &&
    inUseDocumentInstance.id === documentInstanceId &&
    inUseDocumentInstance.type === 'farte'
  ) {
    onQuestionnaireAdded(selectedQuestionnaire)
  } else {
    throw new Error(`Inconsitence entre le document cible et celui en cours d'edition`)
  }
}

function* addQuestionnaireToInUseDocumentInstanceWatcher() {
  yield takeEvery(
    UiMedicalEventDocumentInstanceActionTypes.ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_INSTANCE,
    addQuestionnaireToInUseDocumentInstanceWorker,
  )
}

function* addQuestionToInUseDocumentInstanceWorker({
  documentInstanceId,
  questionType,
  onQuestionAddedToDocumentInstance,
}: ReturnType<typeof medicalEventDocumentInstancesActions.addQuestionToInUseDocumentInstance>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.addQuestionToDocumentInstanceSaga)
  yield put(
    questionsActions.actions.apiCreateItem(
      createDefaultQuestion(questionType, { documentInstanceId }),
      {
        identifier: taskIdentifier,
      },
    ),
  )
  const { item }: ReturnType<typeof questionsActions.actions.storeSetItemDetails> = yield take(
    questionsActions.types.STORE_SET_ITEM_DETAILS,
  )
  onQuestionAddedToDocumentInstance(item)
}

function* addQuestionToInUseDocumentInstanceWatcher() {
  yield takeEvery(
    UiMedicalEventDocumentInstanceActionTypes.ADD_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE,
    addQuestionToInUseDocumentInstanceWorker,
  )
}

function* onDocumentInstanceLoadedWorker({
  item,
  identifier,
}: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails>) {
  const inUseDocumentInstance: ReturnType<typeof inUseMedicalEventDocumentSelector> = yield select(
    inUseMedicalEventDocumentSelector,
  )
  if (
    identifierMatchScope(identifier, RESTUX_UI_IDENTIFIER.useId) &&
    inUseDocumentInstance &&
    inUseDocumentInstance.id === item.id &&
    inUseDocumentInstance.type === 'farte' &&
    !inUseDocumentInstance.answerValues.find((answer) => answer.sharedFromAnswerValueId === null)
  ) {
    // Logique de selection par defaut
    // Autres variables, s'il y des variables question
    // Le premier questionnaires, s'il y en a
    // Le document en l'absence de variables et questionnaires.
    const currentLocation = new URLSearchParams(window.location.search)
    const contentType = currentLocation.get('contentType')
    let filter: SelectedContent = { type: SelectedContentType.EDITOR }
    switch (contentType) {
      case SelectedContentType.VARIABLES: {
        filter = { type: SelectedContentType.VARIABLES }
        break
      }
      case SelectedContentType.QUESTIONNAIRE: {
        const questionnaireId = currentLocation.get('questionnaireId')
        if (questionnaireId) {
          filter = {
            type: SelectedContentType.QUESTIONNAIRE,
            questionnaireId: parseInt(questionnaireId, 10),
          }
        }
        break
      }
      case SelectedContentType.PRESCRIPTION: {
        const prescriptionId = currentLocation.get('prescriptionId')
        const intervalId = currentLocation.get('intervalId')
        if (prescriptionId) {
          filter = {
            type: SelectedContentType.PRESCRIPTION,
            prescriptionId,
            ...(intervalId !== null && { intervalId }),
          }
        }
        break
      }
      default: {
        const { questionnaires, variables } = inUseDocumentInstance
        if (variables.length > 0) {
          filter = { type: SelectedContentType.VARIABLES }
        } else if (questionnaires.length > 0) {
          filter = {
            type: SelectedContentType.QUESTIONNAIRE,
            questionnaireId: questionnaires[0].id,
          }
        } else {
          filter = { type: SelectedContentType.EDITOR }
        }
        break
      }
    }
    yield put(medicalEventContentUiActions.selectMedicalEventContent(filter))
  }
}

function* onDocumentInstanceLoadedWatcher() {
  yield takeEvery(
    documentInstancesActions.types.STORE_SET_ITEM_DETAILS,
    onDocumentInstanceLoadedWorker,
  )
}

export const medicalEventDocumentInstancesSagas = {
  ...paginationSagas,
  addExistingQuestionToInUseDocumentInstanceWatcher,
  addQuestionnaireToInUseDocumentInstanceWatcher,
  addQuestionToInUseDocumentInstanceWatcher,
  onDocumentInstanceLoadedWatcher,
}
