import { ComponentType } from 'react'
import { FieldValue, DocumentContentType } from '../../../model/Document'
import { CustomFile } from '../../../model/File'

export type FocusedInput = {
  id: string
  name: string
}

export enum BackgroundColors {
  blue = 'bg-shades-7',
  white = 'bg-shades-white',
  none = 'bg-transparent',
}

export interface FileViewerProps {
  file: CustomFile | null
  fieldValues?: FieldValue[]
  isPreview?: boolean
  isInteractive?: boolean
  contentType?: DocumentContentType
  readOnly?: boolean
  enableScrollButton?: boolean
  scale?: number
  autoScale?: boolean
  backgroundColor?: BackgroundColors
  actions?: ComponentType
  title?: ComponentType
  closeAction?: ComponentType
  onRequestClose?: () => void
  onFocusInput?: (input: FocusedInput) => void
  onChange?: (value: FieldValue) => void
  onBlur?: () => void
  onInsertVariable?: () => void
  onClear?: () => void
  onExitFullScreen?: () => void
  onEnterFullScreen?: () => void
}
