import { DangerousHtml } from '@follow/cdk'
import { debounce } from 'lodash'
import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEmptyCkEditorValue } from '../../../editor/CKEditor/CKEditor.model'
import { hasDocuments } from '../../../../pages/patient'
import { AccordionItem } from '../../../shared'
import { PatientTransversalNavigationMedicalEventWithoutDoc } from './PatientTransversalNavigationMedicalEventNoResults'
import { PatientTransversalNavigationNextEventButton } from './PatientTransversalNavigationNextEventButton'
import { PatientTransversalNavigationEventDetailsProps } from './PatientTransversalNavigationEventDetails.model'
import { PatientTransversalNavigationEventDocument } from './PatientTransversalNavigationEventDocument'
import { PatientTransversalNavigationEventFile } from './PatientTransversalNavigationEventFile'
import { PatientTransversalNavigationEventHeader } from './PatientTransversalNavigationEventHeader'
import {
  scrolledItemIdAtom,
  scrollHighlightStatusAtom,
  ScrollHighlightStatus,
} from '../../../../state/navTrans'
import { getNavBarWithEventHeaderHeight } from '../utils'
import styles from './PatientTransversalNavigationEventDetails.module.scss'

export const PatientTransversalNavigationEventDetails = ({
  event,
  hasNextEvent,
  selectedDocOrFileId,
  firstDocOrFileId,
  onNextEventClick,
}: PatientTransversalNavigationEventDetailsProps) => {
  const [expand, setExpand] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const scrolledDocOrFileId = useAtomValue(scrolledItemIdAtom)
  const setScrolledDocOrFileId = useSetAtom(scrolledItemIdAtom)
  const scrollHighlightStatus = useAtomValue(scrollHighlightStatusAtom)
  const setScrollHighlightStatus = useSetAtom(scrollHighlightStatusAtom)

  const handleToggleObservations = useCallback(() => setExpand((prev) => !prev), [])
  const docsAndFilesContainerRef = useRef<HTMLDivElement | null>(null)

  const hasMultipleDocOrFiles = useMemo(() => {
    const { files, documents } = event
    return files.length + documents.length > 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.id])

  const docsAndFiles = useMemo(
    () => [...event.documents, ...event.files],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [event.id],
  )

  const currentItemIndex = useMemo(
    () => docsAndFiles.findIndex((item) => item.id === scrolledDocOrFileId),
    [docsAndFiles, scrolledDocOrFileId],
  )

  const isLastElementActive = useMemo(
    () => currentItemIndex === docsAndFiles.length - 1,
    [currentItemIndex, docsAndFiles.length],
  )

  const isFirstElementActive = useMemo(() => currentItemIndex === 0, [currentItemIndex])

  const handleScrollAfterBottom = useCallback(
    (scrollingDown: boolean) => {
      const bottomReached =
        Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

      if (bottomReached && scrollingDown && !isLastElementActive && currentItemIndex !== -1) {
        setScrollHighlightStatus(ScrollHighlightStatus.DISABLED)
        setScrolledDocOrFileId(docsAndFiles[currentItemIndex + 1].id)
      }

      if (!scrollingDown && scrollHighlightStatus === ScrollHighlightStatus.DISABLED) {
        setScrollHighlightStatus(ScrollHighlightStatus.ENABLED)
      }
    },
    [
      currentItemIndex,
      docsAndFiles,
      isLastElementActive,
      scrollHighlightStatus,
      setScrollHighlightStatus,
      setScrolledDocOrFileId,
    ],
  )

  const handlePageWithoutScroll = useCallback(
    (scrollingDown: boolean) => {
      if (scrollingDown) {
        !isLastElementActive && setScrolledDocOrFileId(docsAndFiles[currentItemIndex + 1].id)
      } else {
        !isFirstElementActive && setScrolledDocOrFileId(docsAndFiles[currentItemIndex - 1].id)
      }
    },
    [
      currentItemIndex,
      docsAndFiles,
      isFirstElementActive,
      isLastElementActive,
      setScrolledDocOrFileId,
    ],
  )

  const handleFirstRender = useCallback(() => {
    setIsFirstRender(false)
    document.removeEventListener('wheel', handleFirstRender)
  }, [])

  const allowWaypointDetection = useMemo(
    () => !isFirstRender && scrollHighlightStatus === ScrollHighlightStatus.ENABLED,
    [isFirstRender, scrollHighlightStatus],
  )

  useEffect(() => {
    if (isFirstRender) {
      document.addEventListener('wheel', handleFirstRender)
    }
    return () => document.removeEventListener('wheel', handleFirstRender)
  }, [event.id, handleFirstRender, isFirstRender])

  const handleScroll = useMemo(
    () =>
      debounce((e: WheelEvent) => {
        const scrollingDown = e.deltaY > 0

        const pageWithoutScroll =
          docsAndFilesContainerRef.current!.scrollHeight <
          document.documentElement.scrollHeight - getNavBarWithEventHeaderHeight()

        pageWithoutScroll
          ? handlePageWithoutScroll(scrollingDown)
          : handleScrollAfterBottom(scrollingDown)
      }, 20),
    [handlePageWithoutScroll, handleScrollAfterBottom],
  )

  useEffect(() => {
    const accurateContainerRef = docsAndFilesContainerRef.current
    if (!hasMultipleDocOrFiles || !accurateContainerRef) return

    accurateContainerRef.addEventListener('wheel', handleScroll)

    return () => {
      accurateContainerRef.removeEventListener('wheel', handleScroll)
    }
  }, [event.id, handleScroll, hasMultipleDocOrFiles])

  return (
    <>
      <PatientTransversalNavigationEventHeader event={event} />
      <div className={styles.eventContainer}>
        {hasDocuments(event) ? (
          <div ref={docsAndFilesContainerRef} className={styles.eventDetailsContainer}>
            {!isEmptyCkEditorValue(event.observation) && (
              <AccordionItem
                renderLabel={() => <h4 className={styles.eventObservationsTitle}>Observations</h4>}
                displayToggleButton
                open={expand}
                onOpenClose={handleToggleObservations}
                theme="filled"
              >
                <DangerousHtml html={event.observation} />
              </AccordionItem>
            )}
            {event.documents.map((doc) => (
              <PatientTransversalNavigationEventDocument
                id={doc.id}
                key={doc.id}
                isEventEditable={event.isEditable}
                isSelected={selectedDocOrFileId === doc.id}
                isFirstDocOrFile={doc.id === firstDocOrFileId}
                allowWaypointDetection={allowWaypointDetection}
              />
            ))}
            {event.files.map((file) => (
              <PatientTransversalNavigationEventFile
                id={file.id}
                key={file.id}
                isEventEditable={event.isEditable}
                isSelected={selectedDocOrFileId === file.id}
                isFirstDocOrFile={file.id === firstDocOrFileId}
                allowWaypointDetection={allowWaypointDetection}
              />
            ))}
            {hasNextEvent && (
              <div className={styles.nextEventButton}>
                <PatientTransversalNavigationNextEventButton onNextEventClick={onNextEventClick} />
              </div>
            )}
          </div>
        ) : (
          <PatientTransversalNavigationMedicalEventWithoutDoc
            hasNextEvent={hasNextEvent}
            onNextEventClick={onNextEventClick}
          />
        )}
      </div>
    </>
  )
}
