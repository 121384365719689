import { call, put, race, select, take } from 'redux-saga/effects'
import { getSephiraVitalCardInfos } from './sephira.api'
import { Patient, Sex, Title } from '../../../../model/Patient'
import { ApiResponse } from 'apisauce'
import {
  SEPHIRA_VITAL_CARD_ERROR_MAP,
  SephiraVitalCardResponse,
  VitalCardContentGroupBeneficiary,
  VitalCardContentGroupGlobal,
  SEPHIRA_BDAY_FORMAT,
} from './sephira.model'
import { addError } from '../../../message'
import { vitalCardActions } from '../vitalCard.actions'
import { VitalCardActionTypes } from '../vitalCard.model'
import { vitalCardCpsPincodeSelector } from '../vitalCard.selector'
import { AnyAction } from 'redux'
import { format, isValid, parse } from 'date-fns'
import { DATE_FORMAT_API } from '../../../../misc/date.utilities'

export function* readVitalCardInfosFromSephira(): Generator<Array<Partial<Patient>>> | null {
  const cpsPinCode: string | null = yield select(vitalCardCpsPincodeSelector)

  const { data, ok }: ApiResponse<SephiraVitalCardResponse> = yield call(
    getSephiraVitalCardInfos,
    new Date(),
    cpsPinCode ?? undefined,
  )

  // -- Gestion d'erreur et de saisie de code PIN

  if (!data || !ok) {
    yield put(addError('Erreur lors de la lecture de carte vitale'))
    return null
  }

  // `call` indique une erreur ou un warning
  if (data.report.call) {
    console.warn('Sephira vital card read : ', data.report.call, data.report.exit)

    const messageFromErrorMap = SEPHIRA_VITAL_CARD_ERROR_MAP[data.report.call]
    if (messageFromErrorMap) {
      yield put(addError('Erreur lors de la lecture de carte vitale', messageFromErrorMap))
      yield put(vitalCardActions.setCpsPincodeModalOpen(false))
    }

    // Erreurs en cas de code pin CPS manquants/incorrects
    if ([65313, 96, 61443].includes(data.report.call)) {
      yield put(vitalCardActions.setCpsPincodeModalOpen(true))

      const { pinHasBeenSet } = yield race({
        pinHasBeenSet: take(VitalCardActionTypes.SET_CPS_PINCODE),
        cancel: take(
          (action: AnyAction) =>
            action.type === VitalCardActionTypes.CPS_PINCODE_MODAL_OPEN && action.open === false,
        ),
      })

      if (pinHasBeenSet) {
        return yield* readVitalCardInfosFromSephira()
      } else {
        return null
      }
    }

    if (data.report.exit) {
      if (!messageFromErrorMap) {
        yield put(
          addError(
            'Erreur lors de la lecture de carte vitale',
            `Une erreur inconnue est survenue (${data.report.call}-${data.report.exit})`,
          ),
        )
      }

      return null
    }
  }

  // --- Gestion du retour si lecture réussie
  // Se référer à la page 57 de la documentation SESAM-vital

  yield put(vitalCardActions.setCpsPincodeModalOpen(false))

  // id: 104 => Bénéficiaire
  const rawBeneficiaries =
    data.groups_o?.filter((entry): entry is VitalCardContentGroupBeneficiary => entry.id === 104) ??
    []
  // id: 101 => Données Assuré
  const globalData = data.groups_o
    ?.filter((entry): entry is VitalCardContentGroupGlobal => entry.id === 101)
    .at(0)

  const nir = globalData ? globalData.content.ass_nir + globalData.content.ass_cle_nir : undefined

  try {
    const beneficiaries: Array<Partial<Patient>> = rawBeneficiaries.map(
      ({ content: { dob_name, dob_surname, dob_usual, dob_bday, dob_qualite_beneficiaire } }) => {
        const birthdate = parse(dob_bday, SEPHIRA_BDAY_FORMAT, new Date())
        const sex = nir?.charAt(0) === '1' ? Sex.MALE : Sex.FEMALE
        const title = nir?.charAt(0) === '1' ? Title.MR : Title.MS
        const isCardOwner = dob_qualite_beneficiaire === '0'

        return {
          inseeNumber: nir,
          birthPlaceCode: nir?.replace(/\s/g, '').substring(5, 10),
          birthFirstName: dob_surname,
          birthLastName: dob_name ? dob_name : dob_usual,
          usedLastName: dob_name ? dob_usual : undefined,
          birthDate: isValid(birthdate) ? format(birthdate, DATE_FORMAT_API) : '',
          sex: isCardOwner ? sex : undefined,
          title: isCardOwner ? title : undefined,
        }
      },
    )
    return beneficiaries
  } catch (e) {
    yield put(
      addError(
        'Une erreur est survenue',
        "Impossible d'interpréter les données lues sur la carte vitale",
      ),
    )
    console.error(e)
  }
}
