export interface CKEditorProps {
  style?: React.CSSProperties
  loading?: boolean
  onChange?: (text: string) => void
  initialValue?: string
  theme?: 'white' | 'purple'
  className?: string
  readonly?: boolean
}

export const ckEditorEmptyText = '<p>&nbsp;</p>'

export const isEmptyCkEditorValue = (content: string | null): boolean =>
  content === null || content === '' || content === ckEditorEmptyText
