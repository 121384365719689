import { DocumentTemplate } from '../../../model/DocumentTemplate'
import { QuestionType, Question, LightQuestionnaire } from '../../../model/Questionnaire'
import { uiActionsFactory } from '../../restux/ui/restuxUiActions.factory'
import {
  DocumentTemplateCreateResource,
  managerDocumentTemplatesConfig,
  UiManagerDocumentTemplateActionTypes,
} from './managerDocumentTemplates.model'

const uiActions = uiActionsFactory(managerDocumentTemplatesConfig).actions

const addExistingQuestionToInUseDocumentTemplate = (
  documentTemplateId: number,
  selectedQuestion: Question,
  onQuestionAddedToDocumentTemplate: (question: Question) => void,
) => ({
  type: UiManagerDocumentTemplateActionTypes.ADD_EXISTING_QUESTION_TO_DOCUMENT_IN_USE_TEMPLATE,
  documentTemplateId,
  selectedQuestion,
  onQuestionAddedToDocumentTemplate,
})

const addQuestionnaireToInUseDocumentTemplate = (
  documentTemplateId: number,
  selectedQuestionnaire: LightQuestionnaire,
  onQuestionnaireAdded: (questionnaire: LightQuestionnaire) => void,
) => ({
  type: UiManagerDocumentTemplateActionTypes.ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_TEMPLATE,
  documentTemplateId,
  selectedQuestionnaire,
  onQuestionnaireAdded,
})

const addQuestionToInUseDocumentTemplate = (
  documentTemplateId: number,
  questionType: QuestionType,
  title: string,
  onQuestionAddedToDocumentTemplate: (question: Question) => void,
) => ({
  type: UiManagerDocumentTemplateActionTypes.ADD_QUESTION_TO_IN_USE_DOCUMENT_TEMPLATE,
  documentTemplateId,
  title,
  questionType,
  onQuestionAddedToDocumentTemplate,
})

const createDocumentTemplate = (templateResources: DocumentTemplateCreateResource) => ({
  type: UiManagerDocumentTemplateActionTypes.CREATE,
  templateResources,
})

const updateDocumentTemplate = (
  documentTemplateId: number,
  documentTemplateUpdates: Partial<DocumentTemplate>,
) => ({
  type: UiManagerDocumentTemplateActionTypes.UPDATE,
  documentTemplateId,
  documentTemplateUpdates,
})

export const managerDocumentTemplatesActions = {
  ...uiActions,
  addExistingQuestionToInUseDocumentTemplate,
  addQuestionnaireToInUseDocumentTemplate,
  addQuestionToInUseDocumentTemplate,
  createDocumentTemplate,
  updateDocumentTemplate,
}
