import { adminAnalyticsSagas } from './adminAnalytics/adminAnalytics.sagas'
import { adminUsersSagas } from './adminUsers/adminUsers.sagas'
import { bottomPanelBaseVariablesSagas } from './bottomPanelBaseVariables/bottomPanelBaseVariables.sagas'
import { bottomPanelContactsSagas } from './bottomPanelContacts/bottomPanelContacts.sagas'
import { bottomPanelDocumentHistorysSagas } from './bottomPanelDocumentHistory/bottomPanelDocumentHistory.sagas'
import { bottomPanelDocumentTemplatesSagas } from './bottomPanelDocumentTemplates/bottomPanelDocumentTemplates.sagas'
import { bottomPanelDrugsSagas } from './bottomPanelDrugs/bottomPanelDrugs.sagas'
import { bottomPanelQuestionSagas } from './bottomPanelQuestions/bottomPanelQuestions.sagas'
import { managerDocumentTemplatesSagas } from './managerDocumentTemplates/managerDocumentTemplates.sagas'
import { medicalEventDocumentInstancesSagas } from './medicalEvents/medicalEventDocumentInstances'
import { medicalEventsFilesSagas } from './medicalEvents/medicalEventFiles/medicalEventFiles.sagas'
import { medicalEventQuestionnaires } from './medicalEvents/medicalEventContent/medicalEventContent.sagas'
import { medicalEventsSagas } from './medicalEvents/medicalEvents.sagas'
import { questionnaireComputedValuesSages } from './questionnaireComputedValues/questionnaireComputedValues.sagas'
import { contactsSagas } from './contacts/contacts.sagas'
import { documentContextualMenuUiSagas } from './documentContextualMenu/documentContextualMenu.sagas'
import { DocumentSagas } from './document/document.saga'
import { atcClassificationSagas } from './atcClassification'
import { citiesSagas } from './cities/cities.sagas'
import { bottomPanelMailEditor } from './bottomPanelMailEditor/bottomPanelMailEditor.sagas'
import { mailContactsSagas } from './mailContacts/mailContacts.sagas'
import { bottomPanelPosologySagas } from './bottomPanelPosology'
import { bottomPanelTreatmentsHistorySagas } from './bottomPanelTreatmentsHistory'
import { patientCurrentTreatmentsSagas } from './patientCurrentTreatments/patientCurrentTreatments.sagas'
import { patientTreatmentsSagas } from './patientTreatments/patientTreatments.sagas'
import { uiDocumentAlertsSagas } from './documentAlerts'
import { bottomPanelManualPrescriptionSagas } from './bottomPanelManualPrescription/bottomPanelManualPrescription.sagas'
import { quoteLineSagas } from './quoteLine/quoteLine.sagas'
import { patientManualPrescriptionsSagas } from './patientManualPrescriptions/patientManualPrescriptions.sagas'
import { bottomPanelResourceHistorySagas } from './bottomPanelResourceHistory/bottomPanelResourceHistory.sagas'
import { resourceHistorySagas } from './resourceHistory'
import { countriesSagas } from './countries/countries.sagas'
import { mailContactSearchSagas } from './mailContactSearch/mailContactSearch.sagas'
import { documentPosologySagas } from './documentPosology'

export const uiSagas = [
  ...Object.values(adminAnalyticsSagas),
  ...Object.values(atcClassificationSagas),
  ...Object.values(bottomPanelBaseVariablesSagas),
  ...Object.values(bottomPanelContactsSagas),
  ...Object.values(bottomPanelDocumentHistorysSagas),
  ...Object.values(bottomPanelDocumentTemplatesSagas),
  ...Object.values(bottomPanelDrugsSagas),
  ...Object.values(bottomPanelManualPrescriptionSagas),
  ...Object.values(bottomPanelQuestionSagas),
  ...Object.values(bottomPanelResourceHistorySagas),
  ...Object.values(bottomPanelMailEditor),
  ...Object.values(bottomPanelPosologySagas),
  ...Object.values(bottomPanelTreatmentsHistorySagas),
  ...Object.values(documentPosologySagas),
  ...Object.values(contactsSagas),
  ...Object.values(citiesSagas),
  ...Object.values(countriesSagas),
  ...Object.values(DocumentSagas),
  ...Object.values(documentContextualMenuUiSagas),
  ...Object.values(uiDocumentAlertsSagas),
  ...Object.values(mailContactsSagas),
  ...Object.values(managerDocumentTemplatesSagas),
  ...Object.values(patientCurrentTreatmentsSagas),
  ...Object.values(patientManualPrescriptionsSagas),
  ...Object.values(patientTreatmentsSagas),
  ...Object.values(adminUsersSagas),
  ...Object.values(medicalEventDocumentInstancesSagas),
  ...Object.values(medicalEventsFilesSagas),
  ...Object.values(medicalEventQuestionnaires),
  ...Object.values(medicalEventsSagas),
  ...Object.values(questionnaireComputedValuesSages),
  ...Object.values(quoteLineSagas),
  ...Object.values(resourceHistorySagas),
  ...Object.values(mailContactSearchSagas),
]
