import { FunctionComponent } from 'react'
import { QuestionType, BaseQuestion, createDefaultQuestion } from '../../../model/Questionnaire'
import { QuestionList, QuestionnaireMode, QuestionnaireAddQuestion } from '..'
import { QuestionnaireContentProps } from './QuestionnaireContent.model'

export const QuestionnaireContent: FunctionComponent<QuestionnaireContentProps> = ({
  questionnaire,
  onEditQuestion,
  moveQuestion,
  createQuestion,
  onDeleteQuestion,
  onDeleteSubQuestion,
}) => {
  const handleAddQuestion = (type: QuestionType) => {
    createQuestion(
      createDefaultQuestion(type, {
        questionnaireId: questionnaire.id,
        displayOrder: questionnaire.questions.length + 1,
      }),
    )
  }

  const handleAddSubQuestion = (
    type: QuestionType,
    parentAnswerId: number,
    displayOrder: number,
  ) => {
    createQuestion(
      createDefaultQuestion(type, {
        questionnaireId: questionnaire.id,
        displayOrder,
        parentAnswerId,
      }),
    )
  }

  const handleMoveQuestion = (questionId: number, newIndex: number) => {
    const questionIds = questionnaire.questions.map(({ id }) => id)
    const previousIndex = questionIds.findIndex((id) => id === questionId)
    const actualDestinationQuestionId = questionIds.find((id, index) => index === newIndex)

    if (!actualDestinationQuestionId) {
      throw new Error('This should never happen. Algorithm is wrong')
    }
    if (previousIndex === newIndex) {
      // Si rien n'a bougé. On ne fait rien
      return
    }
    const newQuestionsIds = questionIds.map((id, index) => {
      if (index === previousIndex) {
        return actualDestinationQuestionId
      } else if (index === newIndex) {
        return questionId
      }
      return id
    })
    moveQuestion(questionnaire.id, newQuestionsIds)
  }

  const editHandler = questionnaire.isEditable
    ? (question: BaseQuestion) => onEditQuestion(question.id)
    : undefined
  const addSubQuestionHandler = questionnaire.isEditable ? handleAddSubQuestion : undefined
  const deleteHandler = questionnaire.isDeletable
    ? (question: BaseQuestion) => onDeleteQuestion(question.id, questionnaire.id)
    : undefined
  const deleteSubQuestionHandler = questionnaire.isDeletable
    ? (question: BaseQuestion) => onDeleteSubQuestion(question.id, questionnaire.id)
    : undefined
  const onMoveQuestion = questionnaire.isEditable ? handleMoveQuestion : undefined

  return (
    <QuestionList
      mode={QuestionnaireMode.managerEdit}
      questions={questionnaire.questions}
      onAddSubQuestion={addSubQuestionHandler}
      onDelete={deleteHandler}
      onDeleteSubQuestion={deleteSubQuestionHandler}
      onEdit={editHandler}
      onMoveQuestion={onMoveQuestion}
      renderAfter={
        questionnaire.isEditable && <QuestionnaireAddQuestion onAddQuestion={handleAddQuestion} />
      }
      disabled={true}
      isReadonly={!questionnaire.isEditable}
    />
  )
}
