import { Profession } from '../../../model/Professions'

export enum professionsActionsType {
  GET_LIST_OF_PROFESSION = 'GET_LIST_OF_PROFESSION',
  STORE_LIST_OF_PROFESSIONS = 'STORE_LIST_OF_PROFESSIONS',
}

export interface ProfessionsState {
  professionsList: Array<Profession>
}
