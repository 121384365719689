import { Reducer } from 'redux'
import { createReducer } from 'redux-starter-kit'
import { specialitiesActions } from './specialities.actions'
import { SpecialitiesState, SpecialityActionsType } from './specialities.model'

const SPECIALITIES_DEFAULT_STATE: SpecialitiesState = {
  specialitiesList: [],
}

const ACTION_HANDLERS = {
  [SpecialityActionsType.STORE_LIST_OF_SPECIALITIES]: (
    state: SpecialitiesState = SPECIALITIES_DEFAULT_STATE,
    { specialitiesList }: ReturnType<typeof specialitiesActions.storeSpecialitiesList>,
  ) => ({
    ...state,
    specialitiesList,
  }),
}

export const specialitiesReducer: Reducer = createReducer<SpecialitiesState>(
  SPECIALITIES_DEFAULT_STATE,
  ACTION_HANDLERS,
)
