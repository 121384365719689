import { Fragment, FunctionComponent, useCallback, useMemo } from 'react'
import { MailAttachmentsProps } from './MailAttachments.model'
import { IconsType, MenuChip, TooltipWrapper } from '../../../shared'
import { isPdfExtension } from '../../../../misc/files.utilities'
import { FileViewer } from '../../../file'
import { isDefined } from '../../../../misc/functions.utilities'

const getAttachementIcon = (contentType: string): IconsType => {
  if (contentType.includes('image')) return 'image'
  else if (contentType.includes('pdf')) return 'fileInstance'
  return 'documentDefault'
}

export const MailAttachments: FunctionComponent<MailAttachmentsProps> = ({
  mailId,
  attachments,
  selectedAttachmentId,
  selectedFile,
  setSelectedAttachmentId,
  handleGetAttachmentFile,
  handleDownloadOrPrintFile,
  onClear,
}) => {
  const partsWithCdaContent = useMemo(
    () => attachments.filter(({ cdaContent }) => isDefined(cdaContent) && cdaContent.length > 0),
    [attachments],
  )

  const computeAdditionalInfos = useCallback((isNew?: boolean, isOld?: boolean) => {
    if (isNew) return `Nouvelle version d'un compte-rendu de biologie déjà classé`
    if (isOld) return `Une version plus récente de ce compte-rendu de biologie est disponible`
    return undefined
  }, [])

  return (
    <div className="my-4 p-4 bg-shades-7 rounded-xl">
      <span className="text-shades-4 text-xs font-medium">Pièce(s) Jointe(s)</span>
      <div className="flex flex-wrap">
        {attachments.map((attachment) => (
          <div className="m-2" key={attachment.id}>
            <MenuChip
              label={attachment.visibleName}
              icon={attachment.contentType ? getAttachementIcon(attachment.contentType) : undefined}
              selected={attachment.id === selectedAttachmentId}
              collapsed={attachment.id !== selectedAttachmentId}
              onClick={() => {
                setSelectedAttachmentId(attachment.id)
                handleGetAttachmentFile(attachment.id, mailId)
              }}
              downloadConfig={
                selectedFile ? () => handleDownloadOrPrintFile(selectedFile, 'download') : undefined
              }
              onPrint={
                selectedFile && isPdfExtension(selectedFile.extension)
                  ? () => handleDownloadOrPrintFile(selectedFile, 'print')
                  : undefined
              }
            />
          </div>
        ))}
      </div>
      {partsWithCdaContent.map(
        ({ cdaContent, visibleName, id: partId }) =>
          cdaContent && (
            <Fragment key={partId}>
              <span className="text-shades-4 text-xs font-medium">
                Contenu extrait de l'archive ({visibleName})
              </span>
              <div className="flex flex-wrap">
                {cdaContent.map(
                  ({ id, visibleName, type, critical, isNewVersion, isOldVersion }) => (
                    <div className="m-2" key={id}>
                      <TooltipWrapper
                        content={<p>Comporte un/des résultat(s) critique(s)</p>}
                        display={critical}
                        size="small"
                        pointerDirection="left"
                      >
                        <div>
                          <MenuChip
                            colorPreset={critical ? 'danger' : undefined}
                            additionalInfos={computeAdditionalInfos(isNewVersion, isOldVersion)}
                            label={visibleName}
                            icon={type === 'biologicalReport' ? 'microscope' : 'documentDefault'}
                            selected={id === selectedAttachmentId}
                            collapsed={id !== selectedAttachmentId}
                            onClick={() => {
                              setSelectedAttachmentId(id)
                              handleGetAttachmentFile(partId, mailId, id)
                            }}
                          />
                        </div>
                      </TooltipWrapper>
                    </div>
                  ),
                )}
              </div>
            </Fragment>
          ),
      )}
      {selectedFile ? <FileViewer file={selectedFile} autoScale onClear={onClear} /> : null}
    </div>
  )
}
