import { connect } from 'react-redux'
import { RootState } from '../../../../store'
import { professionsActions } from '../../../../store/domain/professions'
import { professionSelector } from '../../../../store/domain/professions/professions.selector'
import { bottomPanelContactsActions } from '../../../../store/ui/bottomPanelContacts'
import { citiesPageSelector, uiCitiesActions } from '../../../../store/ui/cities'
import { contactsPageSelector, uiContactsActions } from '../../../../store/ui/contacts'
import { PatientContactsFormBlock } from './PatientContactsFormBlock.component'
import {
  PatientContactsFormBlockDispatchProps,
  PatientContactsFormBlockStoreProps,
} from './PatientContactsFormBlock.model'

const mapStateToProps = (state: RootState): PatientContactsFormBlockStoreProps => ({
  predictions: contactsPageSelector(state),
  listOfProfession: professionSelector(state),
  cities: citiesPageSelector(state),
})

const mapDispatchToProps: PatientContactsFormBlockDispatchProps = {
  searchContact: uiContactsActions.requestPage,
  clearPredictions: uiContactsActions.clearPagination,
  openCreateContactForm: bottomPanelContactsActions.openCreateContactForm,
  openEditContactForm: bottomPanelContactsActions.openEditContactForm,
  getListOfProfessions: professionsActions.getListOfProfession,
  searchCity: uiCitiesActions.actions.requestPage,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PatientContactsFormBlock)
export { withConnect as PatientContactsFormBlock }
