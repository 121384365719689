import { format, parse } from 'date-fns'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Patient } from '../../../../model/Patient'
import { SelectOption } from '../../../../model/SelectOption'
import { SelectInput } from '../../inputs'
import { ValidationModal } from '../ValidationModal/ValidationModal.component'
import { VitalCardBeneficiariesModalProps } from './VitalCardBeneficiariesModal.model'

export const VitalCardBeneficiariesModal: FunctionComponent<VitalCardBeneficiariesModalProps> = ({
  beneficiaries,
  setVitalCardPatient,
  setBeneficiariesListVitalCard,
  isUnmounting,
  isUpdate = false,
}) => {
  useEffect(
    () => () => {
      setBeneficiariesListVitalCard([])
    },
    [setBeneficiariesListVitalCard],
  )
  const formatLabel = (beneficiary: Partial<Patient>) => {
    let label = beneficiary.birthFirstName + ' ' + beneficiary.birthLastName

    if (beneficiary.birthDate) {
      label =
        label + ' - ' + format(parse(beneficiary.birthDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
    }

    return label
  }

  const [selectedBeneficiary, setSelectedBeneficiary] = useState<
    SelectOption<Partial<Patient>> | undefined
  >(undefined)

  const beneficiariesValues = useMemo(() => {
    const options = [
      ...beneficiaries.map((beneficiary) => ({
        value: beneficiary,
        label: formatLabel(beneficiary),
      })),
    ]
    setSelectedBeneficiary(options.at(0))
    return options
  }, [beneficiaries])

  const selectedBeneficiaryValue = useMemo(
    () =>
      beneficiariesValues.find(
        (beneficarySelectValue) => beneficarySelectValue.value === selectedBeneficiary?.value,
      ),
    [beneficiariesValues, selectedBeneficiary?.value],
  )

  const handleClose = useCallback(() => {
    setBeneficiariesListVitalCard([])
    setSelectedBeneficiary(undefined)
  }, [setBeneficiariesListVitalCard])

  const handleSubmit = useCallback(() => {
    if (selectedBeneficiary) {
      setVitalCardPatient(selectedBeneficiary.value, isUpdate)
    }
    handleClose()
  }, [handleClose, isUpdate, selectedBeneficiary, setVitalCardPatient])

  return (
    <>
      <ValidationModal
        isUnmounting={isUnmounting}
        title="Plusieurs bénéficaires détectés sur la carte vitale"
        confirmTheme="primary"
        onSubmit={handleSubmit}
        onClose={handleClose}
      >
        <SelectInput
          title="Bénéficiaires"
          options={beneficiariesValues}
          value={selectedBeneficiaryValue}
          onSelect={setSelectedBeneficiary}
        />
      </ValidationModal>
    </>
  )
}
