import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getNextPageParam } from '../../../misc/query.utilities'
import { questionnairesKeys } from './questionnaires.keys'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData } from '../../utils/query/useFlatPaginatedData.hook'
import { usePaginationState } from '../../utils/query'
import { useCallback, useMemo } from 'react'
import { singleItemPageLimit } from '../../../constants'
import { getQuestionnaires } from '../../../data/questionnaires'

interface GetInfiniteQuestionnairesParams {
  enabled?: boolean
  filters?: Filters
  limit?: number
}

export const useGetInfiniteQuestionnaires = ({
  enabled = true,
  filters,
  limit = singleItemPageLimit,
}: GetInfiniteQuestionnairesParams = {}) => {
  const queryKey = useMemo(() => questionnairesKeys.list(filters), [filters])

  const queryReturn = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1, signal }) => {
      const { data, ok } = await getQuestionnaires(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des questionnaires`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      getNextPageParam,
      enabled: enabled,
    },
  )

  const queryClient = useQueryClient()

  const questionnaireList = useFlatPaginatedData(queryReturn.data)
  const paginationState = usePaginationState(queryReturn.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query: queryReturn, questionnaireList, paginationState, cancelPendingQuery }
}
