import getApi from '../../api/apisauceConfig'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'
import { singleItemPageLimit } from '../../constants'
import { QuestionResource } from './mapper/questions.model'
import { deserializeQuestionsPageResponse } from './mapper/questions.mapper'

export const getQuestions = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi()
    .get<PaginatedList<QuestionResource>>(
      '/questions',
      {
        page,
        limit,
        ...filters,
      },
      { signal },
    )
    .then(deserializeQuestionsPageResponse)
