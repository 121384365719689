import { uiActionsFactory } from '../../restux/ui'
import { documentContextualMenuQuestionnaireConfig } from './documentContextualMenu.model'

const documentContextualMenuQuestionnaireUiActions = uiActionsFactory(
  documentContextualMenuQuestionnaireConfig,
).actions

export const documentContextualMenuActions = {
  ...documentContextualMenuQuestionnaireUiActions,
}
