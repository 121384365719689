import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { questionnairesKeys } from './questionnaires.keys'
import { getQuestionnaire } from '../../../data/questionnaires'
import { Questionnaire } from '../../../model/Questionnaire'

interface GetQuestionnaireParams {
  id: number
  enabled?: boolean
}

export const useGetQuestionnaire = ({ id, enabled = true }: GetQuestionnaireParams) => {
  const queryKey = useMemo(() => questionnairesKeys.detail(id), [id])

  const queryReturn = useQuery<Questionnaire>(
    queryKey,
    async () => {
      const { data, ok } = await getQuestionnaire(id)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le questionnaire`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      enabled: enabled,
    },
  )

  return { query: queryReturn }
}
