import { getEditorEmptyJsonValue } from '@follow/farte'
import { take, put, takeEvery, select, call } from 'redux-saga/effects'
import { customHistory } from '../../../history'
import { uiSagasFactory } from '../../restux/ui/restuxUiSagas.factory'
import { createDefaultQuestion, Questionnaire } from '../../../model/Questionnaire'
import {
  managerDocumentTemplatesConfig,
  UiManagerDocumentTemplateActionTypes,
} from './managerDocumentTemplates.model'
import { questionsActions } from '../../cache/questions'
import { documentTemplatesActions } from '../../cache/documentTemplates'
import { managerDocumentTemplatesActions } from './managerDocumentTemplates.actions'

import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { generateIdentifier, identifierMatchScope } from '../../restux/restux.utilities'
import { inUseDocumentTemplateSelector } from '../bottomPanelDocumentTemplates'
import { RestuxLoadingState } from '../../restux/ui'
import { RestuxResources } from '../../resources'
import { editorFontFamilyPreferencesSelector } from '../../domain/me'
import { DocumentTemplate } from '../../../model/DocumentTemplate'
import { documentCategoriesSelector } from '../../domain/documentCategories/documentCategories.selectors'
import { DocumentCategory } from '../../../model/DocumentCategory'
import { ApiResponse } from 'apisauce'
import { FileType, CustomFile } from '../../../model/File'
import { uploadFile } from '../../cache/files/api'
import { addResponseError } from '../../message/message.actions'

const paginationSagas = uiSagasFactory(managerDocumentTemplatesConfig)

function* addExistingQuestionToInUseDocumentTemplateWorker({
  documentTemplateId,
  selectedQuestion,
  onQuestionAddedToDocumentTemplate,
}: ReturnType<typeof managerDocumentTemplatesActions.addExistingQuestionToInUseDocumentTemplate>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.addExistingQuestionToDocumentTemplate)

  const inUseDocumentTemplate: ReturnType<typeof inUseDocumentTemplateSelector> = yield select(
    inUseDocumentTemplateSelector,
  )
  if (
    inUseDocumentTemplate &&
    inUseDocumentTemplate.id === documentTemplateId &&
    inUseDocumentTemplate.type === 'farte'
  ) {
    yield put(
      documentTemplatesActions.actions.apiUpdateItem(
        inUseDocumentTemplate.id,
        {
          variables: [...inUseDocumentTemplate.variables, selectedQuestion],
        },
        {
          identifier: taskIdentifier,
        },
      ),
    )
    const { identifier } = yield take(documentTemplatesActions.types.STORE_SET_ITEM_DETAILS)
    if (identifier === taskIdentifier) {
      onQuestionAddedToDocumentTemplate(selectedQuestion)
    }
  } else {
    throw new Error(`Inconsitence entre le document cible et celui en cours d'edition`)
  }
}

function* addExistingQuestionToInUseDocumentTemplateWatcher() {
  yield takeEvery(
    UiManagerDocumentTemplateActionTypes.ADD_EXISTING_QUESTION_TO_DOCUMENT_IN_USE_TEMPLATE,
    addExistingQuestionToInUseDocumentTemplateWorker,
  )
}

function* addQuestionnaireToInUseDocumentTemplateWorker({
  documentTemplateId,
  selectedQuestionnaire,
  onQuestionnaireAdded,
}: ReturnType<typeof managerDocumentTemplatesActions.addQuestionnaireToInUseDocumentTemplate>) {
  const inUseDocumentTemplate: ReturnType<typeof inUseDocumentTemplateSelector> = yield select(
    inUseDocumentTemplateSelector,
  )
  if (
    inUseDocumentTemplate &&
    inUseDocumentTemplate.id === documentTemplateId &&
    inUseDocumentTemplate.type === 'farte'
  ) {
    const taskIdentifier = generateIdentifier(RestuxResources.documentTemplate)
    yield put(
      documentTemplatesActions.actions.apiUpdateItem(
        inUseDocumentTemplate.id,
        {
          questionnaires: [
            ...inUseDocumentTemplate.questionnaires,
            // L'objet IQuestionnaireLight est sufisant ici car ler DocumentTemplate.mapper converti le tableau questionnaires en questionnaireIds
            selectedQuestionnaire as any as Questionnaire,
          ],
        },
        { identifier: taskIdentifier },
      ),
    )
    const { identifier }: ReturnType<typeof documentTemplatesActions.actions.storeSetItemDetails> =
      yield take(documentTemplatesActions.types.STORE_SET_ITEM_DETAILS)
    if (taskIdentifier === identifier) {
      onQuestionnaireAdded(selectedQuestionnaire)
    }
  } else {
    throw new Error(`Inconsitence entre le document cible et celui en cours d'edition`)
  }
}

function* addQuestionnaireToInUseDocumentTemplateWatcher() {
  yield takeEvery(
    UiManagerDocumentTemplateActionTypes.ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_TEMPLATE,
    addQuestionnaireToInUseDocumentTemplateWorker,
  )
}

function* addQuestionToInUseDocumentTemplateWorker({
  documentTemplateId,
  questionType,
  onQuestionAddedToDocumentTemplate,
}: ReturnType<typeof managerDocumentTemplatesActions.addQuestionToInUseDocumentTemplate>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.addQuestionToDocumentTemplateSaga)
  yield put(
    questionsActions.actions.apiCreateItem(
      createDefaultQuestion(questionType, { documentTemplateId }),
      {
        identifier: taskIdentifier,
      },
    ),
  )
  const { item }: ReturnType<typeof questionsActions.actions.storeSetItemDetails> = yield take(
    questionsActions.types.STORE_SET_ITEM_DETAILS,
  )
  onQuestionAddedToDocumentTemplate(item)
}

function* addQuestionToInUseDocumentTemplateWatcher() {
  yield takeEvery(
    UiManagerDocumentTemplateActionTypes.ADD_QUESTION_TO_IN_USE_DOCUMENT_TEMPLATE,
    addQuestionToInUseDocumentTemplateWorker,
  )
}

function* createDocumentTemplateWorker({
  templateResources,
}: ReturnType<typeof managerDocumentTemplatesActions.createDocumentTemplate>) {
  const { documentTemplateCategory } = templateResources

  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.createDocumentTemplateSaga)
  const editorFontFamily: ReturnType<typeof editorFontFamilyPreferencesSelector> = yield select(
    editorFontFamilyPreferencesSelector,
  )
  const categories: Array<DocumentCategory> = yield select(documentCategoriesSelector)
  const selectedCategory = categories.find((category) => category.id === documentTemplateCategory)
  let documentTemplate: Partial<DocumentTemplate> = {
    title: 'Nouveau modèle',
    category: selectedCategory,
  }

  if (templateResources.documentType === 'pdf') {
    const response: ApiResponse<CustomFile> = yield call(
      uploadFile,
      templateResources.file,
      FileType.MEDICAL_EVENT,
    )

    if (!response.ok || !response.data) {
      yield put(addResponseError(response))
    } else {
      documentTemplate = {
        ...documentTemplate,
        type: 'pdf',
        fileId: response.data.id,
      }
    }
  } else {
    const template = getEditorEmptyJsonValue(editorFontFamily)
    documentTemplate = {
      ...documentTemplate,
      type: 'farte',
      template,
    }
  }

  yield put(
    documentTemplatesActions.actions.apiCreateItem(documentTemplate, {
      identifier: taskIdentifier,
    }),
  )
  const {
    identifier,
    item,
  }: ReturnType<typeof documentTemplatesActions.actions.storeSetItemDetails> = yield take(
    documentTemplatesActions.types.STORE_SET_ITEM_DETAILS,
  )
  if (identifier === taskIdentifier) {
    yield put(managerDocumentTemplatesActions.useId(item.id))
    customHistory.navigate(`/managers/documents/${item.id}`)
  }
}

function* createDocumentTemplateWatcher() {
  yield takeEvery(UiManagerDocumentTemplateActionTypes.CREATE, createDocumentTemplateWorker)
}

function* updateDocumentTemplateWorker({
  documentTemplateId,
  documentTemplateUpdates,
}: ReturnType<typeof managerDocumentTemplatesActions.updateDocumentTemplate>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.updateDocumentTemplateSaga)
  yield put(managerDocumentTemplatesActions.setInUseLoading(RestuxLoadingState.RELOADING))
  yield put(
    documentTemplatesActions.actions.apiUpdateItem(documentTemplateId, documentTemplateUpdates, {
      identifier: taskIdentifier,
    }),
  )
  const { identifier }: ReturnType<typeof documentTemplatesActions.actions.storeSetItemDetails> =
    yield take(documentTemplatesActions.types.STORE_SET_ITEM_DETAILS)
  if (identifier === taskIdentifier) {
    yield put(managerDocumentTemplatesActions.setInUseLoading(RestuxLoadingState.IDLE))
  }
}

function* updateDocumentTemplateWatcher() {
  yield takeEvery(UiManagerDocumentTemplateActionTypes.UPDATE, updateDocumentTemplateWorker)
}

function* onCreateQuestionWorker({
  identifier,
}: ReturnType<typeof questionsActions.actions.storeSetItemDetails>) {
  if (identifierMatchScope(identifier, RESTUX_IDENTIFIER.createQuestion)) {
    const inUseDocumentTemplate: ReturnType<typeof inUseDocumentTemplateSelector> = yield select(
      inUseDocumentTemplateSelector,
    )
    if (inUseDocumentTemplate) {
      yield put(documentTemplatesActions.actions.apiGetItemDetails(inUseDocumentTemplate.id))
    }
  }
}

function* onCreateQuestionWatcher() {
  yield takeEvery(questionsActions.types.STORE_SET_ITEM_DETAILS, onCreateQuestionWorker)
}

export const managerDocumentTemplatesSagas = {
  ...paginationSagas,
  addExistingQuestionToInUseDocumentTemplateWatcher,
  addQuestionnaireToInUseDocumentTemplateWatcher,
  addQuestionToInUseDocumentTemplateWatcher,
  onCreateQuestionWatcher,
  createDocumentTemplateWatcher,
  updateDocumentTemplateWatcher,
}
