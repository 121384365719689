import { connect } from 'react-redux'
import { RootState } from '../../../store'
import {
  patientTreatmentsPageSelector,
  uiPatientTreatmentsActions,
} from '../../../store/ui/patientTreatments'
import { TreatmentsHistoryBottomPanel } from './TreatmentsHistoryBottomPanel.component'
import {
  TreatmentsHistoryBottomPanelDispatchProps,
  TreatmentsHistoryBottomPanelStateProps,
} from './TreatmentsHistoryBottomPanel.model'

const mapStateToProps = (state: RootState): TreatmentsHistoryBottomPanelStateProps => ({
  treatments: patientTreatmentsPageSelector(state),
})

const mapDisptachToProps: TreatmentsHistoryBottomPanelDispatchProps = {
  getTreatments: uiPatientTreatmentsActions.requestPage,
}

const withConnect = connect(mapStateToProps, mapDisptachToProps)(TreatmentsHistoryBottomPanel)
export { withConnect as TreatmentsHistoryBottomPanel }
