import { take, put, takeEvery } from 'redux-saga/effects'
import { documentTemplatesActions } from '../../cache/documentTemplates'
import { questionsActions } from '../../cache/questions'
import { domainQuestionsActions } from './questions.actions'
import { DomainQuestionsActionTypes } from './questions.model'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { generateIdentifier } from '../../restux/restux.utilities'

function* deleteQuestionFromDocumentTemplateSagaWorker({
  questionId,
  documentTemplate,
}: ReturnType<typeof domainQuestionsActions.deleteQuestionFromDocumentTemplate>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.deleteQuestionFromDocumentTemplate)
  yield put(
    questionsActions.actions.apiDeleteItem(questionId, {
      identifier: taskIdentifier,
    }),
  )
  const {
    identifier,
  }: ReturnType<typeof questionsActions.actions.storeDeleteDetailsItem> = yield take(
    questionsActions.types.STORE_DELETE_ITEM,
  )
  if (identifier === taskIdentifier) {
    yield put(documentTemplatesActions.actions.apiGetItemDetails(documentTemplate.id))
  }
}
function* deleteQuestionFromDocumentTemplateSagaWatcher() {
  yield takeEvery(
    DomainQuestionsActionTypes.DELETE_QUESTION_FROM_DOCUMENT_TEMPLATE,
    deleteQuestionFromDocumentTemplateSagaWorker,
  )
}

export const questionsSagas = {
  deleteQuestionFromDocumentTemplateSagaWatcher,
}
