import { uiActionsFactory } from '../../../restux/ui/restuxUiActions.factory'
import { Question, LightQuestionnaire, QuestionType } from '../../../../model/Questionnaire'
import {
  UiMedicalEventDocumentInstanceActionTypes,
  medicalEventDocumentInstancesConfig,
} from './medicalEventDocumentInstances.model'

const uiActions = uiActionsFactory(medicalEventDocumentInstancesConfig).actions

const addExistingQuestionToInUseDocumentInstance = (
  documentInstanceId: number,
  selectedQuestion: Question,
  onQuestionAddedToDocumentInstance: (question: Question) => void,
) => ({
  type: UiMedicalEventDocumentInstanceActionTypes.ADD_EXISTING_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE,
  documentInstanceId,
  selectedQuestion,
  onQuestionAddedToDocumentInstance,
})

const addQuestionnaireToInUseDocumentInstance = (
  documentInstanceId: number,
  selectedQuestionnaire: LightQuestionnaire,
  onQuestionnaireAdded: (questionnaire: LightQuestionnaire) => void,
) => ({
  type: UiMedicalEventDocumentInstanceActionTypes.ADD_QUESTIONNAIRE_TO_IN_USE_DOCUMENT_INSTANCE,
  documentInstanceId,
  selectedQuestionnaire,
  onQuestionnaireAdded,
})

const addQuestionToInUseDocumentInstance = (
  documentInstanceId: number,
  questionType: QuestionType,
  title: string,
  onQuestionAddedToDocumentInstance: (question: Question) => void,
) => ({
  type: UiMedicalEventDocumentInstanceActionTypes.ADD_QUESTION_TO_IN_USE_DOCUMENT_INSTANCE,
  documentInstanceId,
  title,
  questionType,
  onQuestionAddedToDocumentInstance,
})

export const medicalEventDocumentInstancesActions = {
  ...uiActions,
  addExistingQuestionToInUseDocumentInstance,
  addQuestionnaireToInUseDocumentInstance,
  addQuestionToInUseDocumentInstance,
}
