import { ApiResponse } from 'apisauce'
import { takeEvery, put, call } from 'redux-saga/effects'
import { Profession } from '../../../model/Professions'
import { getProfessionList } from '../../cache/professions/api'
import { addResponseError } from '../../message'
import { professionsActions } from './professions.actions'
import { professionsActionsType } from './professions.model'

export function* fetchListOfProfessionsSagas() {
  const response: ApiResponse<Profession[]> = yield call(getProfessionList)

  if (response.ok && response.data) {
    yield put(professionsActions.storeListOfProfessions(response.data))
  } else {
    yield put(addResponseError(response))
  }
}

export function* fetchProfessionsWatcher() {
  yield takeEvery(professionsActionsType.GET_LIST_OF_PROFESSION, fetchListOfProfessionsSagas)
}

export const professionsSagas = { fetchProfessionsWatcher }
