import { combineReducers } from 'redux'
import { adminAnalyticsReducer as adminAnalytics } from './adminAnalytics/adminAnalytics.reducer'
import { adminUsersReducer as adminUsers } from './adminUsers/adminUsers.reducer'
import { bottomPanelReducer as bottomPanel } from './bottomPanel/bottomPanel.reducer'
import { bottomPanelContactsReducer as bottomPanelContacts } from './bottomPanelContacts/bottomPanelContacts.reducer'
import { bottomPanelDocumentHistoryReducer as bottomPanelDocumentHistory } from './bottomPanelDocumentHistory/bottomPanelDocumentHistory.reducer'
import { bottomPanelDocumentTemplatesReducer as bottomPanelDocumentTemplates } from './bottomPanelDocumentTemplates/bottomPanelDocumentTemplates.reducer'
import { bottomPanelQuestionsReducer as bottomPanelQuestions } from './bottomPanelQuestions/bottomPanelQuestions.reducer'
import { bottomPanelBaseVariableReducer as bottomPanelBaseVariable } from './bottomPanelBaseVariables/bottomPanelBaseVariables.reducer'
import { contactsReducer as contacts } from './contacts/contacts.reducer'
import { documentContextualMenuReducer as documentContextualMenu } from './documentContextualMenu/documentContextualMenu.reducer'
import { managerDocumentTemplatesReducer as managerDocumentTemplates } from './managerDocumentTemplates/managerDocumentTemplates.reducer'
import { medicalEventReducer as medicalEvent } from './medicalEvents/medicalEvents.reducer'
import { patientCurrentTreatmentsReducer as patientCurrentTreatments } from './patientCurrentTreatments/patientCurrentTreatments.reducer'
import { patientManualPrescriptionsReducer as patientManualPrescriptions } from './patientManualPrescriptions/patientManualPrescriptions.reducer'
import { patientTreatmentsReducer as patientTreatments } from './patientTreatments/patientTreatments.reducer'
import { questionnaireComputedValuesReducer as questionnaireComputedValues } from './questionnaireComputedValues/questionnaireComputedValues.reducer'
import { UiState } from './ui.model'
import { atcClassificationReducer as atcClassification } from './atcClassification/atcClassification.reducer'
import { citiesReducer as cities } from './cities/cities.reducer'
import { mailContactsReducer as mailContacts } from './mailContacts/mailContacts.reducer'
import { MailSubjectreducer as mailSubject } from './mailSubject/mailSubject.reducer'
import { bottomPanelDrugReducer as bottomPanelDrugs } from './bottomPanelDrugs/bottomPanelDrugs.reducer'
import { bottomPanelMailEditorReducer as bottomPanelMailEditor } from './bottomPanelMailEditor/bottomPanelMailEditor.reducer'
import { bottomPanelPosologyReducer as bottomPanelPosology } from './bottomPanelPosology/bottomPanelPosology.reducer'
import { documentAlertsReducer as documentAlerts } from './documentAlerts/documentAlerts.reducer'
import { bottomPanelResourceHistoryReducer as bottomPanelResourceHistory } from './bottomPanelResourceHistory/bottomPanelResourceHistory.reducer'
import { resourceHistoryReducer as resourceHistory } from './resourceHistory/resourceHistory.reducer'
import { countriesReducer as countries } from './countries/countries.reducer'
import { mailContactSearchReducer as mailContactSearch } from './mailContactSearch/mailContactSearch.reducer'

export default combineReducers<UiState>({
  adminAnalytics,
  atcClassification,
  adminUsers,
  bottomPanel,
  bottomPanelContacts,
  bottomPanelDocumentHistory,
  bottomPanelDocumentTemplates,
  bottomPanelMailEditor,
  bottomPanelQuestions,
  bottomPanelResourceHistory,
  bottomPanelBaseVariable,
  bottomPanelDrugs,
  bottomPanelPosology,
  cities,
  contacts,
  countries,
  documentContextualMenu,
  documentAlerts,
  mailContacts,
  mailSubject,
  managerDocumentTemplates,
  medicalEvent,
  patientCurrentTreatments,
  patientManualPrescriptions,
  patientTreatments,
  questionnaireComputedValues,
  resourceHistory,
  mailContactSearch: mailContactSearch,
})
