import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getNextPageParam } from '../../../misc/query.utilities'
import { questionsKeys } from './questions.keys'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData } from '../../utils/query/useFlatPaginatedData.hook'
import { usePaginationState } from '../../utils/query'
import { useCallback, useMemo } from 'react'
import { singleItemPageLimit } from '../../../constants'
import { getQuestions } from '../../../data/questions'

interface GetInfiniteQuestionsParams {
  enabled?: boolean
  filters?: Filters
  limit?: number
}

export const useGetInfiniteQuestions = ({
  enabled = true,
  filters,
  limit = singleItemPageLimit,
}: GetInfiniteQuestionsParams = {}) => {
  const queryKey = useMemo(() => questionsKeys.list(filters), [filters])

  const queryReturn = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1, signal }) => {
      const { data, ok } = await getQuestions(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des questions`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    {
      getNextPageParam,
      enabled: enabled,
    },
  )

  const queryClient = useQueryClient()

  const questionList = useFlatPaginatedData(queryReturn.data)
  const paginationState = usePaginationState(queryReturn.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query: queryReturn, questionList, paginationState, cancelPendingQuery }
}
