import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import { ScoreConfig } from '../../../../model/Questionnaire'

import { GridElement, GridLayout, Input, TagSearchAndDisplay } from '../../../shared'
import { ScoreConfigForm } from './ScoreConfigForm'

import { QuestionnaireFormProps } from './QuestionnaireForm.model'
import styles from './QuestionnaireForm.module.scss'
import RoleRestriction from '../../../../containers/RoleRestriction'
import { ADMIN_ROLES, Roles } from '../../../../model/Roles'
import { LabeledCheckbox } from '../../..//legacy/LabeledCheckbox'
import { useCreateTag, useGetTags } from '../../../../hooks/queries/tag'

export const QuestionnaireForm: FC<QuestionnaireFormProps> = ({
  questionnaire,
  scoreCodes,
  onUpdate,
}) => {
  const [title, setTitle] = useState<string>(questionnaire.title)
  const [isPrivate, setIsPrivate] = useState<boolean>(questionnaire.private)
  const [isScore, setIsScore] = useState(!!questionnaire.scoreConfig)
  const { tagList: tags } = useGetTags()
  const { mutate: createTag } = useCreateTag()

  useDebounce(
    () => {
      onUpdate(questionnaire.id, {
        title,
        private: isPrivate,
      })
    },
    500,
    [isPrivate, title],
  )

  useEffect(() => {
    if (questionnaire.scoreConfig && !isScore) {
      onUpdate(questionnaire.id, {
        scoreConfig: null,
      })
    }
  }, [isScore, isPrivate, onUpdate, questionnaire.id, questionnaire.scoreConfig, title])

  const handleSaveScoreConfig = (scoreConfig: ScoreConfig) => {
    onUpdate(questionnaire.id, {
      title,
      private: isPrivate,
      scoreConfig,
    })
  }

  const handleTagsUpdate = useCallback(
    (valueIds: string[]) => {
      onUpdate(questionnaire.id, { tagIds: valueIds })
    },
    [onUpdate, questionnaire.id],
  )

  const handleTagCreate = useCallback(
    (label: string) => {
      createTag(
        { label },
        { onSuccess: (tag) => handleTagsUpdate([...questionnaire.tagIds, tag.id]) },
      )
    },
    [createTag, handleTagsUpdate, questionnaire.tagIds],
  )

  return (
    <form className={styles.form}>
      <GridLayout
        columns={2}
        rowsTemplate="repeat(2, auto)"
        alignColumns="end"
        gap="medium"
        padding="medium"
      >
        <Input
          name="title"
          label="Nom du questionnaire"
          placeholder="Nom du questionnaire"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          disabled={!questionnaire.isEditable}
          valid={true}
          colorPreset="dark"
        />

        <RoleRestriction roles={[...ADMIN_ROLES, Roles.EXT_DSM]}>
          <LabeledCheckbox
            colorPreset="light"
            checked={isPrivate}
            label="Questionnaire privé"
            onChange={(e) => setIsPrivate(e.target.checked)}
          />
        </RoleRestriction>
        <GridElement>
          <TagSearchAndDisplay
            colorPreset="dark"
            tags={tags}
            valueIds={questionnaire.tagIds}
            onChange={handleTagsUpdate}
            onCreate={handleTagCreate}
            placeholder="Rechercher un label"
            disabled={!questionnaire.isEditable}
          />
        </GridElement>
        <GridElement>
          <RoleRestriction roles={[...ADMIN_ROLES, Roles.EXT_DSM]}>
            <LabeledCheckbox
              colorPreset="light"
              checked={isScore}
              label="Score"
              onChange={(e) => setIsScore(e.target.checked)}
              disabled={!questionnaire.isEditable}
            />
          </RoleRestriction>
          {isScore && (
            <ScoreConfigForm
              config={questionnaire.scoreConfig || undefined}
              scoreCodes={scoreCodes}
              disabled={!questionnaire.isEditable}
              saveScoreConfig={handleSaveScoreConfig}
            />
          )}
        </GridElement>
      </GridLayout>
    </form>
  )
}
