import { domainAnswerValuesSagas } from './documentAnswerValues/documentAnswerValues.sagas'
import { domainDocumentHistorySagas } from './documentHistory/documentHistory.sagas'
import { domainDocumentTemplatesSagas } from './documentTemplates/documentTemplates.sagas'
import { filesDomainSagas } from './files/files.sagas'
import { questionsSagas } from './questions/questions.sagas'
import { meSagas } from './me/me.sagas'
import { medicalEventsDomainSagas } from './medicalEvents/medicalEvents.saga'
import { domainDocumentInstancesSagas } from './documentInstances/documentInstances.sagas'
import { codesSagas } from './codes/codes.sagas'
import { vitalCardSagas } from './vitalCard/vitalCard.sagas'
import { documentCategoriesSagas } from './documentCategories/documentCategories.sagas'
import { domainContactsSagas } from './contacts/contacts.sagas'
import { externalServicesSagas } from './externalServices/externalServices.sagas'
import { posologySagas } from './posology/posology.sagas'
import { prescriptionConfigsSagas } from './prescription'
import { mailSagas } from './mail/mail.sagas'
import { activePrinciplesSagas } from './activePrinciples'
import { domainDrugsSagas } from './drugs/drugs.sagas'
import { domainDocumentAlertsSagas } from './documentAlerts'
import { indicationsSagas } from './indications'
import { sideEffectsSagas } from './sideEffects'
import { manualPrescriptionSagas } from './manualPrescription'
import { assigningAuthoritiesSagas } from './assigningAuthorities'
import { insiDomainSagas } from './insi'
import { ephemeralNotificationsSagas } from './ephemeralNotification/ephemeralNotification.sagas'
import { dmpSagas } from './dmp/dmp.sagas'
import { professionsSagas } from './professions/professions.sagas'
import { specialitiesSagas } from './specialities/specialities.sagas'
import { metricsSagas } from './metrics/metrics.sagas'
import { prescriptionTemplateSagas } from './prescriptionTemplate'

export const domainSagas = [
  ...Object.values(activePrinciplesSagas),
  ...Object.values(domainAnswerValuesSagas),
  ...Object.values(domainDocumentHistorySagas),
  ...Object.values(domainDocumentTemplatesSagas),
  ...Object.values(domainDocumentAlertsSagas),
  ...Object.values(externalServicesSagas),
  ...Object.values(medicalEventsDomainSagas),
  ...Object.values(domainDocumentInstancesSagas),
  ...Object.values(documentCategoriesSagas),
  ...Object.values(ephemeralNotificationsSagas),
  ...Object.values(prescriptionConfigsSagas),
  ...Object.values(manualPrescriptionSagas),
  ...Object.values(domainDrugsSagas),
  ...Object.values(filesDomainSagas),
  ...Object.values(mailSagas),
  ...Object.values(meSagas),
  ...Object.values(questionsSagas),
  ...Object.values(codesSagas),
  ...Object.values(vitalCardSagas),
  ...Object.values(domainContactsSagas),
  ...Object.values(posologySagas),
  ...Object.values(prescriptionTemplateSagas),
  ...Object.values(indicationsSagas),
  ...Object.values(sideEffectsSagas),
  ...Object.values(assigningAuthoritiesSagas),
  ...Object.values(insiDomainSagas),
  ...Object.values(dmpSagas),
  ...Object.values(professionsSagas),
  ...Object.values(specialitiesSagas),
  ...Object.values(metricsSagas),
]
