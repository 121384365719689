import React, { FC, useCallback } from 'react'
import { ActionModalLayout, Modal } from '../Modal'
import { UpdateModalProps } from './UpdateModal.model'
import Waypoint from 'react-waypoint'
import { Loader } from '../../loading'
import { UpdateModalItem } from './UpdateModalItem'
import { NotificationType } from '../../../../model/Notification'
import {
  useGetInfiniteUserNotifications,
  useReadUserNotifications,
} from '../../../../hooks/queries/notifications'

export const UpdateModal: FC<UpdateModalProps> = ({ isUnmounting, onClose }) => {
  const { notificationList, fetchNextPage, hasNextPage, isLoading } =
    useGetInfiniteUserNotifications({
      filters: {
        types: [NotificationType.feature],
      },
    })
  const { mutate: readNotifications } = useReadUserNotifications()

  const handleClose = useCallback(() => {
    const notificationIds = notificationList.reduce<Array<string>>(
      (acc, { read, id }) => (read ? acc : [...acc, id]),
      [],
    )

    if (notificationIds.length > 0) {
      readNotifications({ notificationIds })
    }
    onClose()
  }, [notificationList, onClose, readNotifications])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={handleClose}>
      <ActionModalLayout title="Mises à jour" onClose={handleClose}>
        <div className="bg-shades-7 w-185 pb-4 overflow-y-scroll">
          {isLoading && (
            <div className="flex justify-center items-center absolute top-0 left-0 h-full w-full z-max bg-shades-white">
              <Loader isLoading={true} height={70} width={70} />
            </div>
          )}
          {notificationList.map((notification) => (
            <UpdateModalItem key={notification.id} notification={notification} />
          ))}
          {hasNextPage && <Waypoint topOffset={1200} onEnter={() => fetchNextPage()} />}
        </div>
      </ActionModalLayout>
    </Modal>
  )
}
