import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QuestionnaireToClonePayload } from '../../../data/questionnaires/mapper/questionnaires.model'
import { Questionnaire } from '../../../model/Questionnaire'
import { cloneQuestionnaire } from '../../../data/questionnaires'
import { UserFriendlyError } from '../../../model/Error'
import { questionnairesKeys } from './questionnaires.keys'
import { useContext } from 'react'
import { ConnectedUserContext } from '../../../misc/auth.utilities'

export const useCloneQuestionnaire = () => {
  const queryClient = useQueryClient()
  const { currentUser } = useContext(ConnectedUserContext)

  return useMutation<Questionnaire, Error, QuestionnaireToClonePayload>(
    async (payload) => {
      const { data, ok } = await cloneQuestionnaire(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La duplication du questionnaire a échoué')
      }
      return data
    },
    {
      onSuccess: (_, payload) => {
        if (payload.userTargetId === currentUser?.id) {
          queryClient.invalidateQueries(questionnairesKeys.lists)
        }
      },
    },
  )
}
