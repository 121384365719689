import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Question, QUESTION_TYPE_TO_LABEL } from '../../../../model/Questionnaire'
import { Label } from '../../../shared'
import styles from './QuestionTitle.module.scss'
import { getQuestionLevel } from '../../Questionnaire.model'

function isShareableQuestion(question: Question | Question): question is Question {
  return question.hasOwnProperty('sharedInMedicalEvent')
}

interface QuestionTitleProps {
  question: Question | Question
}

const cx = classNames.bind(styles)

export const QuestionTitle: FunctionComponent<QuestionTitleProps> = ({ question }) => {
  const shared = isShareableQuestion(question) && question.sharedInMedicalEvent
  const emptyQuestionTitleDisplay = getQuestionLevel(question.parentQuestionId)
  return (
    <div className={styles.titleContainer} data-test-id="title-question">
      <h3 className={cx(styles.title, { [styles.defaultTitle]: !question.title })}>
        {question.title || emptyQuestionTitleDisplay}
      </h3>

      <div className={styles.labels}>
        <Label theme="light" testId="label-question-type">
          {QUESTION_TYPE_TO_LABEL[question.type]}
        </Label>
        {shared && (
          <Label theme="warning" title="Valeur par défaut de l'évenement">
            {'Par défaut'}
          </Label>
        )}
      </div>
    </div>
  )
}
