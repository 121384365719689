import getApi from '../../../../api/index'

import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'

import { Question } from '../../../../model/Questionnaire'
import { PaginatedList } from '../../../../model/Pagination'
import {
  serializeQuestion,
  deserializeQuestionsPageResponse,
  deserializeQuestionResponse,
} from './Question.mapper'
import { QuestionResource } from './Questions.model'

export const restuxQuestionsApiConfig: RestuxApiConfig<Question, Question> = {
  url: '/questions',
  getPaginatedItems: (route) =>
    getApi().get<PaginatedList<QuestionResource>>(route).then(deserializeQuestionsPageResponse),
  getItem: (route) => getApi().get<QuestionResource>(route).then(deserializeQuestionResponse),
  createItem: (route, item) => getApi().post<Question>(route, serializeQuestion(item)),
  updateItem: (route, item) => getApi().put<Question>(route, serializeQuestion(item)),
  deleteItem: (route) => getApi().delete<void>(route),
}
