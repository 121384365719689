import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { Answer } from '../../../model/Questionnaire'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { updateAnswer } from '../../../data/answers'
import { questionsKeys } from '../questions/questions.keys'

interface RemoveImageFromAnswerMutateVariable {
  answerId: number
  questionnaireId?: number
}

export const useRemoveImageFromAnswer = () => {
  const queryClient = useQueryClient()

  return useMutation<Answer, Error, RemoveImageFromAnswerMutateVariable>(
    async ({ answerId }) => {
      const { data, ok } = await updateAnswer(answerId, {
        imageFile: null,
      })
      if (!data || !ok) {
        throw new UserFriendlyError('error', `La suppression de l'image sur la réponse a échoué`)
      }
      return data
    },
    {
      onSuccess: (answer, { questionnaireId }) => {
        queryClient.invalidateQueries(questionsKeys.detail(answer.questionId))
        if (questionnaireId) {
          queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
        }
      },
    },
  )
}
