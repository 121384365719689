import { connect } from 'react-redux'
import { RootState } from '../../../../../store'
import { ProfessionSpecialitiesSelect as component } from './ProfessionSpecialities.component'
import { specialitiesActions } from '../../../../../store/domain/specialities/specialities.actions'
import {
  ProfessionSpecialitiesSelectStateProps,
  ProfessionSpecialitiesSelectDispatchProps,
} from './ProfessionSpecialities.model'
import { specialitiesSelector } from '../../../../../store/domain/specialities/specialities.selector'

const mapStateToProps = (state: RootState): ProfessionSpecialitiesSelectStateProps => ({
  listOfSpecialities: specialitiesSelector(state),
})

const mapDisptachToProps: ProfessionSpecialitiesSelectDispatchProps = {
  getListOfSpecialities: specialitiesActions.getSpecialities,
}

export const ProfessionSpecialitiesSelect = connect(mapStateToProps, mapDisptachToProps)(component)
