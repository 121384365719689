import { FunctionComponent, MouseEvent, useState } from 'react'
import { useDebounce } from 'react-use'

import { defaultAnswerTitle } from '../../../constants'

import { isDefined } from '../../../misc/functions.utilities'

import { Input, Button, IconButton } from '../../../components/shared'
import { FileDropzone } from '../../../components/file'

import AnswerImage from '../AnswerImage'

import { EditAnswerProps } from './EditAnswer.model'
import { EditTextOutput } from './EditTextOutput.component'
import { Answer } from '../../../model/Questionnaire'
import ChildQuestionsLink from './ChildQuestionsLink'

import styles from './EditAnswer.module.scss'
import { EDIT_QUESTION_DEBOUNCE_LATENCY } from '../EditQuestionPanel/EditQuestionPanel.model'
import { isEqual } from 'lodash'

const isDefaultAnswerTitle = (answer: Answer): boolean => answer.title === defaultAnswerTitle

const EditAnswer: FunctionComponent<EditAnswerProps> = ({
  answer,
  canEditPoints,
  editorFontFamily,
  isChild,
  onAddImage,
  onDeleteAnswer,
  onRemoveImage,
  onUpdateAnswer,
  isDeletable,
  isEditable,
}) => {
  const [displayDropzone, setDisplayDropzone] = useState(false)
  const [isGeneratedText, setIsGeneratedText] = useState(isDefined(answer.textOutput))

  const [title, setTitle] = useState(answer.title)
  const [textOutput, setTextOutput] = useState(answer.textOutput)
  const [points, setPoints] = useState(answer.points)

  useDebounce(
    () => {
      const toSetTextOutput = isGeneratedText ? textOutput : undefined
      if (
        answer.title !== title ||
        answer.points !== points ||
        !isEqual(answer.textOutput, toSetTextOutput)
      ) {
        onUpdateAnswer(answer.id, { title, textOutput: toSetTextOutput, points })
      }
    },
    EDIT_QUESTION_DEBOUNCE_LATENCY,
    [answer, title, isGeneratedText, textOutput, points],
  )

  function handlePointsChange(newPointsValue: string) {
    setPoints(parseFloat(newPointsValue))
  }

  function handleGeneratedTextButtonClick() {
    setIsGeneratedText(!isGeneratedText)
  }

  function handleTitleChange(newTitleValue: string) {
    setTitle(newTitleValue)
  }

  function handleDelete() {
    onDeleteAnswer(answer)
  }

  function handleAddImage(files: File[]) {
    handleToggleDropzone()
    if (files.length > 0) {
      onAddImage(answer, files[0])
    }
  }

  function handleToggleDropzone(event?: MouseEvent<HTMLElement>) {
    if (event) {
      event.stopPropagation()
    }
    setDisplayDropzone(!displayDropzone)
  }

  function onClickFieldOptionImage(event: MouseEvent<HTMLElement>) {
    event.stopPropagation()
    if (answer.imageFile) {
      onRemoveImage(answer)
    } else {
      handleToggleDropzone()
    }
  }

  return (
    <div date-test-id={`answer_configuration-${answer.id}`}>
      <div className={styles.answer}>
        <AnswerImage imageData={answer.imageFile} onClick={onClickFieldOptionImage} />
        <div className={styles.labelWrapper}>
          <div className={styles.label}>
            <Input
              label="Nom du choix"
              name="label"
              value={title}
              onChange={(event) => handleTitleChange(event.currentTarget.value)}
              colorPreset="dark"
              autofocus={isDefaultAnswerTitle(answer)}
              disabled={!isEditable}
              testId={`input-answer-title-${answer.id}`}
            />
          </div>
          {canEditPoints && (
            <div className={styles.points}>
              <Input
                type="number"
                label="Points"
                name="points"
                value={`${points}`}
                onChange={(e) => handlePointsChange(e.target.value)}
                colorPreset="dark"
              />
            </div>
          )}
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            theme="dark"
            testId="button-toggle-output"
            label={isGeneratedText ? 'Supprimer un texte généré' : 'Définir un texte généré'}
            onClick={() => handleGeneratedTextButtonClick()}
            disabled={!isEditable}
          />
        </div>
        {isDeletable && (
          <div className={styles.actionsWrapper}>
            <IconButton
              icon="trash"
              theme="transparent"
              testId="button-delete-answer"
              onClick={handleDelete}
              size="micro"
            />
          </div>
        )}
      </div>
      {isGeneratedText && (
        <div className={styles.textOutputEditor}>
          <EditTextOutput
            editorFontFamily={editorFontFamily}
            textOutput={answer.textOutput || undefined}
            onTextChange={setTextOutput}
          />
        </div>
      )}
      {answer.childQuestionIds && answer.childQuestionIds.length > 0 ? (
        <ChildQuestionsLink answer={answer} isChild={isChild}>
          `${answer.childQuestionIds.length} sous questions`
        </ChildQuestionsLink>
      ) : (
        <ChildQuestionsLink answer={answer} isChild={isChild}>
          'Ajouter des sous questions'
        </ChildQuestionsLink>
      )}
      {displayDropzone && (
        <div className={styles.dropZone}>
          <FileDropzone
            type="image"
            multiple={false}
            onDrop={handleAddImage}
            onClose={handleToggleDropzone}
          />
        </div>
      )}
    </div>
  )
}

export default EditAnswer
