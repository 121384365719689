import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { deleteAnswer } from '../../../data/answers'
import { questionsKeys } from '../questions/questions.keys'

interface AnswerMutateVariable {
  id: number
  questionId: number
  questionnaireId?: number
}

export const useDeleteAnswer = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, AnswerMutateVariable>(
    async ({ id }) => {
      const { ok } = await deleteAnswer(id)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la réponse a échoué')
      }
      return null
    },
    {
      onSuccess: (_, { questionId, questionnaireId }) => {
        queryClient.invalidateQueries(questionsKeys.detail(questionId))
        if (questionnaireId) {
          queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
        }
      },
    },
  )
}
