import { Speciality } from '../../../model/Professions'
import { SpecialityActionsType } from './specialities.model'

export const specialitiesActions = {
  getSpecialities: () => ({ type: SpecialityActionsType.GET_LIST_OF_SPECIALITIES }),

  storeSpecialitiesList: (specialitiesList: Speciality[]) => ({
    type: SpecialityActionsType.STORE_LIST_OF_SPECIALITIES,
    specialitiesList,
  }),
}
