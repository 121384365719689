import { restuxPatientTreatmentsApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { RestuxResources } from '../../resources'
import { Treatment } from '../../../model/Treatment'

const resourceName = RestuxResources.patientTreatments

const { actions, reducers, sagas } = restuxCacheFactory<Treatment, Treatment>({
  apiConfig: restuxPatientTreatmentsApiConfig,
  refetchList: true,
  resourceName,
})

export const patientTreatmentsActions = actions
export const patientTreatmentsReducers = reducers
export const patientTreatmentsSagas = sagas
