import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { PatientDetailsProps } from './PatientDetailsPage.model'
import styles from './PatientDetailsPage.module.scss'
import { PatientDetailsPageHeader, PatientForm } from '../../../../components/patient'
import { VitalCardBeneficiariesModal } from '../../../../components/shared/modal/VitalCardBeneficiariesModal'
import { NavBar } from '../../../../components/layout'
import { LoadingPage } from '../../../../components/shared'
import { useNavigate } from 'react-router-dom'
import { isDefined } from '../../../../misc/functions.utilities'
import { Patient } from '../../../../model/Patient'
import { useCurrentPatient } from '../../../../hooks/utils'
import { VitalCardDifferencesModal } from '../../../../components/shared/modal/VitalCardDifferencesModal'

export const PatientDetailsPage: FunctionComponent<PatientDetailsProps> = ({
  vitalCardPatient,
  setVitalCardPatient,
  openBottomPanelResourceHistory,
  vitalCardStatus,
  setBeneficiariesListVitalCard,
  openPatientWithVitalCard,
  beneficiaries,
}) => {
  const navigate = useNavigate()
  const { currentPatient, isLoadingCurrentPatient } = useCurrentPatient()
  const [currentPatientWithVitalCardInfo, setCurrentPatientWithVitalCardInfo] = useState<
    Partial<Patient> | null | undefined
  >()

  useEffect(() => {
    if (currentPatient && !currentPatient.isEditable) {
      navigate(-1)
    }
  }, [navigate, currentPatient])

  useEffect(() => {
    if (!isLoadingCurrentPatient) {
      setCurrentPatientWithVitalCardInfo(
        isDefined(currentPatient) || vitalCardPatient !== null
          ? { ...currentPatient, ...vitalCardPatient }
          : null,
      )
    }
  }, [currentPatient, isLoadingCurrentPatient, vitalCardPatient])

  useEffect(
    () => () => {
      setVitalCardPatient(null)
    },
    [setVitalCardPatient],
  )

  const openPatientInformationsHistory = useCallback(() => {
    if (currentPatientWithVitalCardInfo && currentPatientWithVitalCardInfo.id) {
      openBottomPanelResourceHistory({ id: currentPatientWithVitalCardInfo.id, type: 'patient' })
    }
  }, [openBottomPanelResourceHistory, currentPatientWithVitalCardInfo])

  return currentPatientWithVitalCardInfo === undefined ? (
    <LoadingPage />
  ) : (
    <>
      <NavBar backButtonPath={null} backButtonTitle="Retour">
        <PatientDetailsPageHeader
          patient={currentPatientWithVitalCardInfo}
          isLoading={false}
          openPatientInformationsHistory={openPatientInformationsHistory}
        />
      </NavBar>
      <div className={styles.content}>
        <PatientForm patient={currentPatientWithVitalCardInfo} />
      </div>
      {vitalCardStatus && (
        <>
          <VitalCardBeneficiariesModal
            setBeneficiariesListVitalCard={setBeneficiariesListVitalCard}
            openPatientWithVitalCard={openPatientWithVitalCard}
            display={beneficiaries.length > 1}
            beneficiaries={beneficiaries}
            setVitalCardPatient={setVitalCardPatient}
            isUpdate={true}
          />
          <VitalCardDifferencesModal />
        </>
      )}
    </>
  )
}
