import { connect } from 'react-redux'
import { RootState } from '../../../store'
import {
  inUseContextualMenuQuestionnaireSelector,
  documentContextualMenuActions,
  loadingInUseContextualMenuQuestionnaireSelector,
} from '../../../store/ui/documentContextualMenu'
import { QuestionnaireVariableConfiguration } from './QuestionnaireVariableConfiguration.component'
import {
  QuestionnaireVariableConfigurationDispatchProps,
  QuestionnaireVariableConfigurationStoreProps,
} from './QuestionnaireVariableConfiguration.model'

const mapStateToProps = (state: RootState): QuestionnaireVariableConfigurationStoreProps => ({
  isQuestionnaireLoading: loadingInUseContextualMenuQuestionnaireSelector(state),
  inUseQuestionnaire: inUseContextualMenuQuestionnaireSelector(state),
})
const mapDispatchToProps: QuestionnaireVariableConfigurationDispatchProps = {
  selectQuestionnaire: documentContextualMenuActions.useId,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireVariableConfiguration)
