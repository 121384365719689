import { createSelector } from 'reselect'
import { MedicalEvent, MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { Questionnaire } from '../../../model/Questionnaire'
import { RootState } from '../../reducers/index'
import { PageList, RestuxLoadingState } from '../../restux/ui/index'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import {
  isInUseMedicalEventFileLoadingSelector,
  isInUseMedicalEventContentLoadingSelector,
  inUseMedicalEventContentIdSelector,
} from './medicalEventContent'
import {
  inUseMedicalEventDocumentSelector,
  isInUseMedicalEventDocumentInstanceLoadingSelector,
} from './medicalEventDocumentInstances'
import { inUseMedicalEventFileSelector } from './medicalEventFiles'
import { SelectedMedicalEventDocumentWrapper } from './medicalEvents.model'
import { IdentityIdentifier } from '../../restux/restux.model'

export function medicalEventsPageSelector(state: RootState): PageList<MedicalEvent> {
  const medicalEventsResource = state.cache.medicalEvents.list
  const pagination = state.ui.medicalEvent.pagination

  return restuxResolvePage(medicalEventsResource, pagination)
}

export function inUseMedicalEventIdSelector(state: RootState): IdentityIdentifier | null {
  return state.ui.medicalEvent.inUse.inUseId
}
export function inUseMedicalEventSelector(state: RootState): MedicalEvent | undefined {
  const inUsedId = inUseMedicalEventIdSelector(state)
  if (!inUsedId) return undefined
  return state.cache.medicalEvents.details[inUsedId]
}

export function isMedicalEventInUseLoadingSelector(state: RootState): RestuxLoadingState {
  return state.ui.medicalEvent.inUse.loadingState
}

export function shouldDisplayAddFileDropzoneSelector(state: RootState) {
  return state.ui.medicalEvent.addFile.displayDropzone
}

export const isMedicalEventContentLoadingSelector = createSelector(
  [
    isInUseMedicalEventContentLoadingSelector,
    isInUseMedicalEventDocumentInstanceLoadingSelector,
    isInUseMedicalEventFileLoadingSelector,
    isMedicalEventInUseLoadingSelector,
  ],
  (
    isInUseMedicalEventContentLoading,
    isInUseMedicalEventDocumentInstanceLoading,
    isInUseMedicalEventFileLoading,
    isMedicalEventInUseLoading,
  ) => {
    return (
      isInUseMedicalEventContentLoading !== RestuxLoadingState.IDLE ||
      isInUseMedicalEventDocumentInstanceLoading !== RestuxLoadingState.IDLE ||
      isInUseMedicalEventFileLoading !== RestuxLoadingState.IDLE ||
      isMedicalEventInUseLoading !== RestuxLoadingState.IDLE
    )
  },
)

export function inUseDocumentQuestionnaireSelector(state: RootState): Questionnaire | null {
  const inUseId = inUseMedicalEventContentIdSelector(state)
  if (!inUseId) {
    return null
  }
  const selectedContent = selectedMedicalEventDocumentSelector(state)
  if (
    !selectedContent ||
    selectedContent.type !== MedicalEventDocumentType.FW_DOCUMENT ||
    selectedContent.item.type !== 'farte'
  ) {
    return null
  }
  return selectedContent.item.questionnaires.find(({ id }) => id === inUseId) || null
}

export function medicalEventDocumentFilterSelector(state: RootState) {
  return state.ui.medicalEvent.filters.documentInstance
}

export function isMedicalEventDocumentSelected(state: RootState) {
  return !!medicalEventDocumentFilterSelector(state)
}

/**
 * Pour la page de medicalEvent. Return le document selectionne
 * Peut être :
 *  - La note de l'exam
 *  - Un bilan/CRO/document
 *  - Un fichier
 *
 * Si rien n'est trouve return "null"
 */
export const selectedMedicalEventDocumentSelector = createSelector(
  [
    inUseMedicalEventSelector,
    medicalEventDocumentFilterSelector,
    inUseMedicalEventDocumentSelector,
    inUseMedicalEventFileSelector,
  ],
  (
    inUseMedicalEvent,
    medicalEventDocumentFilter,
    documentInstance,
    file,
  ): SelectedMedicalEventDocumentWrapper | null => {
    if (!inUseMedicalEvent || !medicalEventDocumentFilter) {
      return null
    }

    switch (medicalEventDocumentFilter.medicalEventDocumentType) {
      case MedicalEventDocumentType.FW_DOCUMENT:
        if (!documentInstance) {
          return null
        }
        return {
          type: medicalEventDocumentFilter.medicalEventDocumentType,
          item: documentInstance,
        }
      case MedicalEventDocumentType.FILE:
        if (!file) {
          return null
        }
        return { type: medicalEventDocumentFilter.medicalEventDocumentType, item: file }
      case MedicalEventDocumentType.OBSERVATIONS:
        return { type: medicalEventDocumentFilter.medicalEventDocumentType }
    }

    return null
  },
)

export const selectedDocumentQuestionnairesSelector = createSelector(
  [selectedMedicalEventDocumentSelector],
  (medicalEventDocument): Questionnaire[] | null => {
    if (
      !medicalEventDocument ||
      medicalEventDocument.type !== MedicalEventDocumentType.FW_DOCUMENT ||
      medicalEventDocument.item.type !== 'farte'
    ) {
      return null
    }

    return medicalEventDocument.item.questionnaires
  },
)

export const selectedQuestionnaireSelector = createSelector(
  [selectedMedicalEventDocumentSelector, inUseDocumentQuestionnaireSelector],
  (documentInstance, inUseQuestionnaire): Questionnaire | null => {
    if (
      !documentInstance ||
      documentInstance.type !== MedicalEventDocumentType.FW_DOCUMENT ||
      !inUseQuestionnaire
    ) {
      return null
    }
    return inUseQuestionnaire
  },
)

export const selectedMedicalEventDocumentCategorySelector = createSelector(
  [inUseMedicalEventSelector],
  (medicalEvent) => {
    if (!medicalEvent) {
      return null
    }

    return medicalEvent.category
  },
)

/**
 * Retourne l'identifiant du questionnaire suivant dans la liste des questionnaire de l'événement médical
 */
export const nextQuestionnaireIdSelector = createSelector(
  [selectedDocumentQuestionnairesSelector, selectedQuestionnaireSelector],
  (questionnaires, currentQuestionnaire): number | null => {
    if (!questionnaires || questionnaires.length === 0) {
      return null
    }

    // Si pas de questionnaire selectionné. On retourne le premier
    if (!currentQuestionnaire) {
      return questionnaires[0].id
    }

    const currentQuestionnaireIndex = questionnaires.findIndex(
      ({ id }) => id === currentQuestionnaire.id,
    )

    if (currentQuestionnaireIndex === -1) {
      return null
    }

    // Le questionnaire suivant dans la liste des questionnaires de l'événement medical
    const nextQuestionnaire = questionnaires[currentQuestionnaireIndex + 1]
    if (!nextQuestionnaire) {
      return null
    }
    return nextQuestionnaire.id
  },
)

export function displaySendFileModalSelector(state: RootState) {
  return state.ui.medicalEvent.sendFile.displaySendFileModal
}
