import { StarterKit } from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import ListKeymap from '@tiptap/extension-list-keymap'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Table from '@tiptap/extension-table'
import { BlockSpacing } from './blockSpacing'
import { CursorControl } from './cursorControl'
import { FontSize } from './fontSize'
import { FontFamilyExtended } from './fontfamilyExtended'
import { ImageResize } from './imageResize'
import { Indent } from './indent'
import { LineHeight } from './lineHeight'
import { PageBreak } from './pageBreak'
import { TextColor } from './textColor'
import { DEFAULT_FONT_FAMILY, FontFamily } from '@/model/Font'
import { ClassName } from './className'
import { EDITOR_ALL_TOOLS, EditorTool } from '@/model/Toolbar'
import { Extensions } from '@tiptap/react'

export interface CommonPluginsParams {
  defaultFontFamily?: FontFamily
  tools?: Array<EditorTool> | ReadonlyArray<EditorTool>
}

export const getCommonPlugins = ({
  defaultFontFamily = DEFAULT_FONT_FAMILY,
  tools = EDITOR_ALL_TOOLS,
}: CommonPluginsParams) => {
  const plugins: Extensions = [CursorControl, Indent, ClassName, TextStyle]

  plugins.push(
    StarterKit.configure({
      heading: tools.includes('heading') ? undefined : false,
      bold: tools.includes('marks') ? undefined : false,
      italic: tools.includes('marks') ? undefined : false,
      strike: tools.includes('marks') ? undefined : false,
      bulletList: tools.includes('list') ? undefined : false,
      orderedList: tools.includes('list') ? undefined : false,
      listItem: tools.includes('list') ? undefined : false,
    }),
  )

  if (tools.includes('marks')) {
    plugins.push(Underline)
  }

  if (tools.includes('fontFamily')) {
    plugins.push(
      FontFamilyExtended.configure({
        defaultFontFamily,
      }),
    )
  }

  if (tools.includes('fontSize')) {
    plugins.push(FontSize)
  }

  if (tools.includes('colors')) {
    plugins.push(TextColor)
  }
  if (tools.includes('align')) {
    plugins.push(
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    )
  }
  if (tools.includes('list')) {
    plugins.push(ListKeymap)
  }
  if (tools.includes('lineHeight')) {
    plugins.push(LineHeight, BlockSpacing)
  }
  if (tools.includes('pagebreak')) {
    plugins.push(PageBreak)
  }
  if (tools.includes('table')) {
    plugins.push(
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
    )
  }
  if (tools.includes('image')) {
    plugins.push(
      ImageResize.configure({
        inline: true,
      }),
    )
  }

  return plugins
}
