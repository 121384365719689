import { useField } from 'formik'
import React, { ChangeEvent } from 'react'
import { Input } from '../../Input'
import {
  inputInseeNumberFormatter,
  inseeNumberValidator,
} from '../../../../../misc/inseeNumber.utils'
import { FormikInput } from './Input.model'

export const FormikSecuriteSocialNumberInput = React.forwardRef<HTMLDivElement, FormikInput>(
  ({ label, fieldName, placeholder, disabled, colorPreset, onChange }, ref) => {
    const [field, meta, helpers] = useField({
      name: fieldName,
      validate: (value) => {
        if (!inseeNumberValidator(value)) {
          return 'Numéro invalide'
        }
      },
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      helpers.setTouched(false)
      helpers.setValue(event.target.value)
      if (onChange) onChange(event.target.value)
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const formated = inputInseeNumberFormatter(event.currentTarget.value)
      helpers.setTouched(true)
      helpers.setValue(formated)
      field.onBlur(event)
    }

    return (
      <Input
        ref={ref}
        value={field.value}
        label={label}
        name={fieldName}
        placeholder={placeholder}
        valid={meta.touched ? !meta.error : undefined}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        colorPreset={colorPreset}
        error={meta.touched ? meta.error : undefined}
      />
    )
  },
)
