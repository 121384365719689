import { useAtom } from 'jotai'
import { editQuestionAtom } from './editQuestion.atom'
import { useGetQuestion } from '../../hooks/queries/questions'
import { RESET } from 'jotai/utils'
import { useCallback } from 'react'

export const useEditQuestion = () => {
  const [editQuestion, setEditQuestion] = useAtom(editQuestionAtom)

  const {
    query: { data, isLoading },
  } = useGetQuestion({
    enabled: editQuestion.selectedQuestionId !== null,
    id: editQuestion.selectedQuestionId as number,
  })

  const setQuestion = useCallback(
    ({ id }: { id: number }): void => {
      setEditQuestion({
        selectedQuestionId: id,
      })
    },
    [setEditQuestion],
  )

  const resetQuestion = useCallback(() => setEditQuestion(RESET), [setEditQuestion])

  return { setQuestion, question: data, isLoadingQuestion: isLoading, resetQuestion }
}
