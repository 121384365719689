import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Question } from '../../../model/Questionnaire'
import { UserFriendlyError } from '../../../model/Error'

import { QuestionMutationPayload } from '../../../data/questions/mapper/questions.model'
import { createQuestion } from '../../../data/questions'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { questionsKeys } from './questions.keys'

export const useCreateQuestion = () => {
  const queryClient = useQueryClient()

  return useMutation<Question, Error, QuestionMutationPayload>(
    async (payload) => {
      const { data, ok } = await createQuestion(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de la question a échoué')
      }
      return data
    },
    {
      onSuccess: (question) => {
        queryClient.invalidateQueries(questionsKeys.lists)
        question.questionnaires.map(({ id }) =>
          queryClient.invalidateQueries(questionnairesKeys.detail(id)),
        )
      },
    },
  )
}
