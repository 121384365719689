import React, { FunctionComponent, useState } from 'react'

import { QuestionListProps } from './QuestionList.model'
import { QuestionCard } from '../Questions/QuestionCard.component'
import styles from './QuestionList.module.scss'
import { QuestionDragLayer, SortableQuestion } from './SortableQuestion.component'

export const QuestionList: FunctionComponent<QuestionListProps> = ({
  mode,
  onAddSubQuestion,
  onEdit,
  onDelete,
  onDeleteSubQuestion,
  questions,
  disabled = false,
  isReadonly,
  setAnswerValue,
  setSubQuestionsAnswerValues,
  onMoveQuestion,
  renderAfter,
}) => {
  const [isDraggingQuestion, setIsDraggingQuestion] = useState(false)

  return (
    <>
      {isDraggingQuestion && <QuestionDragLayer />}
      <div className={styles.list}>
        {questions.map((question, index) =>
          onMoveQuestion ? (
            <SortableQuestion
              key={question.id}
              question={question}
              questionType={question.type}
              mode={mode}
              onAddSubQuestion={onAddSubQuestion}
              onDeleteSubQuestion={onDeleteSubQuestion}
              onEdit={onEdit}
              onDelete={onDelete}
              setIsDragging={setIsDraggingQuestion}
              disabled={true}
              isReadonly={
                isReadonly || question.isEditable === false || question.isEditable === undefined
              }
              order={index}
              onMoveQuestion={onMoveQuestion}
            />
          ) : (
            <QuestionCard
              key={question.id}
              question={question}
              questionType={question.type}
              mode={mode}
              onAddSubQuestion={onAddSubQuestion}
              onDeleteSubQuestion={onDeleteSubQuestion}
              onEdit={onEdit}
              onDelete={onDelete}
              answerValues={question.answerValues}
              setAnswerValue={(answerValue) =>
                setAnswerValue && setAnswerValue(question.id, answerValue)
              }
              setSubQuestionsAnswerValues={setSubQuestionsAnswerValues}
              disabled={disabled}
              isReadonly={
                isReadonly || question.isEditable === false || question.isEditable === undefined
              }
            />
          ),
        )}
        {renderAfter && <div className={styles.questionContainer}>{renderAfter}</div>}
      </div>
    </>
  )
}
