import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getAmenorrheaText } from '@follow/cdk'
import { AccordionItem, Badge, IconButton, ValidationModal } from '../../shared'
import { useLocalStorage } from '../../../hooks/utils'
import { breakSmall } from '../../../misc/responsive'
import { FwTrackingEvent, withTrackingEvent } from '../../../misc/Tracking'
import { withWidthLowerObserver } from '../../../misc/WidthObserver.hoc'
import { PatientImportantInformations } from './PatientImportantInformations/index'
import { PatientInfosProps } from './PatientInfos.model'
import styles from './PatientInfos.module.scss'
import { PatientTags } from './PatientTags'
import { PatientNotes } from './PatientNotes'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { JSONToValue, valueToJSON, EditorValue } from '@follow/farte'
import classNames from 'classnames'
import { hasPermissionAction } from '../../../misc/entity.utilities'
import { SharingConfigAccessLevel } from '../../../model/Team'
import { PatientInformationsItem } from './PatientInformationsItem'
import { PatientHealthData } from './PatientHealthData'
import { PatientInformationsModal, PatientMenuActions } from '../../patient'
import { PatientCurrentTreatments } from './PatientCurrentTreatments'
import { Treatment } from '../../../model/Treatment'
import { PatientManualPrescriptions } from './PatientManualPrescriptions'
import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { isPatientFormReachable } from '../../../misc/patient.utilities'
import { PatientGynecologyData } from './PatientGynecologyData'
import { isDoctor } from '../../../misc/user.utilities'
import { HealthData, Module } from '../../../model/HealthData'
import { GynecologyModal, HealthDataModal } from '../../moduleData'
import { Sex } from '../../../model/Patient'
import { findOngoingPregnancy } from '../../../misc/healthData.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { useGetAllPatientImportantInformations } from '../../../hooks/queries/patientImportantInformations'
import { PatientImportantInformation } from '../../../model/PatientImportantInformations'
import { useUpdatePatient } from '../../../hooks/queries/patients'
import { useOpenPatientHistoric, useCreateEventInPlanning } from '../../../hooks/utils/doctolib'
import { zipperStatusAtom } from '../../../state/doctolib'
import { useAtomValue } from 'jotai'
import { PatientInformations } from '../../../model/PatientInformations'
import {
  useGetPatientInformations,
  useDeletePatientInformations,
} from '../../../hooks/queries/patientInformations'
import {
  useGetInfinitePatientHealthData,
  useGetPatientHealthDataTypes,
} from '../../../hooks/queries/patientHealthData'
import { PatientGeneralInfosComponent } from './PatientGeneralInfos'

const withTrackingAccordionEvent = (section: string) =>
  withTrackingEvent(FwTrackingEvent.TOGGLE_PATIENT_INFO_ACCORDION, {
    section,
  })

export const PatientInfosComponent: FunctionComponent<PatientInfosProps> = ({
  patient,
  currentTreatments,
  enabledFeatures,
  manualPrescriptions,
  openEditContactForm,
  getCurrentTreatments,
  clearCurrentTreatments,
  openPosolosyBottomPanel,
  openPosologyBottomPanelFromPrescription,
  openTreatmentsHistoryBottomPanel,
  searchDrug,
  deleteCurrentTreatment,
  selectMedicalEventDocument,
  terminateTreatment,
  getManualPrescriptions,
  clearManualPrescriptions,
  copyTextToClipboard,
}) => {
  const doctolibStatus = useAtomValue(zipperStatusAtom)
  const { triggerOpenPatientHistoric } = useOpenPatientHistoric()
  const { triggerCreateEventInPlanning } = useCreateEventInPlanning()
  const { patientImportantInformationList } = useGetAllPatientImportantInformations({
    patientId: patient.id,
  })

  const { healthData } = useGetInfinitePatientHealthData({
    patientId: patient.id,
  })
  const { mutate: updatePatient } = useUpdatePatient()
  const [showHealthData, setShowHealthData] = useState(false)
  const [showGynecologicyModal, setShowGynecologicyModal] = useState(false)
  const [openImportant, setOpenImportant] = useLocalStorage<boolean>(
    'ui.accordionImportant.open',
    true,
  )
  const { healthDataTypes, indexedTypes } = useGetPatientHealthDataTypes()

  const [openNotes, setOpenNotes] = useLocalStorage<boolean>('ui.accordionNotes.open', true)
  const [openTags, setOpenTags] = useLocalStorage<boolean>('ui.accordionTags.open', true)
  const [openPatientInformations, setOpenPatientInformations] = useState<boolean>(false)
  const [localPatientNotes, setLocalePatientNotes] = useState<EditorValue>(
    JSONToValue(patient.note),
  )
  const [treatmentToDelete, setTreatmentToDelete] = useState<Treatment | undefined>()
  const [patientInformationsUpdate, setPatientInformationsUpdate] =
    useState<PatientInformations | null>(null)

  const navigate = useNavigate()
  const { medicalEventId: eventIdParam } = useParams<'medicalEventId'>()
  const setOpenImportantAccordion = withTrackingAccordionEvent('important')(setOpenImportant)
  const setOpenNotesAccordion = withTrackingAccordionEvent('notes')(setOpenNotes)
  const setOpenTagsAccordion = withTrackingAccordionEvent('tags')(setOpenTags)

  const { loggedUser, currentUser } = useContext(ConnectedUserContext)

  const {
    query: { data: patientInformations = [] },
  } = useGetPatientInformations(patient.id)

  const { mutate: deletePatientInformations } = useDeletePatientInformations()

  const gynecologyData = useMemo(
    () =>
      healthData.filter(
        (listItem) =>
          listItem.module === Module.GYNECOLOGICAL || listItem.module === Module.GYNECO_OBSTETRIC,
      ),
    [healthData],
  )

  const pregnancyData = useMemo(() => {
    const ongoingPregnancy = findOngoingPregnancy(healthData)

    if (!ongoingPregnancy?.value?.value) {
      return []
    }

    const {
      pregnancyType,
      twinPregnancy,
      lastMenstrualPeriod,
      pregnancyStart,
      dueDate,
      amenorrhea: amenorrheaValue,
    } = ongoingPregnancy.value.value

    const {
      twin_pregnancy,
      due_date,
      pregnancy_type,
      last_menstrual_period,
      pregnancy_start,
      amenorrhea: amenorrheaType,
    } = indexedTypes

    const pregnancyDatum = [
      { value: pregnancyType, type: pregnancy_type },
      { value: twinPregnancy, type: twin_pregnancy },
      { value: lastMenstrualPeriod, type: last_menstrual_period },
      { value: pregnancyStart, type: pregnancy_start },
      { value: dueDate, type: due_date },
      {
        value: amenorrheaValue && getAmenorrheaText(amenorrheaValue),
        type: amenorrheaType,
      },
    ]

    const mapped = pregnancyDatum
      .map<HealthData | null>(({ value, type }) =>
        value && type
          ? ({
              ...ongoingPregnancy,
              value: { value },
              moduleDataType: type,
            } as HealthData)
          : null,
      )
      .filter(isDefined)

    return mapped
  }, [healthData, indexedTypes])

  const genericHealthData = useMemo(
    () => healthData.filter((listItem) => listItem.module === Module.HEALTH_DATA),
    [healthData],
  )

  useEffect(() => {
    getCurrentTreatments(patient.id)
    return () => {
      clearCurrentTreatments()
    }
  }, [patient.id, getCurrentTreatments, clearCurrentTreatments])

  useEffect(() => {
    getManualPrescriptions({
      page: { currentPage: 1, pageSize: 100 },
      params: { patientId: `${patient.id}` },
    })

    return () => {
      clearManualPrescriptions()
    }
  }, [getManualPrescriptions, patient.id, clearManualPrescriptions])

  useDebounce(
    () => {
      if (
        isDefined(localPatientNotes) &&
        JSON.stringify(localPatientNotes) !== JSON.stringify(patient.note)
      ) {
        updatePatient({ id: patient.id, payload: { note: valueToJSON(localPatientNotes) } })
      }
    },
    500,
    [localPatientNotes, patient.note],
  )

  const handleDeleteTreatment = useCallback(() => {
    if (treatmentToDelete?.id) {
      deleteCurrentTreatment(treatmentToDelete.id, {
        params: { patientId: `${patient.id}` },
        refetchList: false,
      })
      setTreatmentToDelete(undefined)
    }
  }, [treatmentToDelete, patient.id, setTreatmentToDelete, deleteCurrentTreatment])

  const handleNavigateToDocumentInstance = (eventId: number, documentId: number) => {
    if (eventIdParam && parseInt(eventIdParam) === eventId) {
      selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
        id: documentId,
      })
    } else {
      navigate(`/patients/${patient.id}/medicalEvent/${eventId}?docId=${documentId}`)
    }
  }

  const isFormLinkVisible = isPatientFormReachable(currentUser, loggedUser)

  const getNbOfImportantInformation = (list: PatientImportantInformation[]) => {
    return list.reduce((nb, item) => nb + item.importantInformations.length, 0)
  }

  const handlePatientInformationsEdition = useCallback(
    (id: string) => {
      const foundPatientInformations = patientInformations.find(
        (patientInfo) => patientInfo.id === id,
      )
      foundPatientInformations && setPatientInformationsUpdate(foundPatientInformations)
      setOpenPatientInformations(true)
    },
    [patientInformations],
  )

  const handleDeletePatientInformations = useCallback(
    (patientInformationsId: string) =>
      deletePatientInformations({ patientId: patient.id, patientInformationsId }),
    [deletePatientInformations, patient.id],
  )

  return (
    <div>
      <AccordionItem
        renderLabel={() => (
          <div className={styles.accordionTitle}>
            <span className={styles.titleSpan}>Informations générales</span>
            <div className={styles.actions}>
              {patient.isEditable && isFormLinkVisible && (
                <Link to={`/patients/${patient.id}/details`}>
                  <IconButton
                    icon="pencil"
                    title="Editer les informations"
                    theme="transparent-dark"
                    size="micro"
                    testId="button-edit-patient"
                  />
                </Link>
              )}
              <PatientMenuActions
                doctolibStatus={doctolibStatus}
                theme="transparent-dark"
                onCreateEvent={() => triggerCreateEventInPlanning({ patientId: patient.id })}
                onOpenPatientHistoric={() => triggerOpenPatientHistoric({ patientId: patient.id })}
              />
            </div>
          </div>
        )}
      >
        <div className={styles.patientInfosContainer}>
          <PatientGeneralInfosComponent
            patient={patient}
            openEditContactForm={openEditContactForm}
            copyTextToClipboard={copyTextToClipboard}
          />
        </div>
      </AccordionItem>

      {enabledFeatures?.drug && (
        <AccordionItem
          testId="patient-infos-accordion-item-treatments"
          overflow={true}
          renderLabel={() => (
            <div className={styles.accordionTitle}>
              <div className="flex flex-row items-center">
                <span className={classNames(styles.titleSpan, styles.withBadge)}>
                  Traitements en cours
                </span>
                <Badge value={currentTreatments.items.length} />
              </div>
              {patient.isEditable && (
                <div className={styles.actions}>
                  <IconButton
                    icon="history"
                    title="Voir l'historique médicamenteux"
                    theme="transparent-dark"
                    size="micro"
                    testId="button-open-treatment-history"
                    onClick={() => openTreatmentsHistoryBottomPanel()}
                  />
                  <IconButton
                    icon="add"
                    title="Saisir manuellement un traitement en cours"
                    theme="transparent-dark"
                    size="micro"
                    testId="button-add-manual-treatment"
                    onClick={() => {
                      searchDrug((selectedDrug) => {
                        openPosolosyBottomPanel({ mode: 'create', selectedDrug })
                      })
                    }}
                  />
                </div>
              )}
            </div>
          )}
        >
          <PatientCurrentTreatments
            currentTreatments={currentTreatments.items}
            openPosolosyBottomPanel={openPosologyBottomPanelFromPrescription}
            onDeleteRequest={setTreatmentToDelete}
            navigateToDocument={handleNavigateToDocumentInstance}
            terminateTreatment={terminateTreatment}
            isEditable={patient.isEditable ?? true}
          />
        </AccordionItem>
      )}
      {enabledFeatures?.drug && (
        <AccordionItem
          overflow={true}
          renderLabel={() => (
            <div className={styles.accordionTitle}>
              <div className="flex flex-row items-center">
                <span className={classNames(styles.titleSpan, styles.withBadge)}>
                  Prescriptions libres
                </span>
                <Badge value={manualPrescriptions.items.length} />
              </div>
            </div>
          )}
        >
          <PatientManualPrescriptions
            manualPrescriptions={manualPrescriptions}
            navigateToDocument={handleNavigateToDocumentInstance}
          />
        </AccordionItem>
      )}
      <AccordionItem
        testId="patient-infos-accordion-item-health-data"
        renderLabel={() => (
          <div className={styles.accordionTitle}>
            <span className={styles.titleSpan}>Informations médicales</span>
            <div className={styles.actions}>
              {patient.isEditable && (
                <IconButton
                  icon="pencil"
                  title="Editer les informations"
                  theme="transparent-dark"
                  size="micro"
                  testId="button-edit-health-data"
                  onClick={() => setShowHealthData(true)}
                />
              )}
            </div>
          </div>
        )}
      >
        <PatientHealthData list={genericHealthData} types={healthDataTypes} />
      </AccordionItem>
      {((patient.sex !== Sex.MALE && enabledFeatures?.gynecology) ||
        gynecologyData.length > 0 ||
        pregnancyData?.length > 0) && (
        <AccordionItem
          renderLabel={() => (
            <div className={styles.accordionTitle}>
              <span className={styles.titleSpan}>Informations gynécologiques</span>
              <div className={styles.actions}>
                {patient.isEditable &&
                  isDoctor(currentUser) &&
                  currentUser.preferences.enabledFeatures.gynecology && (
                    <IconButton
                      icon="pencil"
                      title="Editer les informations"
                      theme="transparent-dark"
                      size="micro"
                      testId="button-edit-gynecology-data"
                      onClick={() => setShowGynecologicyModal(true)}
                    />
                  )}
              </div>
            </div>
          )}
        >
          <PatientGynecologyData
            list={[...gynecologyData, ...pregnancyData]}
            types={healthDataTypes}
          />
          <GynecologyModal
            patientId={patient.id}
            display={showGynecologicyModal}
            onClose={() => setShowGynecologicyModal(false)}
          />
        </AccordionItem>
      )}
      <AccordionItem
        testId="patient-infos-accordion-item-allergies"
        overflow
        renderLabel={() => (
          <div className={styles.accordionTitle}>
            <div className="flex flex-row items-center">
              <span className={classNames(styles.titleSpan, styles.withBadge)}>
                Allergies & antécédents
              </span>
              <Badge value={patientInformations.length} />
            </div>
            {patient.isEditable && (
              <div className={styles.actions}>
                <IconButton
                  icon="add"
                  title="Ajouter une allergie ou un antécédent"
                  theme="transparent-dark"
                  size="micro"
                  testId="button-add-allergy"
                  onClick={() => {
                    setOpenPatientInformations(true)
                    setPatientInformationsUpdate(null)
                  }}
                />
              </div>
            )}
          </div>
        )}
      >
        <PatientInformationsItem
          list={patientInformations}
          editPatientInformations={handlePatientInformationsEdition}
          deleteItem={handleDeletePatientInformations}
          isEditable={patient.isEditable ?? true}
        />
      </AccordionItem>
      <AccordionItem
        open={openImportant}
        onOpenClose={() => setOpenImportantAccordion(!openImportant)}
        renderLabel={() => (
          <>
            <span className={classNames(styles.withBadge, styles.titleSpan)}>
              Informations Importantes
            </span>
            <Badge value={getNbOfImportantInformation(patientImportantInformationList)} />
          </>
        )}
      >
        <PatientImportantInformations list={patientImportantInformationList} />
      </AccordionItem>
      <AccordionItem
        open={openTags}
        onOpenClose={() => setOpenTagsAccordion(!openTags)}
        renderLabel={() => (
          <>
            <span className={classNames(styles.withBadge, styles.titleSpan)}>Labels</span>
            <Badge value={patient.tagIds.length} />
          </>
        )}
        overflow={true}
      >
        <PatientTags tagIds={patient.tagIds} disabled={!patient.isEditable} />
      </AccordionItem>
      <AccordionItem
        testId="patient-infos-accordion-item-notes"
        open={openNotes}
        onOpenClose={() => setOpenNotesAccordion(!openNotes)}
        renderLabel={() => (
          <span className={classNames(styles.withBadge, styles.titleSpan)}>Notes</span>
        )}
      >
        <PatientNotes
          isEditable={hasPermissionAction(SharingConfigAccessLevel.write, patient)}
          patientNote={localPatientNotes}
          onChange={setLocalePatientNotes}
        />
      </AccordionItem>
      <HealthDataModal
        display={showHealthData}
        onClose={() => setShowHealthData(false)}
        patient={patient}
      />
      <PatientInformationsModal
        display={openPatientInformations}
        onClose={() => setOpenPatientInformations(false)}
        patientId={patient.id}
        patientInformationsUpdate={patientInformationsUpdate}
      />
      <ValidationModal
        display={!!treatmentToDelete}
        title={`Voulez vous vraiment supprimer ce traitement en cours ?`}
        onClose={() => setTreatmentToDelete(undefined)}
        onSubmit={handleDeleteTreatment}
      />
    </div>
  )
}

export const PatientInfos = withWidthLowerObserver(breakSmall)(PatientInfosComponent)
