import React, { FunctionComponent, useEffect } from 'react'
import { Radio, RadioGroup, Loader } from '../../../components/shared'
import { EditorVariableDisplayConfig } from '@follow/cdk'
import { RestuxLoadingState } from '../../../store/restux/ui'
import { CommonVariableActions } from '../CommonVariableActions'
import { QuestionnaireVariableConfigurationProps } from './QuestionnaireVariableConfiguration.model'
import styles from './QuestionnaireVariableConfiguration.module.scss'

export const QuestionnaireVariableConfiguration: FunctionComponent<
  QuestionnaireVariableConfigurationProps
> = ({
  variableId,
  isQuestionnaireLoading,
  inUseQuestionnaire,
  displayConfig,
  renderTitle,
  selectQuestionnaire,
  onSelectQuestionnaireDisplayConfig,
  ...restProps
}) => {
  useEffect(() => {
    const questionnaireId = variableId.split('_')[1]
    selectQuestionnaire(questionnaireId, { withFetch: true })
  }, [variableId, selectQuestionnaire])

  if (!inUseQuestionnaire || isQuestionnaireLoading !== RestuxLoadingState.IDLE) {
    return (
      <div className={styles.loadingContainer}>
        <Loader isLoading={true} height={70} width={70} />
      </div>
    )
  }

  const handleDisplayConfigChange = (_event, value: string) => {
    switch (value) {
      case `${EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE)
        break
      case `${EditorVariableDisplayConfig.SCORE_VALUE}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.SCORE_VALUE)
        break
      case `${EditorVariableDisplayConfig.HIDDEN}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.HIDDEN)
        break
      case `${EditorVariableDisplayConfig.FULL}`:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.FULL)
        break
      default:
        onSelectQuestionnaireDisplayConfig(EditorVariableDisplayConfig.HIDDEN)
        break
    }
  }
  return (
    <CommonVariableActions {...restProps}>
      {renderTitle}
      <div>
        <RadioGroup
          label="Mode d'affichage"
          name="displayMode"
          onChange={handleDisplayConfigChange}
          value={displayConfig || EditorVariableDisplayConfig.FULL}
        >
          <Radio label="Résultat complet" value={EditorVariableDisplayConfig.FULL} />
          {inUseQuestionnaire.scoreConfig && (
            <>
              <Radio
                label="Titre et score"
                value={EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE}
              />
              <Radio label="Score seul" value={EditorVariableDisplayConfig.SCORE_VALUE} />
            </>
          )}{' '}
          <Radio label="Masqué" value={EditorVariableDisplayConfig.HIDDEN} />
        </RadioGroup>
      </div>
    </CommonVariableActions>
  )
}
