import { PageList } from '../../restux/ui/restuxUi.model'
import { restuxResolvePage } from '../../restux/ui/restuxUi.selectors'
import { RootState } from '../../reducers/index'
import { Treatment } from '../../../model/Treatment'

export const patientTreatmentsPaginationSelector = (state: RootState) =>
  state.ui.patientTreatments.pagination

export const patientTreatmentsPageSelector = (state: RootState): PageList<Treatment> => {
  const pagination = patientTreatmentsPaginationSelector(state)
  const patientTreatmentsResource = state.cache.patientTreatments.list
  return restuxResolvePage(patientTreatmentsResource, pagination)
}
