import { FontFamily } from '@follow/farte'
import { QuestionLight } from '../../../model/Questionnaire'

export const EDIT_QUESTION_DEBOUNCE_LATENCY = 500

export interface EditQuestionPanelOwnProps {
  question: QuestionLight
  onQuestionEdited?: (question: QuestionLight) => void
}

export interface EditQuestionPanelStateProps {
  editorFontFamily: FontFamily
  isExtDsm: boolean
  isAdmin: boolean
}

export type EditQuestionPanelProps = EditQuestionPanelOwnProps & EditQuestionPanelStateProps
