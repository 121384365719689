import { connect } from 'react-redux'
import { RootState } from '../../../../store'
import { bottomPanelBaseVariablesActions } from '../../../../store/ui/bottomPanelBaseVariables'
import { inUseMedicalEventSelector } from '../../../../store/ui/medicalEvents'
import { FileInstanceContent } from './FileInstanceContent.component'
import {
  FileInstanceContentDispatchProps,
  FileInstanceContentStateProps,
} from './FileInstanceContent.model'
import {
  getVariableData,
  isVariableDataLoadingSelector,
  setVariableDataLoading,
  variableDataSelector,
} from '../../../../store/renderer'
import { domainDocumentInstancesActions } from '../../../../store/domain/documentInstances'

const mapStateToProps = (state: RootState): FileInstanceContentStateProps => ({
  medicalEvent: inUseMedicalEventSelector(state),
  variablesData: variableDataSelector(state),
  isVariableDataLoading: isVariableDataLoadingSelector(state),
})

const mapDispatchToProps: FileInstanceContentDispatchProps = {
  updateFileInstance: domainDocumentInstancesActions.updateDocumentInstanceWithVariableDataRefetch,
  searchBaseVariable: bottomPanelBaseVariablesActions.searchBaseVariable,
  getVariableData,
  setVariableDataLoading,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(FileInstanceContent)
export { withConnect as FileInstanceContent }
