import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Question } from '../../../model/Questionnaire'
import { UserFriendlyError } from '../../../model/Error'
import { updateQuestion } from '../../../data/questions'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { QuestionMutationPayload } from '../../../data/questions/mapper/questions.model'
import { questionsKeys } from './questions.keys'

interface QuestionMutateVariable {
  id: number
  payload: QuestionMutationPayload
}

export const useUpdateQuestion = () => {
  const queryClient = useQueryClient()

  return useMutation<Question, Error, QuestionMutateVariable>(
    async ({ id, payload }) => {
      const { data, ok } = await updateQuestion(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la question a échoué')
      }
      return data
    },
    {
      onSuccess: (question) => {
        queryClient.invalidateQueries(questionsKeys.lists)
        queryClient.setQueryData(questionsKeys.detail(question.id), question)
        question.questionnaires.map(({ id }) =>
          queryClient.invalidateQueries(questionnairesKeys.detail(id)),
        )
      },
    },
  )
}
