import { restuxQuestionsApiConfig } from './api'
import { restuxCacheFactory } from '../../restux/cache'
import { Question } from '../../../model/Questionnaire'
import { RestuxResources } from '../../resources'

const { actions, reducers, sagas } = restuxCacheFactory<Question, Question>({
  apiConfig: restuxQuestionsApiConfig,
  refetchList: false,
  resourceName: RestuxResources.question,
})

export const questionsActions = actions
export const questionsReducers = reducers
export const questionsSagas = sagas
