import { useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { CitiesSearch, CountriesSearch } from '../../../location'
import { FormikTextInput } from '../../../shared'
import { City, Country } from '../../../../model/Contact'
import { DisabledINSITooltipWrapper } from '../DisabledINSITootipWrapper'
import { PatientFormFields } from '../PatientForm.model'
import { PatientBirthPlaceCodeBlockProps } from './PatientBirthPlaceCodeBlock.model'
import { CitySourceFilter } from '../../../../store/ui/cities'
import { useResetINSIStatusHook } from '../useResetINSIStatus.hook'
import { isINSIValidated } from '../../../../misc/patient.utilities'

export const PatientBirthPlaceCodeBlock: FC<PatientBirthPlaceCodeBlockProps> = ({
  cities,
  countries,
  selectedCountry,
  patient,
  disabled,
  searchCity,
  searchCountry,
  setSelectedCountry,
}) => {
  const [countrySearch, setCountrySearch] = useState('')

  const { values, setValues } = useFormikContext<PatientFormFields>()

  const [citySearch, setCitySearch] = useState(patient?.birthPlaceCity ?? '')

  const [searchBirthPlaceCodewithFilter, setSearchBirthPlaceCodeWithFilter] = useState(false)

  const resetINSIStatus = useResetINSIStatusHook()

  useEffect(() => {
    if (patient?.birthPlaceCountry) {
      setCountrySearch(patient.birthPlaceCountry)
    } else if (patient?.birthPlaceCode === undefined) {
      setCountrySearch('FRANCE')
    }
  }, [patient])

  const handleSearchCountry = useCallback(() => {
    searchCountry({
      page: { currentPage: 1, pageSize: 10 },
      filters: { search: countrySearch },
    })
  }, [countrySearch, searchCountry])

  const handleSearchCity = useCallback(() => {
    searchCity({
      page: { currentPage: 1, pageSize: 10 },
      filters: {
        search: citySearch,
        country: selectedCountry?.isoCode,
        source: countrySearch === 'FRANCE' ? CitySourceFilter.INSEE : undefined,
      },
    })
  }, [countrySearch, citySearch, searchCity, selectedCountry?.isoCode])

  useDebounce(
    () => {
      handleSearchCountry()
    },
    400,
    [countrySearch],
  )

  useDebounce(
    () => {
      handleSearchCity()
    },
    400,
    [citySearch],
  )

  useEffect(() => {
    if (!selectedCountry && countrySearch === 'FRANCE') {
      handleSearchCity()
    }
  }, [countrySearch, handleSearchCity, selectedCountry])

  const clearCitySearchFilter = () => {
    setCitySearch('')
    setValues({ ...values, birthPlaceCode: '' })
  }

  const clearAllSearchFilter = () => {
    setCountrySearch('')
    setSelectedCountry(null)
    clearCitySearchFilter()
  }

  const handleDisplayFilter = () => {
    if (!searchBirthPlaceCodewithFilter) {
      clearCitySearchFilter()
      setCountrySearch('FRANCE')
    }
    setSearchBirthPlaceCodeWithFilter(!searchBirthPlaceCodewithFilter)
  }

  return (
    <>
      {searchBirthPlaceCodewithFilter && (
        <>
          <CountriesSearch
            search={countrySearch}
            countries={countries.items}
            disabled={disabled}
            onSearch={(search) => {
              setCountrySearch(search)
              if (search === '') {
                setSelectedCountry(null)
                setCitySearch('')
                setValues({
                  ...values,
                  birthPlaceCode: '',
                })
              }
            }}
            onSelect={(item: Country | undefined) => {
              if (item) {
                setSelectedCountry(item.id)
                setCountrySearch(item.name)

                if (item.isoCode === 'FR') {
                  handleSearchCity()
                }

                if (item?.inseeCode) {
                  setValues({
                    ...values,
                    birthPlaceCode: item.inseeCode.toString(),
                  })
                }
              } else {
                clearAllSearchFilter()
              }
            }}
          />
          {(selectedCountry?.isoCode === 'FR' || countrySearch === 'FRANCE') && (
            <CitiesSearch
              search={citySearch}
              cities={cities.items}
              disabled={disabled}
              onSearch={(search) => {
                setCitySearch(search)
                if (search === '') {
                  setValues({
                    ...values,
                    birthPlaceCode: '',
                  })
                }
              }}
              onSelect={(item: City | undefined) => {
                if (item) {
                  if (item?.inseeCode) {
                    setCitySearch(item.name)
                    setValues({
                      ...values,
                      birthPlaceCode: item.inseeCode.toString(),
                    })
                  }
                } else {
                  clearCitySearchFilter()
                }
              }}
            />
          )}
        </>
      )}
      <DisabledINSITooltipWrapper display={isINSIValidated(values.identityStatus)}>
        <div>
          <FormikTextInput
            type="text"
            fieldName="birthPlaceCode"
            label="Code lieu de naissance (INSEE) *"
            disabled={disabled || searchBirthPlaceCodewithFilter}
            colorPreset="light"
            infos={!searchBirthPlaceCodewithFilter ? `Entrez "99999" si inconnu.` : undefined}
            onChange={resetINSIStatus}
          />
          {!disabled && (
            <button
              onClick={handleDisplayFilter}
              className="text-xs bg-transparent text-primary-default hover:text-primary-dark"
            >
              {searchBirthPlaceCodewithFilter
                ? 'Saisir le code lieu de naissance manuellement'
                : 'Effectuer une recherche avancée par pays'}
            </button>
          )}
        </div>
      </DisabledINSITooltipWrapper>
    </>
  )
}
