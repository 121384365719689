import { FunctionComponent, useCallback, useState } from 'react'
import { QuestionnaireQueryFilters, SearchScoresProps } from './SearchQuestionnairesSurvey.model'
import createSearchPanel, { FiltersType } from '../../../containers/SearchPanel'
import { LightQuestionnaire } from '../../../model/Questionnaire'
import { Button } from '../../shared'
import { QuestionnaireListItem } from '../atoms'
import { useGetInfiniteQuestionnaires } from '../../../hooks/queries/questionnaires'
import { isDefined } from '../../../misc/functions.utilities'
import { isEqual } from 'lodash'

const SearchPanel = createSearchPanel<LightQuestionnaire>()

export const SearchQuestionnairesSurvey: FunctionComponent<SearchScoresProps> = ({
  actions,
  addQuestionnaires,
}) => {
  const [selected, setSelected] = useState<ReadonlyArray<LightQuestionnaire>>([])

  const [queryFilters, setQueryFilters] = useState<QuestionnaireQueryFilters>({})
  const {
    query: { isLoading, fetchNextPage },
    questionnaireList: items,
    paginationState,
    cancelPendingQuery,
  } = useGetInfiniteQuestionnaires({ filters: queryFilters })

  const handleFiltersChange = useCallback(
    (filters) => {
      const newQueryFilters: QuestionnaireQueryFilters = {
        ...(filters.search && { search: filters.search }),
        ...(isDefined(filters.private) && { private: filters.private }),
        ...(filters.tagIds.length && { tagIds: filters.tagIds.join(',') }),
      }

      if (!isEqual(queryFilters, newQueryFilters)) {
        cancelPendingQuery()
        setQueryFilters(newQueryFilters)
      }
    },
    [cancelPendingQuery, queryFilters],
  )

  const applySelection = (item?: LightQuestionnaire) => {
    const selection: number[] = item
      ? [item.id]
      : items
          .filter((questionnaire) => selected.some(({ id }) => id === questionnaire.id))
          .map((score) => score.id)

    addQuestionnaires(selection)
  }

  return (
    <SearchPanel
      items={items}
      loading={isLoading}
      itemCount={paginationState?.itemCount ?? null}
      pageCount={paginationState?.pageCount ?? null}
      currentPage={paginationState?.currentPage ?? 0}
      onMultiSelectionChange={(selection) => setSelected(selection)}
      onPrimaryAction={applySelection}
      getItems={() => fetchNextPage()}
      onFiltersChange={handleFiltersChange}
      actions={actions}
      renderItem={(item) => <QuestionnaireListItem item={item} />}
      renderActions={() =>
        (selected.length > 0 && (
          <Button theme="primary" label="Valider" onClick={() => applySelection()} />
        )) ||
        null
      }
      filterCapabilities={[FiltersType.Title, FiltersType.Visibility, FiltersType.Tags]}
    />
  )
}
