import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Question } from '../../../model/Questionnaire'
import { UserFriendlyError } from '../../../model/Error'
import {
  addImportantInformation,
  removeImportantInformation,
} from '../../../data/importantInformations'
import { importantInformationsKeys } from './importantInformations.keys'
import { patientImportantInformationsKeys } from '../patientImportantInformations/patientImportantInformations.keys'

interface ToggleImportantInformationMutateVariable {
  toggle: boolean
  questionId: number
}

export const useToggleImportantInformation = () => {
  const queryClient = useQueryClient()

  return useMutation<Question | void, Error, ToggleImportantInformationMutateVariable>(
    async ({ toggle, questionId }) => {
      const { data, ok } = toggle
        ? await addImportantInformation(questionId)
        : await removeImportantInformation(questionId)

      if (!ok) {
        throw new UserFriendlyError('error', `Le mise à jour de l'information importante a échoué`)
      }

      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(importantInformationsKeys.lists)
        queryClient.invalidateQueries(patientImportantInformationsKeys.lists)
      },
    },
  )
}
