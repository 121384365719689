import getApi from '../../api'
import { singleItemPageLimit } from '../../constants'
import { ImportantInformation } from '../../model/ImportantInformations'
import { PaginatedList } from '../../model/Pagination'

export const getImportantInformations = (
  page: number,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<ImportantInformation>>(
    `/important_informations`,
    {
      page,
      limit,
    },
    { signal },
  )
