import FontFamilyPlugin, { FontFamilyOptions } from '@tiptap/extension-font-family'
import { FONT_FAMILIES, FontFamily } from '@/model/Font'

type FontFamilyExtendedOptions = FontFamilyOptions & {
  defaultFontFamily: FontFamily
  fontFamilies: ReadonlyArray<FontFamily>
}

export const FontFamilyExtended = FontFamilyPlugin.extend<FontFamilyExtendedOptions>({
  addOptions(): FontFamilyExtendedOptions {
    return {
      ...this.parent?.(),
      fontFamilies: FONT_FAMILIES,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: this.options.defaultFontFamily,
            parseHTML: (element) => element.style.fontFamily?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              let fontFamily = attributes['fontFamily']

              if (!this.options.fontFamilies.includes(fontFamily)) {
                return {}
              }

              return {
                style: `font-family: ${fontFamily}`,
              }
            },
          },
        },
      },
    ]
  },
})
