import React, { FunctionComponent, useMemo } from 'react'

import { ObservationsProps } from './Observations.model'
import styles from './Observations.module.scss'
import { EditorEvents, EditorTool, SimpleEditor } from '@follow/elfe'
import { debounce } from 'lodash'

const editorTools: Array<EditorTool> = ['marks', 'align', 'list', 'undo']

export const Observations: FunctionComponent<ObservationsProps> = ({
  content,
  onChange,
  readonly,
}) => {
  const handleChange = useMemo(
    () =>
      debounce((event: EditorEvents['update']) => {
        onChange(event.editor.getHTML())
      }, 500),
    [onChange],
  )

  return (
    <div className={styles.observations} data-test-id="editor-observations">
      <SimpleEditor
        initialContent={content}
        onChange={handleChange}
        disabled={readonly}
        themes={['rounded']}
        toolbarStyle={{ top: 'var(--navbar-height)' }}
        tools={editorTools}
      />
    </div>
  )
}
