export const SEPHIRA_BDAY_FORMAT = 'yyyyMMddHHmm'

export interface SephiraPayload {
  args: {
    DateConsultation: string // "yyyyMMdd"
    NomRessourceLecteur: string
    NomRessourcePS: string
    CodePorteurPS?: string
  }
}

export interface SephiraVitalCardResponse {
  args?: SephiraPayload // args du payload
  groups_o?: Array<VitalCardContentGroup>
  report: {
    exception?: string
    call?: number
    exit?: number
    groups_o: Array<Array<number>>
  }
}

// Vient de la demo fournie par Sephira
export const SEPHIRA_VITAL_CARD_ERROR_MAP = {
  '1024': 'Le lecteur est introuvable, non branché ou mal configuré.',
  '32780': "Le lecteur n'est pas branché, pas connecté, ou pas connecté sur le bon port.",
  '61442': 'La carte CPS a été bloquée après 3 saisies erronées de code porteur.',
  '61443': 'Le code porteur entré est erroné.',
  '61445': 'La Carte du Professionnel de Santé a été retirée.',
  '61457': 'La Carte Vitale est absente.',
  '61460': 'La Carte Vitale est mal insérée ou invalide.',
  '61461': 'La Carte Vitale a été retirée.',
  '61478': "Tentative de lecture d'une carte réelle avec une CPS de test.",
  '61489': 'Le délai de saisie du code porteur est atteint.',
  '61697': "Le lecteur n'est pas correctement configuré. Vérifiez vos paramètres.",
  '65318': 'Les certificats de chiffrement utilisés sont révoqués, vous devez les remplacer.',
}

interface VitalCardContentGroupBase {
  id: number
  content: Record<string, any>
}

export interface VitalCardContentGroupBeneficiary extends VitalCardContentGroupBase {
  id: 104
  content: {
    dob_usual: string
    dob_name: string
    dob_surname: string
    dob_adr_1: string
    dob_adr_2: string
    dob_adr_3: string
    dob_adr_4: string
    dob_adr_5: string
    dob_nir: string
    dob_nir_key: string
    dob_nir_cert_date: string
    dob_bday: string
    dob_born_rank: string
    dob_qualite_beneficiaire: string
    dob_serv_code: string
    dob_begin_service: string
    dob_end_service: string
    dob_ruf_data: string
  }
}

export interface VitalCardContentGroupGlobal extends VitalCardContentGroupBase {
  id: 101
  content: {
    ass_card_t: string
    ass_num_serie_vit: string
    ass_date_fin_validite_vitale: string
    ass_donnees_adm_carte_ruf1: string
    ass_donnees_adm_carte_ruf2: string
    ass_donnees_ruf_adm: string
    ass_porteur_id_t: string
    ass_nir: string
    ass_cle_nir: string
    ass_code_reg: string
    ass_caisse_ges: string
    ass_centre_ges: string
    ass_code_ges: string
    ass_donnees_ruf_fam: string
  }
}

export type VitalCardContentGroup = VitalCardContentGroupBeneficiary | VitalCardContentGroupGlobal
