import { connect } from 'react-redux'
import { RootState } from '../../../../store'
import { vitalCardActions } from '../../../../store/domain/vitalCard'
import { vitalCardServiceSelector } from '../../../../store/domain/vitalCard/vitalCard.selector'
import { VitalCardButton } from './VitalCardButton.component'
import { VitalCardButtonDispatchProps, VitalCardButtonStoreProps } from './VitalCardButton.model'

const mapStateToProps = (state: RootState): VitalCardButtonStoreProps => ({
  vitalCardService: vitalCardServiceSelector(state),
})

const mapDispatchToProps: VitalCardButtonDispatchProps = {
  readVitalCardInfos: vitalCardActions.readVitalCardInfos,
  setVitalCardPatient: vitalCardActions.setVitalCardPatient,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(VitalCardButton)
export { withConnect as VitalCardButton }
