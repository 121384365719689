import { ApiResponse } from 'apisauce'
import { call, put, takeLatest } from 'redux-saga/effects'
import { Speciality } from '../../../model/Professions'
import { getSpecialityList } from '../../cache/specialities/api'
import { addResponseError } from '../../message'
import { specialitiesActions } from './specialities.actions'
import { SpecialityActionsType } from './specialities.model'

export function* fetchListOfSpecialitiesSagas() {
  const response: ApiResponse<Speciality[]> = yield call(getSpecialityList)

  if (response.ok && response.data) {
    yield put(specialitiesActions.storeSpecialitiesList(response.data))
  } else {
    yield put(addResponseError(response))
  }
}

function* fetchListOfSpecialitiesWatcher() {
  yield takeLatest(SpecialityActionsType.GET_LIST_OF_SPECIALITIES, fetchListOfSpecialitiesSagas)
}

export const specialitiesSagas = { fetchListOfSpecialitiesWatcher }
