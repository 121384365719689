import { useEffect } from 'react'
import { isDefined } from '../../../../misc/functions.utilities'
import { useEditQuestion } from '../../../../state/question/useEditQuestion'
import { BottomPanel } from '../BottomPanel'
import EditQuestionPanel from '../../../../containers/Question/EditQuestionPanel'
import { Button } from '../../buttons'
import { EditQuestionBottomPanelProps } from './EditQuestionBottomPanel.model'
import { FullLoader } from '../../loading'

export const EditQuestionBottomPanel = ({
  itemId,
  isUnmounting,
  onRequestClose,
  onItemEdited,
}: EditQuestionBottomPanelProps) => {
  const { setQuestion, question, resetQuestion } = useEditQuestion()

  useEffect(() => {
    if (isDefined(itemId)) {
      setQuestion({ id: itemId })
    }
    return () => resetQuestion()
  }, [itemId, resetQuestion, setQuestion])

  const topBarActions = (
    <Button
      onClick={onRequestClose}
      theme="primary"
      label="OK"
      disabled={!question?.isEditable}
      testId="button-bottom_panel-submit"
    />
  )

  return (
    <BottomPanel
      title={`Éditer l'élément`}
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
      actions={topBarActions}
    >
      {isDefined(question) ? (
        <EditQuestionPanel question={question} onQuestionEdited={onItemEdited} />
      ) : (
        <FullLoader />
      )}
    </BottomPanel>
  )
}
