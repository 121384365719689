import { EditorValueJSON, listVariables } from '@follow/farte'
import {
  isPrescriptionVariable,
  isQuestionnaireVariable,
  isQuestionVariable,
  isManualPrescriptionVariable,
  retrieveVariableId,
  retrieveVariableUuid,
  isQuoteLineVariable,
} from '@follow/cdk'
import { uniq } from '../../../misc/array.utils'
import { isDefined } from '../../../misc/functions.utilities'
import { PrescriptionVariable } from '../../../model/Prescription'
import { PendingVariables, SingleSourceOfTruthOutput } from './editor.model'
import { FarteDocumentInstance } from '../../../model/DocumentInstance'

// Single Source of Truth
export function documentWithSingleSourceOfTruthMapper(
  template: EditorValueJSON,
): SingleSourceOfTruthOutput {
  const templateVariableIds = listVariables(template).map(({ id }) => id)
  // Construction de la liste des questionnaires/variables à partir de l'ordre d'apparation dans le template
  const prescriptionUuids = uniq(
    templateVariableIds
      .filter((id) => isPrescriptionVariable(id))
      .map((id) => retrieveVariableUuid(id))
      .filter(isDefined),
  )

  const manualPrescriptionUuids = uniq(
    templateVariableIds
      .filter((id) => isManualPrescriptionVariable(id))
      .map((id) => retrieveVariableUuid(id))
      .filter(isDefined),
  )

  const questionnaireIds = uniq(
    templateVariableIds
      .filter((id) => isQuestionnaireVariable(id))
      .map(retrieveVariableId)
      .filter(isDefined),
  )

  const variableIds = uniq(
    templateVariableIds
      .filter((id) => isQuestionVariable(id))
      .map(retrieveVariableId)
      .filter(isDefined),
  )

  const quoteLinesUuids = uniq(
    templateVariableIds
      .filter((id) => isQuoteLineVariable(id))
      .map(retrieveVariableUuid)
      .filter(isDefined),
  )

  const updates = {
    template,
    prescriptionUuids,
    manualPrescriptionUuids,
    questionnaireIds,
    variableIds,
    quoteLinesUuids,
  }
  return updates
}

export function craftAMinimalTypescriptCompatibleDocumentUpdate(
  { prescriptionUuids, quoteLinesUuids, ...updates }: SingleSourceOfTruthOutput,
  document: Pick<FarteDocumentInstance, 'prescriptions' | 'manualPrescriptions' | 'quoteLines'>,
  pendingVariables?: PendingVariables,
) {
  const { manualPrescriptions, prescriptions, quoteLines } = document
  return {
    ...updates,
    variables: updates.variableIds.map((id) => ({ id })),
    questionnaires: updates.questionnaireIds.map((id) => ({ id })),
    manualPrescriptions: updates.manualPrescriptionUuids.map(
      (id) =>
        manualPrescriptions.find((manualPrescription) => manualPrescription.variableUuid === id) ??
        pendingVariables?.manualPrescriptions.find(
          (manualPrescription) => manualPrescription.variableUuid === id,
        ),
    ),
    prescriptions: mapDrugVariablesOnPrescriptions(
      prescriptionUuids,
      prescriptions,
      pendingVariables?.prescriptions ?? [],
    ),
    quoteLines: quoteLinesUuids.map(
      (uuid) =>
        quoteLines.find((quoteLine) => uuid === quoteLine.variableUuid) ??
        pendingVariables?.quoteLines.find((quoteLine) => quoteLine.variableUuid === uuid),
    ),
    type: 'farte',
  }
}

const mapDrugVariablesOnPrescriptions = (
  prescriptionUuids: string[],
  prescriptions: ReadonlyArray<PrescriptionVariable>,
  prescriptionMemory: PrescriptionVariable[],
): Omit<PrescriptionVariable, 'isAld'>[] => {
  // On ajoute aux prescriptions du documents les prescriptions présentes dans la mémoire mais absentes du documents
  // Critère d'unicité : prescriptionVariableUuid
  const prescriptionsSet = [...prescriptions]
  prescriptionMemory.forEach((memoizedPrescription) => {
    if (
      !prescriptionsSet.some(
        ({ prescriptionVariableUuid }) =>
          prescriptionVariableUuid === memoizedPrescription.prescriptionVariableUuid,
      )
    ) {
      prescriptionsSet.push(memoizedPrescription)
    }
  })

  return prescriptionUuids
    .map((variableUuid) => {
      const found = prescriptionsSet.find(
        ({ prescriptionVariableUuid }) => prescriptionVariableUuid === variableUuid,
      )
      // fallback pour les prescriptions qui auraient été coupées/collées
      return found
        ? {
            prescriptionVariableUuid: found.prescriptionVariableUuid,
            uuid: found.uuid,
            drugs: found.drugs,
          }
        : { prescriptionVariableUuid: variableUuid, drugs: [] }
    })
    .filter(isDefined)
}
