import styled from '@emotion/styled'
import React, { FunctionComponent, MouseEvent, ReactElement } from 'react'
import { BlockProperties } from 'slate'
import { Button } from '../../../components/Button'
import { DEFAULT_NODE_TYPE, MarkType, NodeType } from '../../model/TextEditor.model'
import {
  getNodeType,
  getSelectionFontFamily,
  getSelectionFontSize,
  hasBlock,
  hasBlockIncludingParent,
  hasData,
  hasMark,
} from '../../TextEditor.utilities'
import {
  ButtonType,
  DEFAULT_TOOLBAR_CONFIG,
  FontFamily,
  FontSize,
  ToolbarProps,
} from './Toolbar.model'
import { BlockTypeDropdown } from './BlockTypeDropdown.component'
import { FontFamilyDropdown } from './FontFamilyDropdown.component'
import { FontSizeDropdown } from './FontSizeDropdown.component'

const DefaultToolbarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px;
  background: #e5e5ec;
  font-size: 0.85rem;
  & > *:not(:last-child) {
    border-right: 1px solid #ccc;
  }
`

const Categories = styled.div`
  display: flex;
  padding: 5px 2.5px;
  align-items: center;
`

export const Toolbar: FunctionComponent<ToolbarProps> = ({
  editor,
  value,
  config = DEFAULT_TOOLBAR_CONFIG,
  readOnly,
  children,
  editorBanner,
  style,
  saveAsText,
  onAddImage,
}) => {
  const handleFontFamilyChange = (fontFamily: FontFamily) => {
    const block = value.focusBlock
    const selection = value.fragment
    // Si le texte séléctionné est l'ensemble du block
    if (block.text === selection.text) {
      editor.setBlocks({
        type: block.type,
        data: { ...block.data.toJS(), fontFamily },
      })
      // On supprime les mark fontSize existante de la sélection
      selection
        .getMarks()
        .forEach((mark) => mark && mark.type === 'font-family' && editor.removeMark(mark))
    } else {
      const currentMarks = selection
        .getMarks()
        .filter((mark) => !!mark && mark.type === 'font-family')
      if (currentMarks.size > 0) {
        // On supprime les marks existantes
        currentMarks.forEach((mark) => mark && editor.removeMark(mark))
      }
      // Si la fontFamily est différente de celle du block, alors on ajoute une fonteSize sur la selection
      if (block.data.get('fontFamily') !== fontFamily) {
        editor.toggleMark({ type: 'font-family', data: { fontFamily } })
      }
    }
  }
  const handleFontSizeChange = (fontSize: FontSize) => {
    const block = value.focusBlock
    const selection = value.fragment
    // Si le texte séléctionné est l'ensemble du block
    if (block.text === selection.text) {
      editor.setBlocks({ type: block.type, data: { ...block.data.toJS(), fontSize } })
      // On supprime les mark fontSize existante de la sélection
      selection
        .getMarks()
        .forEach((mark) => mark && mark.type === 'font-size' && editor.removeMark(mark))
    } else {
      const currentMarks = selection
        .getMarks()
        .filter((mark) => !!mark && mark.type === 'font-size')
      if (currentMarks.size > 0) {
        // On supprime les mark existante
        currentMarks.forEach((mark) => mark && editor.removeMark(mark))
      }
      // Si la fontSize est différente de celle du block, alors on ajoute une fonteSize sur la selection
      if (block.data.get('fontSize') !== fontSize) {
        editor.toggleMark({ type: 'font-size', data: { fontSize } })
      }
    }
  }

  const handleClickMark = (type: MarkType) => {
    editor.toggleMark(type).focus()
  }

  const chooseBlockType = (block: BlockProperties) => {
    const isList = hasBlock(value, NodeType.LIST_ITEM)
    block.data = {
      ...block.data,
      fontFamily: getSelectionFontFamily(value),
    }
    const { type } = block

    // Handle everything but list buttons.
    if (type !== NodeType.LIST && type !== NodeType.NUMBERED_LIST) {
      if (isList) {
        editor.unwrapBlock(NodeType.LIST).unwrapBlock(NodeType.NUMBERED_LIST).setBlocks(type)
      } else {
        editor.setBlocks(block)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isType = hasBlockIncludingParent(value, type)

      if (isList && isType) {
        editor
          .unwrapBlock(NodeType.LIST)
          .unwrapBlock(NodeType.NUMBERED_LIST)
          .setBlocks(DEFAULT_NODE_TYPE)
      } else if (isList) {
        editor
          .unwrapBlock(type === NodeType.NUMBERED_LIST ? NodeType.LIST : NodeType.NUMBERED_LIST)
          .wrapBlock(type)
      } else {
        editor.wrapBlock(type).setBlocks(NodeType.LIST_ITEM)
      }
    }
  }

  const handleAddVoidBlock = (type: NodeType) => {
    editor.insertBlock(type).insertBlock(NodeType.PARAGRAPH).unwrapBlock(type)
  }

  const handleClickMarkButton = (type: MarkType) => (event: MouseEvent) => {
    event.preventDefault()
    handleClickMark(type)
  }

  const handleChooseBlockType = (type: BlockProperties) => (event: MouseEvent) => {
    event.preventDefault()
    chooseBlockType(type)
  }

  const handleAddBlockButton = (type: NodeType) => (event: MouseEvent) => {
    event.preventDefault()
    handleAddVoidBlock(type)
  }

  const handleSetAlign = (align: 'right' | 'center' | 'left' | 'justify') => (
    event: MouseEvent,
  ) => {
    event.preventDefault()
    editor.command('setAlign', { align })
  }

  const handleUndo = (event: MouseEvent) => {
    event.preventDefault()
    editor.undo()
  }
  const handleRedo = (event: MouseEvent) => {
    event.preventDefault()
    editor.redo()
  }

  const BUTTONS: { [key in ButtonType]: ReactElement } = {
    blockType: (
      <BlockTypeDropdown
        key="blockType"
        onClick={(block) => chooseBlockType(block)}
        value={getNodeType(value)}
        values={config.blockTypes}
      />
    ),
    fontFamily: (
      <FontFamilyDropdown
        key="fontFamily"
        onClick={(fontFamily) => handleFontFamilyChange(fontFamily)}
        value={getSelectionFontFamily(value)}
        values={config.fontFamilies}
      />
    ),
    fontSize: (
      <FontSizeDropdown
        key="fontSize"
        onClick={(fontSize) => handleFontSizeChange(fontSize)}
        value={getSelectionFontSize(value)}
        values={config.fontSizes}
      />
    ),
    bold: (
      <Button
        key="Gras"
        title="Gras"
        iconType="bold"
        active={!readOnly && hasMark(value, MarkType.BOLD)}
        onClick={handleClickMarkButton(MarkType.BOLD)}
      />
    ),
    italic: (
      <Button
        key="Italique"
        title="Italique"
        iconType="italic"
        active={!readOnly && hasMark(value, MarkType.ITALIC)}
        onClick={handleClickMarkButton(MarkType.ITALIC)}
      />
    ),
    underline: (
      <Button
        key="Souligné"
        title="Souligné"
        iconType="underline"
        active={!readOnly && hasMark(value, MarkType.UNDERLINED)}
        onClick={handleClickMarkButton(MarkType.UNDERLINED)}
      />
    ),
    alignLeft: (
      <Button
        key="Aligné à gauche"
        title="Aligné à gauche"
        iconType="left"
        active={hasData(value, 'align', 'left')}
        onClick={handleSetAlign('left')}
      />
    ),
    alignCenter: (
      <Button
        key="Centré"
        title="Centré"
        iconType="center"
        active={hasData(value, 'align', 'center')}
        onClick={handleSetAlign('center')}
      />
    ),
    alignRight: (
      <Button
        key="Aligné à droite"
        title="Aligné à droite"
        iconType="right"
        active={hasData(value, 'align', 'right')}
        onClick={handleSetAlign('right')}
      />
    ),
    justify: (
      <Button
        key="Justifié"
        title="Justifié"
        iconType="justified"
        active={hasData(value, 'align', 'justify')}
        onClick={handleSetAlign('justify')}
      />
    ),
    bulletList: (
      <Button
        key="Liste a puce"
        title="Liste a puce"
        iconType="unorderedList"
        active={!readOnly && hasBlockIncludingParent(value, NodeType.LIST)}
        onClick={handleChooseBlockType({ type: NodeType.LIST })}
      />
    ),
    numberedList: (
      <Button
        key="Liste numérotée"
        title="Liste numérotée"
        iconType="orderedList"
        active={!readOnly && hasBlockIncludingParent(value, NodeType.NUMBERED_LIST)}
        onClick={handleChooseBlockType({ type: NodeType.NUMBERED_LIST })}
      />
    ),
    addImage: (
      <Button
        key="Image"
        title="Image"
        iconType="image"
        active={!readOnly && hasBlockIncludingParent(value, NodeType.NUMBERED_LIST)}
        onClick={() => onAddImage && onAddImage()}
      />
    ),
    breakPage: (
      <Button
        key="Saut de page"
        iconType="pagebreak"
        title="Saut de page"
        onClick={handleAddBlockButton(NodeType.PAGE_BREAK)}
      />
    ),
    undo: <Button key="Annuler" iconType="undo" title="Annuler" onClick={handleUndo} />,
    redo: <Button key="Rétablir" iconType="redo" title="Rétablir" onClick={handleRedo} />,
    saveText: (
      <Button
        key="Copier en texte brut"
        iconType="clipboard"
        title="Copier en texte brut"
        onClick={(_) => {
          if (saveAsText) {
            saveAsText()
          }
        }}
      />
    ),
  }

  return (
    <div style={!readOnly ? style : undefined}>
      <DefaultToolbarContainer>
        {Object.entries(config.categories).map(([_category, buttons], index) => (
          <Categories key={index}>{buttons && buttons.map((button) => BUTTONS[button])}</Categories>
        ))}
        <Categories>{children}</Categories>
      </DefaultToolbarContainer>
      {editorBanner}
    </div>
  )
}
