import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { openMonograph, openSMR } from '../../../misc/drug.utilities'
import DrugItem from '../../../containers/BottomSearchPanel/SearchDrug/DrugItem'
import { TreatmentsHistoryBottomPanelProps } from './TreatmentsHistoryBottomPanel.model'
import { RestuxLoadingState } from '../../../store/restux/ui'
import { Treatment, TreatmentsStatus } from '../../../model/Treatment'
import { useEffectWithRef } from '../../../hooks/utils'
import { SelectSearch, List, AbstractList } from '../../shared'
import { SelectOption } from '../../../model/SelectOption'
import { isDefined } from '../../../misc/functions.utilities'
import { SideEffectsSelect } from '../SideEffectsSelect'
import { AtcClass, SideEffect } from '../../../model/Drug'
import { AtcClassSelect } from '../AtcClassSelect'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'

const treatmentsStatusOptions = [
  { value: TreatmentsStatus.ALL, label: 'Tous' },
  { value: TreatmentsStatus.CURRENT, label: 'En cours' },
  { value: TreatmentsStatus.PAST, label: 'Terminés' },
]

export const TreatmentsHistoryBottomPanel: FunctionComponent<TreatmentsHistoryBottomPanelProps> = ({
  treatments: { items, totalPages, totalItems, loadingState },
  getTreatments,
}) => {
  const currentPatientId = getCurrentPatientId()

  const [listRef, setListRef] = useEffectWithRef<AbstractList<Treatment>>(() => {
    if (listRef) {
      listRef.updateList(true)
    }
  }, [])

  const [treatmentsStatus, setTreatmentsStatus] = useState<SelectOption<TreatmentsStatus>>(
    treatmentsStatusOptions.find((status) => status.value === TreatmentsStatus.PAST) ??
      treatmentsStatusOptions[0],
  )

  const [treatmentsSideEffect, setTreatmentsSideEffect] = useState<SideEffect | undefined>()
  const [atcClass, setAtcClass] = useState<AtcClass | null>(null)

  const getTreatmentsPage = useCallback(
    (page: number) => {
      if (isDefined(currentPatientId)) {
        const { value: filter } = treatmentsStatus
        getTreatments({
          page: { currentPage: page, pageSize: 50 },
          params: { patientId: `${currentPatientId}` },
          filters: {
            status: filter !== TreatmentsStatus.ALL ? filter : undefined,
            sideEffectId: treatmentsSideEffect?.externalId,
            atcCodeExternalId: atcClass?.id,
          },
        })
      }
    },
    [currentPatientId, treatmentsStatus, getTreatments, treatmentsSideEffect, atcClass],
  )

  const handleOnChangeTreatmentsSelect = useCallback(
    (selectedOption: SelectOption<TreatmentsStatus> | undefined) => {
      if (isDefined(selectedOption)) {
        setTreatmentsStatus(selectedOption)
      }
    },
    [setTreatmentsStatus],
  )

  useEffect(() => {
    getTreatmentsPage(1)
  }, [treatmentsStatus, getTreatmentsPage])

  return (
    <div className="flex flex-row h-full">
      <div className="flex-[1] p-6">
        <div className="flex flex-col space-y-4 font-semibold text-white">
          <SelectSearch
            value={treatmentsStatus}
            options={treatmentsStatusOptions}
            onSelect={handleOnChangeTreatmentsSelect}
            colorPreset="dark"
            clearable={false}
            label="Statut"
          />
          <SideEffectsSelect setSideEffectSelected={setTreatmentsSideEffect} />
          <AtcClassSelect label="Classification ATC" onClassSelected={setAtcClass} />
        </div>
      </div>
      <div className="flex-[3] relative">
        <List
          ref={setListRef}
          items={items}
          loading={loadingState !== RestuxLoadingState.IDLE}
          pageCount={totalPages}
          itemCount={totalItems}
          onUpdateList={(page) => getTreatmentsPage(page)}
          renderItem={(treatment) => (
            <DrugItem
              drug={treatment.drugConfig.drug}
              treatment={treatment}
              onMonographClick={() => openMonograph(treatment.drugConfig.drug)}
              onSMRClick={() => openSMR(treatment.drugConfig.drug)}
            />
          )}
        />
      </div>
    </div>
  )
}
