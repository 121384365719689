import { FontFamily } from '@follow/farte'
import { FunctionComponent, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import {
  Answer,
  Question,
  QuestionLight,
  QuestionType,
  WITH_ANSWERS_QUESTION_TYPES,
} from '../../../model/Questionnaire'

import { defaultAnswerTitle } from '../../../constants'

import { Button, Input, ValidationModal } from '../../../components/shared'

import EditAnswer from '../EditAnswer'

import { EditQuestionPanelProps } from './EditQuestionPanel.model'

import { EditSliderConfig } from '../EditSliderConfig'
import { useUpdateQuestion } from '../../../hooks/queries/questions'
import {
  useCreateAnswer,
  useDeleteAnswer,
  useUpdateAnswer,
  useAddImageToAnswer,
  useRemoveImageFromAnswer,
} from '../../../hooks/queries/answers'
import { AnswerMutationPayload } from '../../../data/answers/mapper/answers.model'
import { getQuestionLevelPlaceholder } from '../../../components/questionnaire/Questionnaire.model'
import { BOTTOM_PANEL_CONTENT_ROOT } from '../../../components/shared/bottomPanel/BottomPanel'

const NO_CODE_QUESTION_TYPES = [QuestionType.QCM, QuestionType.Text]

const EditWithAnswersQuestion: FunctionComponent<{
  answers: Answer[]
  canEditPoints: boolean
  isEditable: boolean
  isDeletable: boolean
  editorFontFamily: FontFamily
  isChild: boolean
  questionType: QuestionType
  onAddAnswer: () => void
  onAddImageToAnswer: (answer: Answer, file: File) => void
  onDeleteAnswer: (answer: Answer) => void
  onUpdateAnswer: (id: number, updates: Partial<Answer>) => void
  onRemoveImageFromAnswer: (answer: Answer) => void
  onUpdateQuestion: (question: Partial<Question>) => void
}> = ({
  answers,
  canEditPoints,
  editorFontFamily,
  isChild,
  questionType,
  onAddAnswer,
  onAddImageToAnswer,
  onDeleteAnswer,
  onRemoveImageFromAnswer,
  onUpdateAnswer,
  onUpdateQuestion,
  isEditable,
  isDeletable,
}) => {
  const [openSwitchValidation, setOpenSwitchValidation] = useState(false)
  return (
    <>
      <div>
        {answers.map((answer) => (
          <EditAnswer
            key={answer.id}
            answer={answer}
            canEditPoints={canEditPoints}
            editorFontFamily={editorFontFamily}
            isChild={isChild}
            onAddImage={onAddImageToAnswer}
            onRemoveImage={onRemoveImageFromAnswer}
            onDeleteAnswer={onDeleteAnswer}
            onUpdateAnswer={onUpdateAnswer}
            isDeletable={isDeletable}
            isEditable={isEditable}
          />
        ))}
      </div>
      {isEditable && (
        <div className="mt-4 flex w-max">
          <Button
            label="Ajouter un choix"
            icon="add"
            theme="primary"
            onClick={onAddAnswer}
            testId="button-answer-add"
          />
          {questionType === QuestionType.QCU && (
            <div className="ml-2">
              <Button
                icon="shuffle"
                onClick={() => setOpenSwitchValidation(true)}
                theme="dark"
                label="Convertir en QCM"
                testId="button-answer-QCM_convert"
              />
            </div>
          )}
        </div>
      )}
      {isEditable && (
        <ValidationModal
          display={openSwitchValidation}
          rootId={BOTTOM_PANEL_CONTENT_ROOT}
          title="Voulez-vous convertir cette question en QCM ? Cette action est irréversible."
          testId="modal-QCM_convert"
          onClose={() => setOpenSwitchValidation(false)}
          onSubmit={() => {
            onUpdateQuestion({ type: QuestionType.QCM })
            setOpenSwitchValidation(false)
          }}
        />
      )}
    </>
  )
}
const EditQuestionPanel: FunctionComponent<EditQuestionPanelProps> = ({
  question: inUseQuestion,
  onQuestionEdited,
  editorFontFamily,
  isExtDsm,
  isAdmin,
}) => {
  const questionnaire = inUseQuestion?.questionnaires[0]
  const [title, setTitle] = useState('')
  const [code, setCode] = useState('')
  const [initialized, setInitialized] = useState(false)
  const { mutate: updateQuestion } = useUpdateQuestion()
  const { mutate: addAnswerToQuestion } = useCreateAnswer()
  const { mutate: removeAnswerFromQuestion } = useDeleteAnswer()
  const { mutate: updateAnswer } = useUpdateAnswer()
  const { mutate: addImageToAnswer } = useAddImageToAnswer()
  const { mutate: removeImageFromAnswer } = useRemoveImageFromAnswer()

  const canHandleScore = isAdmin || isExtDsm
  const placeholder = getQuestionLevelPlaceholder(inUseQuestion)
  const questionLevelDefaultLabel = !!inUseQuestion.parentQuestionId
    ? 'Nom de la sous-question'
    : 'Nom de la question'

  const onMutationSuccess = (question?: QuestionLight) => {
    // Temporaire pour les modifications autres que sur l'entité question.
    // Jusqu'a ce que packages/app/src/store/ui/bottomPanelQuestions/bottomPanelQuestions.sagas.ts:editQuestionSagaWorker ne soit plus utilisé
    typeof onQuestionEdited === 'function' && onQuestionEdited(question || inUseQuestion)
  }

  useEffect(() => {
    if (!initialized && inUseQuestion) {
      if (!title) setTitle(inUseQuestion.title)
      if (!code) setCode(inUseQuestion.code || '')
      setInitialized(true)
    }
  }, [inUseQuestion, code, title, initialized])

  useDebounce(
    () => {
      if (inUseQuestion && inUseQuestion.title !== title) {
        updateQuestion(
          {
            id: inUseQuestion.id,
            payload: {
              title,
            },
          },
          { onSuccess: (question) => onMutationSuccess(question) },
        )
      }

      if (
        inUseQuestion &&
        inUseQuestion.code !== code &&
        (inUseQuestion.code || code !== '') &&
        !NO_CODE_QUESTION_TYPES.includes(inUseQuestion.type)
      ) {
        updateQuestion(
          {
            id: inUseQuestion.id,
            payload: {
              code,
            },
          },
          { onSuccess: (question) => onMutationSuccess(question) },
        )
      }
    },
    500,
    [inUseQuestion, title, code],
  )

  function handleDeleteAnswer(answer: Answer) {
    removeAnswerFromQuestion(
      {
        id: answer.id,
        questionId: answer.questionId,
        questionnaireId: questionnaire?.id,
      },
      {
        onSuccess: () => onMutationSuccess(),
      },
    )
  }

  function handleUpdateAnswer(id: number, payload: AnswerMutationPayload) {
    updateAnswer(
      {
        id,
        payload,
        questionnaireId: questionnaire?.id,
      },
      {
        onSuccess: () => onMutationSuccess(),
      },
    )
  }

  return (
    <div className="py-4 px-8 h-full overflow-y-scroll">
      {inUseQuestion && (
        <>
          <div>
            <Input
              name="title"
              label={questionLevelDefaultLabel}
              value={title}
              placeholder={placeholder}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              colorPreset="dark"
              autofocus
              disabled={!inUseQuestion.isEditable}
              testId="input-question-title"
            />
            {canHandleScore && !NO_CODE_QUESTION_TYPES.includes(inUseQuestion.type) && (
              <div className="max-w-80">
                <Input
                  name="code"
                  label="Code pour le calcul des scores"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  colorPreset="dark"
                />
              </div>
            )}
          </div>
          {WITH_ANSWERS_QUESTION_TYPES.includes(inUseQuestion.type) && (
            <EditWithAnswersQuestion
              answers={inUseQuestion.answers}
              canEditPoints={canHandleScore}
              editorFontFamily={editorFontFamily}
              isChild={!!inUseQuestion.parentAnswerId}
              questionType={inUseQuestion.type}
              onAddAnswer={() =>
                addAnswerToQuestion(
                  {
                    payload: {
                      questionId: inUseQuestion.id,
                      title: defaultAnswerTitle,
                      displayOrder: inUseQuestion.answers.length + 1,
                    },
                    questionnaireId: questionnaire?.id,
                  },
                  {
                    onSuccess: () => onMutationSuccess(),
                  },
                )
              }
              onAddImageToAnswer={(answer, file) =>
                addImageToAnswer(
                  {
                    answerId: answer.id,
                    file,
                    questionnaireId: questionnaire?.id,
                  },
                  {
                    onSuccess: () => onMutationSuccess(),
                  },
                )
              }
              onDeleteAnswer={handleDeleteAnswer}
              onRemoveImageFromAnswer={(answer) =>
                removeImageFromAnswer(
                  {
                    answerId: answer.id,
                    questionnaireId: questionnaire?.id,
                  },
                  {
                    onSuccess: () => onMutationSuccess(),
                  },
                )
              }
              onUpdateAnswer={handleUpdateAnswer}
              onUpdateQuestion={(question) =>
                updateQuestion(
                  { id: inUseQuestion.id, payload: question },
                  {
                    onSuccess: (question) => onMutationSuccess(question),
                  },
                )
              }
              isEditable={inUseQuestion.isEditable ?? false}
              isDeletable={inUseQuestion.isDeletable ?? false}
            />
          )}
          {inUseQuestion.type === QuestionType.Slider && inUseQuestion.config && (
            <EditSliderConfig
              questionConfig={inUseQuestion.config}
              isAdmin={isAdmin}
              canEditPoints={canHandleScore}
              onConfigValueChange={(config) => {
                updateQuestion(
                  { id: inUseQuestion.id, payload: { config } },
                  {
                    onSuccess: (question) => onMutationSuccess(question),
                  },
                )
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

export default EditQuestionPanel
