import css from '@emotion/css'
import styled from '@emotion/styled'
import { FontFamily, FontSize } from '../../Toolbar/Toolbar.model'
import React, { CSSProperties, FunctionComponent } from 'react'
import { RenderAttributes } from 'slate-react'

export type TitleType = 1 | 2 | 3

export interface TitleProps extends RenderAttributes {
  type: TitleType
  fontFamily: FontFamily
  fontSize: FontSize
  align?: 'left' | 'center' | 'right'
}

const baseTitleStyle = css``

const H1 = styled.h1`
  ${baseTitleStyle}
  font-weight: bold;
`

const H2 = styled.h2`
  ${baseTitleStyle}
  font-weight: 600;
`

const H3 = styled.h3`
  ${baseTitleStyle}
  font-weight: 600;
`

export const Title: FunctionComponent<TitleProps> = ({
  type,
  fontFamily,
  fontSize,
  align,
  children,
  ...renderAttributes
}) => {
  const style: CSSProperties = {
    fontFamily,
    fontSize: `${fontSize}px`,
    ...(align ? { textAlign: align } : {}),
  }

  switch (type) {
    case 2:
      return (
        <H2 style={style} {...renderAttributes}>
          {children}
        </H2>
      )
    case 3:
      return (
        <H3 style={style} {...renderAttributes}>
          {children}
        </H3>
      )
    case 1:
    default:
      return (
        <H1 style={style} {...renderAttributes}>
          {children}
        </H1>
      )
  }
}
