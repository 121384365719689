import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Answer } from '../../../model/Questionnaire'
import { UserFriendlyError } from '../../../model/Error'
import { updateAnswer } from '../../../data/answers'
import { AnswerMutationPayload } from '../../../data/answers/mapper/answers.model'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { questionsKeys } from '../questions/questions.keys'

interface AnswerMutateVariable {
  id: number
  payload: AnswerMutationPayload
  questionnaireId?: number
}

export const useUpdateAnswer = () => {
  const queryClient = useQueryClient()

  return useMutation<Answer, Error, AnswerMutateVariable>(
    async ({ id, payload }) => {
      const { data, ok } = await updateAnswer(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la réponse a échoué')
      }
      return data
    },
    {
      onSuccess: (answer, { questionnaireId }) => {
        queryClient.invalidateQueries(questionsKeys.detail(answer.questionId))
        if (questionnaireId) {
          queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
        }
      },
    },
  )
}
