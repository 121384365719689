import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateAnswer } from '../../../data/answers'
import { UserFriendlyError } from '../../../model/Error'
import { Answer } from '../../../model/Questionnaire'
import { questionnairesKeys } from '../questionnaires/questionnaires.keys'
import { isUploadableToServer } from '../../../misc/files.utilities'
import { uploadFile } from '../../../data/files'
import { FileType } from '../../../model/File'
import { questionsKeys } from '../questions/questions.keys'

interface AddImageToAnswerMutateVariable {
  answerId: number
  file: File
  questionnaireId?: number
}

export const useAddImageToAnswer = () => {
  const queryClient = useQueryClient()

  const throwUploadError = () => {
    throw new UserFriendlyError('error', "L'ajout d'une image sur la réponse a échoué")
  }

  return useMutation<Answer, Error, AddImageToAnswerMutateVariable>(
    async ({ answerId, file }) => {
      if (!isUploadableToServer(file)) {
        throw new UserFriendlyError(
          'error',
          'Taille de fichier non supportée',
          `Le fichier n'a pas pu être uploadé car sa taille est supérieur à 50Mo`,
        )
      }
      const { data: uploadFileData, ok: uploadFileOk } = await uploadFile(
        file,
        FileType.ANSWER_IMAGE,
      )
      if (!uploadFileData || !uploadFileOk) {
        return throwUploadError()
      }
      const { data: updateAnswerData, ok: updateAnswerOk } = await updateAnswer(answerId, {
        imageFile: uploadFileData,
      })
      if (!updateAnswerData || !updateAnswerOk) {
        return throwUploadError()
      }
      return updateAnswerData
    },
    {
      onSuccess: (answer, { questionnaireId }) => {
        queryClient.invalidateQueries(questionsKeys.detail(answer.questionId))
        if (questionnaireId) {
          queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
        }
      },
    },
  )
}
