import styled from '@emotion/styled'
import css from '@emotion/css'
import { EditorMode } from '../../core/model/TextEditor.model'
import React, { FunctionComponent, ReactNode } from 'react'

type EditorContainerProps = {
  mode: EditorMode
  disabled: boolean
  children?: ReactNode
}

type ContainerProps = {
  mode: EditorMode
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ mode }) =>
    mode !== 'print' &&
    css`
      height: 100%;
    `}
`

const DisabledOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: not-allowed;
`

const EditorContainer: FunctionComponent<EditorContainerProps> = ({ mode, disabled, children }) => (
  <Container mode={mode}>
    {disabled && <DisabledOverlay />}
    {children}
  </Container>
)

export default EditorContainer
