import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionnairesKeys } from './questionnaires.keys'
import { deleteQuestionnaire } from '../../../data/questionnaires'

interface QuestionnaireMutateVariable {
  questionnaireId: number
}

export const useDeleteQuestionnaire = () => {
  const queryClient = useQueryClient()

  return useMutation<null, Error, QuestionnaireMutateVariable>(
    async ({ questionnaireId }) => {
      const { ok } = await deleteQuestionnaire(questionnaireId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression du questionnaire a échoué')
      }
      return null
    },
    {
      onSuccess: (_, { questionnaireId }) => {
        queryClient.invalidateQueries(questionnairesKeys.lists)
        queryClient.removeQueries(questionnairesKeys.detail(questionnaireId))
      },
    },
  )
}
