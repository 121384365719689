import { useMutation } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { User } from '../../../../model/User'
import { duplicateUserContent } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { getFullName } from '../../../../misc/user.utilities'

interface DuplicateUserContentVariables {
  sourceUser: User
  targetUserId: string
}

export const useDuplicateUserContent = () => {
  const valid = useActionDispatch(addValid)
  const queryReturn = useMutation<null, Error, DuplicateUserContentVariables>(
    async ({ sourceUser, targetUserId }) => {
      const { ok } = await duplicateUserContent(sourceUser.id, targetUserId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La duplication du contenu a échoué')
      }
      return null
    },
    {
      onSuccess: (_data, { sourceUser, targetUserId }) => {
        valid(
          'Duplication réalisée',
          `Le contenu de l'utilisateur ${getFullName(
            sourceUser,
          )} a bien été dupliqué sur l'utilisateur ${targetUserId} !`,
        )
      },
    },
  )

  return { ...queryReturn }
}
