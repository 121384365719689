import { FunctionComponent, useEffect, useState } from 'react'
import { FileViewer } from '../../../file'
import { mapFieldValues } from '../../../file/FileViewer/PdfViewerLegacy/pdf.utils'
import { FieldValue } from '../../../../model/Document'
import { FileInstanceContentProps } from './FileInstanceContent.model'
import styles from './FileInstanceContent.module.scss'
import { isDefined } from '../../../../misc/functions.utilities'
import { useHistorizedMemo } from '../../../../hooks/utils/useHistorizedMemo'
import { FocusedInput } from '../../../file/FileViewer/FileViewer.model'

export const FileInstanceContent: FunctionComponent<FileInstanceContentProps> = ({
  documentInstance,
  medicalEvent,
  isVariableDataLoading,
  variablesData,
  setVariableDataLoading,
  getVariableData,
  searchBaseVariable,
  updateFileInstance,
}) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInput | null>(null)

  useEffect(() => {
    getVariableData()
  }, [getVariableData])

  const fieldValues = useHistorizedMemo<Array<FieldValue> | null>(
    (previousValue) => {
      if (!isDefined(variablesData)) {
        return null
      }
      if (isVariableDataLoading && isDefined(previousValue)) {
        return previousValue
      }

      const mapped = mapFieldValues(documentInstance.fieldValues, variablesData)
      return mapped
    },
    [documentInstance.fieldValues, isVariableDataLoading, variablesData],
  )

  const handleOnChange = (newFieldValue: FieldValue) => {
    if (documentInstance) {
      const { fieldValues, id } = documentInstance
      const existingValue = documentInstance.fieldValues.findIndex(
        (value) => value.id === newFieldValue.id,
      )
      if (existingValue !== -1) {
        if (newFieldValue.value !== '') {
          fieldValues[existingValue] = newFieldValue
        } else {
          fieldValues.splice(existingValue, 1)
        }
      } else {
        fieldValues.push(newFieldValue)
      }
      setVariableDataLoading(true)
      updateFileInstance(id, { type: 'pdf', fieldValues })
    }
  }

  return (
    <div className={styles.fileViewer}>
      {fieldValues && (
        <FileViewer
          file={documentInstance.file}
          fieldValues={fieldValues}
          isInteractive={true}
          readOnly={!medicalEvent?.isEditable}
          onChange={handleOnChange}
          contentType={documentInstance.contentType}
          enableScrollButton
          onInsertVariable={() => {
            searchBaseVariable(
              (id) =>
                focusedInput &&
                handleOnChange({
                  id: focusedInput.id,
                  type: 'variable',
                  value: id,
                  name: focusedInput.name,
                }),
              'fileTemplate',
            )
          }}
          onFocusInput={(input) => setFocusedInput(input)}
        />
      )}
    </div>
  )
}
