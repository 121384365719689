import getApi from '../../api/apisauceConfig'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'
import { LightQuestionnaire } from '../../model/Questionnaire'
import { singleItemPageLimit } from '../../constants'

export const getQuestionnaires = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<LightQuestionnaire>>(
    '/questionnaires',
    {
      page,
      limit,
      ...filters,
    },
    { signal },
  )
